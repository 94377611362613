import { FC, HTMLAttributes } from 'react';

type FormoLogoProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const FormoLogo: FC<FormoLogoProps> = ({ size = 257, ...props }) => {
  return (
    <svg
      width={size}
      height={(248 * size) / 257}
      viewBox="0 0 257 248"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M197.379 202.443L154.79 179.888C153.334 179.117 151.535 179.855 151.04 181.426L130.735 245.784C129.959 248.244 126.478 248.244 125.702 245.784L105.407 181.427C104.911 179.855 103.112 179.117 101.656 179.888L59.0679 202.443C56.8265 203.63 54.3647 201.279 55.4471 198.985L77.5847 152.072C78.2741 150.611 77.5006 148.879 75.9525 148.418L1.88455 126.336C-0.628173 125.587 -0.628188 122.028 1.88453 121.279L75.9525 99.1966C77.5006 98.735 78.2741 97.0032 77.5848 95.5423L55.4471 48.629C54.3647 46.3352 56.8265 43.9845 59.0679 45.1715L101.656 67.7259C103.112 68.4971 104.911 67.7596 105.407 66.1882L125.707 1.84452C126.483 -0.61484 129.963 -0.61484 130.739 1.84452L151.039 66.1881C151.535 67.7596 153.334 68.4971 154.79 67.7259L197.379 45.1715C199.62 43.9845 202.082 46.3352 200.999 48.629L178.862 95.5423C178.172 97.0032 178.946 98.735 180.494 99.1966L254.562 121.279C257.075 122.028 257.075 125.587 254.562 126.336L180.494 148.418C178.946 148.879 178.173 150.611 178.862 152.072L200.999 198.985C202.082 201.279 199.62 203.63 197.379 202.443Z"
        fill="url(#paint0_radial_12_5)"
      />
      <path
        d="M197.379 202.443L154.79 179.888C153.334 179.117 151.535 179.855 151.04 181.426L130.735 245.784C129.959 248.244 126.478 248.244 125.702 245.784L105.407 181.427C104.911 179.855 103.112 179.117 101.656 179.888L59.0679 202.443C56.8265 203.63 54.3647 201.279 55.4471 198.985L77.5847 152.072C78.2741 150.611 77.5006 148.879 75.9525 148.418L1.88455 126.336C-0.628173 125.587 -0.628188 122.028 1.88453 121.279L75.9525 99.1966C77.5006 98.735 78.2741 97.0032 77.5848 95.5423L55.4471 48.629C54.3647 46.3352 56.8265 43.9845 59.0679 45.1715L101.656 67.7259C103.112 68.4971 104.911 67.7596 105.407 66.1882L125.707 1.84452C126.483 -0.61484 129.963 -0.61484 130.739 1.84452L151.039 66.1881C151.535 67.7596 153.334 68.4971 154.79 67.7259L197.379 45.1715C199.62 43.9845 202.082 46.3352 200.999 48.629L178.862 95.5423C178.172 97.0032 178.946 98.735 180.494 99.1966L254.562 121.279C257.075 122.028 257.075 125.587 254.562 126.336L180.494 148.418C178.946 148.879 178.173 150.611 178.862 152.072L200.999 198.985C202.082 201.279 199.62 203.63 197.379 202.443Z"
        fill="url(#paint1_radial_12_5)"
        fillOpacity="0.4"
      />
      <path
        d="M197.379 202.443L154.79 179.888C153.334 179.117 151.535 179.855 151.04 181.426L130.735 245.784C129.959 248.244 126.478 248.244 125.702 245.784L105.407 181.427C104.911 179.855 103.112 179.117 101.656 179.888L59.0679 202.443C56.8265 203.63 54.3647 201.279 55.4471 198.985L77.5847 152.072C78.2741 150.611 77.5006 148.879 75.9525 148.418L1.88455 126.336C-0.628173 125.587 -0.628188 122.028 1.88453 121.279L75.9525 99.1966C77.5006 98.735 78.2741 97.0032 77.5848 95.5423L55.4471 48.629C54.3647 46.3352 56.8265 43.9845 59.0679 45.1715L101.656 67.7259C103.112 68.4971 104.911 67.7596 105.407 66.1882L125.707 1.84452C126.483 -0.61484 129.963 -0.61484 130.739 1.84452L151.039 66.1881C151.535 67.7596 153.334 68.4971 154.79 67.7259L197.379 45.1715C199.62 43.9845 202.082 46.3352 200.999 48.629L178.862 95.5423C178.172 97.0032 178.946 98.735 180.494 99.1966L254.562 121.279C257.075 122.028 257.075 125.587 254.562 126.336L180.494 148.418C178.946 148.879 178.173 150.611 178.862 152.072L200.999 198.985C202.082 201.279 199.62 203.63 197.379 202.443Z"
        fill="url(#paint2_radial_12_5)"
        fillOpacity="0.54"
      />
      <defs>
        <radialGradient
          id="paint0_radial_12_5"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(221.934 168.674) rotate(-151.58) scale(201.399 208.953)"
        >
          <stop stopColor="#FF891D" />
          <stop offset="0.36182" stopColor="#FFA944" />
          <stop offset="0.724798" stopColor="#FFCE78" />
          <stop offset="1" stopColor="#FFBA6A" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_12_5"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(150.738 76.4556) rotate(127.266) scale(51.2924 53.2161)"
        >
          <stop stopColor="#F8A544" stopOpacity="0" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_12_5"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(114.384 83.8889) rotate(39.0585) scale(171.386 177.814)"
        >
          <stop offset="0.0188787" stopColor="#FDCE53" stopOpacity="0" />
          <stop offset="0.316869" stopColor="#F7BE56" stopOpacity="0.0913036" />
          <stop offset="0.761084" stopColor="#D46803" stopOpacity="0.5" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default FormoLogo;
