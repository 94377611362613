import { FC, HTMLAttributes } from 'react';

type NestWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const NestWallet: FC<NestWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      {...props}
    >
      <path fill="#101102" d="M0 0h28v28H0z" />
      <path
        fill="#EEF455"
        fillRule="evenodd"
        d="M16.732 17.367c-1.544 0-3.224-1.573-3.224-4.088h-3.19c0 3.763 2.635 7.28 6.414 7.28 3.78 0 6.415-3.517 6.415-7.28C23.147 9.516 20.51 6 16.732 6v3.19c1.544 0 3.224 1.574 3.224 4.09 0 2.514-1.68 4.087-3.224 4.087Zm-5.317-6.847c1.544 0 3.224 1.573 3.224 4.088h3.19c0-3.763-2.635-7.28-6.414-7.28-3.78 0-6.415 3.517-6.415 7.28 0 3.763 2.635 7.28 6.415 7.28v-3.192c-1.544 0-3.224-1.573-3.224-4.088s1.68-4.088 3.224-4.088Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default NestWallet;
