import { FC, HTMLAttributes } from 'react';

type EmailProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const Email: FC<EmailProps> = ({
  size = 40,
  shouldBlend = false,
  ...props
}) => {
  if (shouldBlend)
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_549_2138)">
          <rect x="5" y="4.75" width="30" height="30" rx="6" fill="#E3F7CE" />
          <rect
            x="5.5"
            y="5.25"
            width="29"
            height="29"
            rx="5.5"
            stroke="#73C51D"
            strokeOpacity="0.5"
          />
          <path
            d="M27.4786 12.6115H12.5216C12.161 12.6115 11.8152 12.7548 11.5602 13.0098C11.3052 13.2648 11.1619 13.6106 11.1619 13.9712V25.5289C11.1619 25.8895 11.3052 26.2353 11.5602 26.4903C11.8152 26.7453 12.161 26.8886 12.5216 26.8886H27.4786C27.8392 26.8886 28.1851 26.7453 28.4401 26.4903C28.6951 26.2353 28.8383 25.8895 28.8383 25.5289V13.9712C28.8383 13.6106 28.6951 13.2648 28.4401 13.0098C28.1851 12.7548 27.8392 12.6115 27.4786 12.6115Z"
            fill="#73C51D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0922 12.5418C11.4713 12.1627 11.9855 11.9497 12.5216 11.9497H27.4786C28.0147 11.9497 28.5289 12.1627 28.908 12.5418C29.2871 12.9209 29.5001 13.4351 29.5001 13.9712V25.5289C29.5001 26.065 29.2871 26.5792 28.908 26.9583C28.5289 27.3374 28.0147 27.5504 27.4786 27.5504H12.5216C11.9855 27.5504 11.4713 27.3374 11.0922 26.9583C10.7131 26.5792 10.5001 26.065 10.5001 25.5289V13.9712C10.5001 13.4351 10.7131 12.9209 11.0922 12.5418ZM12.5216 13.2733C12.3365 13.2733 12.159 13.3468 12.0281 13.4777C11.8973 13.6086 11.8237 13.7861 11.8237 13.9712V25.5289C11.8237 25.714 11.8973 25.8915 12.0281 26.0224C12.159 26.1533 12.3365 26.2268 12.5216 26.2268H27.4786C27.6637 26.2268 27.8412 26.1533 27.9721 26.0224C28.103 25.8915 28.1765 25.714 28.1765 25.5289V13.9712C28.1765 13.7861 28.103 13.6086 27.9721 13.4777C27.8412 13.3468 27.6637 13.2733 27.4786 13.2733H12.5216Z"
            fill="#73C51D"
          />
          <path
            d="M19.5291 20.5815C19.5373 20.5877 19.5453 20.5941 19.5532 20.6006C19.6786 20.7051 19.8366 20.7623 19.9999 20.7623C20.1631 20.7623 20.3211 20.7051 20.4465 20.6006C20.4544 20.5941 20.4624 20.5877 20.4706 20.5815L29.4909 13.7523V15.4125L21.2806 21.6284C20.9195 21.9241 20.467 22.0859 19.9999 22.0859C19.5328 22.0859 19.0802 21.9241 18.7191 21.6284L10.5298 15.4283V13.7682L19.5291 20.5815Z"
            fill="#E3F7CE"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_549_2138"
            x="0"
            y="0.75"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.890196 0 0 0 0 0.968627 0 0 0 0 0.807843 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_549_2138"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_549_2138"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.0507812"
        y="0.458008"
        width="24"
        height="24"
        rx="6"
        fill="#E3F7CE"
      />
      <rect
        x="0.550781"
        y="0.958008"
        width="23"
        height="23"
        rx="5.5"
        stroke="#73C51D"
        strokeOpacity="0.5"
      />
      <path
        d="M18.032 6.74722H6.06644C5.77794 6.74722 5.50126 6.86182 5.29726 7.06582C5.09327 7.26982 4.97866 7.5465 4.97866 7.83499V17.0811C4.97866 17.3696 5.09327 17.6463 5.29726 17.8503C5.50126 18.0543 5.77794 18.1689 6.06644 18.1689H18.032C18.3205 18.1689 18.5972 18.0543 18.8012 17.8503C19.0052 17.6463 19.1198 17.3696 19.1198 17.0811V7.83499C19.1198 7.5465 19.0052 7.26982 18.8012 7.06582C18.5972 6.86182 18.3205 6.74722 18.032 6.74722Z"
        fill="#73C51D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.92289 6.69145C5.22618 6.38816 5.63753 6.21777 6.06644 6.21777H18.032C18.4609 6.21777 18.8723 6.38816 19.1755 6.69145C19.4788 6.99473 19.6492 7.40608 19.6492 7.83499V17.0811C19.6492 17.51 19.4788 17.9214 19.1755 18.2247C18.8723 18.5279 18.4609 18.6983 18.032 18.6983H6.06644C5.63753 18.6983 5.22618 18.5279 4.92289 18.2247C4.6196 17.9214 4.44922 17.51 4.44922 17.0811V7.83499C4.44922 7.40608 4.6196 6.99473 4.92289 6.69145ZM6.06644 7.27666C5.91836 7.27666 5.77634 7.33548 5.67164 7.44019C5.56693 7.5449 5.5081 7.68691 5.5081 7.83499V17.0811C5.5081 17.2292 5.56693 17.3712 5.67164 17.4759C5.77634 17.5806 5.91836 17.6394 6.06644 17.6394H18.032C18.1801 17.6394 18.3221 17.5806 18.4268 17.4759C18.5315 17.3712 18.5903 17.2292 18.5903 17.0811V7.83499C18.5903 7.68691 18.5315 7.5449 18.4268 7.44019C18.3221 7.33548 18.1801 7.27666 18.032 7.27666H6.06644Z"
        fill="#73C51D"
      />
      <path
        d="M11.6721 13.1231C11.6787 13.1281 11.6851 13.1332 11.6914 13.1384C11.7917 13.222 11.9181 13.2678 12.0487 13.2678C12.1793 13.2678 12.3057 13.222 12.406 13.1384C12.4124 13.1332 12.4188 13.1281 12.4253 13.1231L19.6415 7.65979V8.98792L13.0733 13.9607C12.7844 14.1972 12.4224 14.3267 12.0487 14.3267C11.675 14.3267 11.313 14.1972 11.0241 13.9607L4.47266 9.00059V7.67247L11.6721 13.1231Z"
        fill="#E3F7CE"
      />
    </svg>
  );
};

export default Email;
