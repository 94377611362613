import { FC, Fragment, ReactNode } from 'react';
import { cn } from '~/lib/utils';

import TableTooltip from './TableTooltip';

type Header = {
  label: string;
  // eslint-disable-next-line no-unused-vars
  render?: (label: string) => JSX.Element;
  tooltip?: ReactNode;
  /** Column span. Defaults to `1` */
  size?: number;
};

type TableHeaderProps = {
  headers: Header[];
  className?: string;
  sticky?: boolean;
  th?: string;
};

const DefaultHeaderTemplate = (label: string) => (
  <div className="w-auto px-3 py-2 text-left font-semibold">{label}</div>
);

const TableHeader: FC<TableHeaderProps> = ({
  headers,
  sticky,
  className = '',
  th,
}) => {
  return (
    <thead>
      <tr className={cn('border-b border-solid', className)}>
        {headers.map(
          ({ label, render = DefaultHeaderTemplate, tooltip, size }, index) => {
            const Wrapper = tooltip ? TableTooltip : Fragment;

            const children = (
              <th
                key={index}
                className={cn(sticky && 'sticky top-0 z-10', th)}
                colSpan={size}
              >
                {render(label)}
              </th>
            );

            const tooltipProps: any = tooltip
              ? {
                  content: tooltip,
                  children,
                }
              : { children };

            return <Wrapper key={index} {...tooltipProps}></Wrapper>;
          },
        )}
      </tr>
    </thead>
  );
};

export default TableHeader;
