import { FC, HTMLAttributes } from 'react';

type EnkryptWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const EnkryptWallet: FC<EnkryptWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="28" height="28" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.87852 5.87869C6.54381 5.21339 7.4461 4.83965 8.38694 4.83965L23.2744 4.83951V7.56142C23.2744 8.23771 23.0057 8.88627 22.5276 9.36445C22.0494 9.84262 21.4008 10.1113 20.7246 10.1113H13.725C12.7842 10.1113 11.8819 10.485 11.2166 11.1503C10.5513 11.8156 10.1776 12.7178 10.1776 13.6587V14.5615C10.1776 15.5023 10.5513 16.4046 11.2166 17.0698C11.8819 17.7351 12.7842 18.1088 13.725 18.1088H20.7246C21.4008 18.1088 22.0494 18.3775 22.5276 18.8557C23.0057 19.3339 23.2744 19.9824 23.2744 20.6587V23.2746H8.38694C7.4461 23.2746 6.54381 22.9008 5.87852 22.2356C5.21326 21.5703 4.83951 20.668 4.83951 19.7271V8.38707C4.83951 7.44624 5.21326 6.54395 5.87852 5.87869ZM13.8783 11.5949H21.0573C22.2818 11.5949 23.2744 12.5875 23.2744 13.812V14.4085C23.2744 15.6329 22.2818 16.6256 21.0573 16.6256H13.8783C12.6538 16.6256 11.6612 15.6329 11.6612 14.4085V13.812C11.6612 12.5875 12.6538 11.5949 13.8783 11.5949Z"
        fill="url(#paint0_linear_3142_296)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3142_296"
          x1="6.69242"
          y1="4.83951"
          x2="19.6241"
          y2="24.1286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C549FF" />
          <stop offset="1" stopColor="#654BFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EnkryptWallet;
