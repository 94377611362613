import { FC, HTMLAttributes } from 'react';

type GateWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const GateWallet: FC<GateWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <g clip-path="url(#a)">
        <path fill="#fff" d="M0 0h28v28H0z" />
        <mask
          id="b"
          width="28"
          height="28"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'luminance' }}
        >
          <path fill="#fff" d="M0 0h28v28H0V0Z" />
        </mask>
        <g mask="url(#b)">
          <path
            fill="#0255EB"
            d="M0 22.512h28V28H0v-5.488Zm14-7.071a4.238 4.238 0 0 1-2.354-7.76A4.236 4.236 0 0 1 14 6.968V3.501a7.706 7.706 0 0 0-7.556 9.205 7.701 7.701 0 0 0 6.053 6.051 7.705 7.705 0 0 0 9.207-7.554h-3.47A4.231 4.231 0 0 1 14 15.44Z"
          />
          <path fill="#00E697" d="M18.237 6.967H14v4.236h4.237V6.967Z" />
          <path
            fill="url(#c)"
            d="M27.997 21.874c-4.201-1.007-8.955-1.576-13.999-1.576-5.044 0-9.797.569-13.998 1.576v.63a5.493 5.493 0 0 0 5.494 5.494h17.012A5.495 5.495 0 0 0 28 22.505l-.003-.63Z"
          />
          <path
            fill="#0255EB"
            d="M27.997 22.508c-4.17-1.052-8.935-1.65-13.999-1.65-5.063 0-9.829.598-13.998 1.65A5.493 5.493 0 0 0 5.494 28h17.012A5.493 5.493 0 0 0 28 22.507h-.003Z"
          />
          <path
            fill="#fff"
            d="M10.977 25.786h-.74l-.576-2.563-.588 2.563h-.74l-.651-2.942h.508l.522 2.472.583-2.471h.736l.568 2.462.519-2.462h.517l-.658 2.94Zm3.034-2.23v2.226h-.483v-.299a.98.98 0 0 1-.736.336c-.194.001-.385-.053-.55-.156a1.179 1.179 0 0 1-.404-1.57c.095-.172.233-.316.401-.418.166-.102.356-.155.55-.154a.944.944 0 0 1 .41.09c.124.058.235.14.326.241v-.298l.486.001Zm-.81 1.728a.684.684 0 0 0 .346-.607.703.703 0 0 0-1.316-.359.708.708 0 0 0 .254.966.717.717 0 0 0 .71 0h.005Zm1.277.502v-2.942h.483v2.942h-.483Zm.945 0v-2.942h.484v2.942h-.484Zm2.597-2.111a1.14 1.14 0 0 1 .56 1.17h-1.807c.018.101.058.197.117.282a.666.666 0 0 0 .555.26c.125 0 .248-.029.36-.084.1-.045.19-.11.264-.193l.425.117a.977.977 0 0 1-.425.42c-.192.11-.41.169-.63.17a1.16 1.16 0 0 1-1.151-1.152 1.141 1.141 0 0 1 .575-.994 1.153 1.153 0 0 1 1.151 0l.006.004Zm-.867.355a.71.71 0 0 0-.24.18.621.621 0 0 0-.132.264h1.341a.665.665 0 0 0-.374-.444.69.69 0 0 0-.595 0Zm2.053-1.004h.474v.533h.686v.425h-.686v1.042c0 .059.016.117.047.167.03.05.073.093.124.123a.33.33 0 0 0 .17.046h.366v.424h-.467a.72.72 0 0 1-.718-.72v-1.08h-.42v-.425h.42l.004-.535Z"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="c"
          x1="-18.993"
          x2="48.217"
          y1="24.148"
          y2="24.148"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".25" stopColor="#fff" />
          <stop offset=".5" stopColor="#00E79D" />
          <stop offset=".75" stopColor="#fff" />
        </linearGradient>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GateWallet;
