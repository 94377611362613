import { FC, HTMLAttributes } from 'react';

type EventDeviceDesktopProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const EventDeviceDesktop: FC<EventDeviceDesktopProps> = ({
  size = 20,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={(21 * size) / 20}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.4667 13.3332V5.83317C17.4667 5.39114 17.2911 4.96722 16.9785 4.65466C16.666 4.3421 16.2421 4.1665 15.8 4.1665H5.80003C5.358 4.1665 4.93407 4.3421 4.62151 4.65466C4.30895 4.96722 4.13336 5.39114 4.13336 5.83317V13.3332M17.4667 13.3332H4.13336M17.4667 13.3332L18.5334 15.4582C18.5976 15.5857 18.628 15.7275 18.6217 15.8701C18.6154 16.0128 18.5725 16.1514 18.4972 16.2727C18.4219 16.394 18.3167 16.4939 18.1917 16.5628C18.0667 16.6318 17.9261 16.6675 17.7834 16.6665H3.81669C3.67393 16.6675 3.53331 16.6318 3.40831 16.5628C3.28331 16.4939 3.17813 16.394 3.10284 16.2727C3.02755 16.1514 2.98469 16.0128 2.97836 15.8701C2.97203 15.7275 3.00245 15.5857 3.06669 15.4582L4.13336 13.3332"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EventDeviceDesktop;
