import { FC, HTMLAttributes } from 'react';

type XDeFiWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const XDeFiWallet: FC<XDeFiWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      {...props}
    >
      <g clipPath="url(#a)">
        <path fill="#335DE5" d="M0 0h28v28H0z" />
        <mask
          id="b"
          width="28"
          height="28"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path fill="#fff" d="M28 0H0v28h28V0Z" />
        </mask>
        <g mask="url(#b)">
          <path
            fill="#335DE5"
            d="M22.313 0H5.688A5.687 5.687 0 0 0 0 5.688v16.625A5.688 5.688 0 0 0 5.688 28h16.625A5.688 5.688 0 0 0 28 22.312V5.688A5.688 5.688 0 0 0 22.312 0Z"
          />
          <path
            fill="#ECECEC"
            d="M25.344 13.193a11.282 11.282 0 0 0-1.933-5.522 11.52 11.52 0 0 0-4.458-3.854 11.73 11.73 0 0 0-11.45.641A11.463 11.463 0 0 0 3.52 8.785l-.044.088a6.357 6.357 0 0 0-.585 1.884c-.273 1.932.15 3.651 1.257 4.975 1.21 1.447 3.18 2.308 5.544 2.421 2.878.143 5.739-.63 7.77-2.05l1.203.705c-1.15.981-3.809 2.748-8.213 2.99-4.943.269-7.003-1.314-7.023-1.33l-.346.421-.457.54c.088.073 2.063 1.644 6.703 1.644.38 0 .78 0 1.195-.032 5.33-.293 8.26-2.58 9.287-3.561l1.006.602a9.165 9.165 0 0 1-2.459 2.206c-3.345 2.126-7.6 2.4-10.578 2.252l-.063 1.24c.5.024.981.035 1.449.035 8.392 0 11.788-3.76 12.74-5.109l.786.461a10.22 10.22 0 0 1-3.007 3.197 10.417 10.417 0 0 1-4.967 1.776l.11 1.235a11.69 11.69 0 0 0 5.576-1.992 11.444 11.444 0 0 0 3.843-4.45 11.25 11.25 0 0 0 1.098-5.74Zm-4.847.191a1 1 0 0 1-1.008-.993 1 1 0 0 1 1.008-.992 1 1 0 0 1 1.009.992 1 1 0 0 1-1.009.993Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default XDeFiWallet;
