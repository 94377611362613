import { FC, HTMLAttributes } from 'react';

type HelpCenterProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const HelpCenter: FC<HelpCenterProps> = ({
  size = 20,
  color = '#030712',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={(21 * size) / 20}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_727_7070)">
        <path
          d="M10.0001 19.2289C14.6025 19.2289 18.3334 15.498 18.3334 10.8956C18.3334 6.29322 14.6025 2.56226 10.0001 2.56226C5.39771 2.56226 1.66675 6.29322 1.66675 10.8956C1.66675 15.498 5.39771 19.2289 10.0001 19.2289Z"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.57495 8.39598C7.77087 7.83904 8.15758 7.3694 8.66658 7.07026C9.17558 6.77111 9.77403 6.66176 10.3559 6.76158C10.9378 6.86139 11.4656 7.16392 11.8458 7.61559C12.2261 8.06726 12.4342 8.63892 12.4333 9.22932C12.4333 10.896 9.93328 11.7293 9.93328 11.7293"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 15.0623H10.0083"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_727_7070">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.895508)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HelpCenter;
