import { FC, HTMLAttributes } from 'react';

type KaikasWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const KaikasWallet: FC<KaikasWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#fff" d="M0 0h26v26H0z" />
      <mask
        id="a"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="26"
      >
        <path d="M26 0H0v25.95h26V0Z" fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path
          d="m17.828 13.081-1.206 2.581c-.605 1.296-1.834 2.114-3.176 2.114H5.423C4.088 17.771 3 18.951 3 20.386 3 21.82 4.088 23 5.423 23h16.368c.97 0 1.543-1.17.998-2.034l-4.959-7.885h-.002Z"
          fill="#99B3FF"
        />
        <path
          d="M20.574 3H6.654C4.637 3 3 4.765 3 6.944v13.442c0-1.435 1.088-2.615 2.423-2.615h8.025c1.342 0 2.572-.818 3.177-2.114L21.65 4.884C22.055 4.02 21.473 3 20.574 3Z"
          fill="#36F"
        />
        <path
          d="M13.836 11.122c.813 0 1.473-.555 1.473-1.241 0-.687-.66-1.242-1.473-1.242-.814 0-1.474.555-1.474 1.242 0 .686.66 1.241 1.474 1.241Z"
          fill="#DAFF86"
        />
      </g>
    </svg>
  );
};

export default KaikasWallet;
