import { FC, HTMLAttributes } from 'react';

type EventActionIdentifyProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const EventActionIdentify: FC<EventActionIdentifyProps> = ({
  size = 16,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path
        style={{
          fill: 'none',
          strokeWidth: 2,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
        }}
        d="M 12 22.001953 L 12 16.998047 "
        transform="matrix(0.666667,0,0,0.666667,0,0)"
      />
      <path
        style={{
          fill: 'none',
          strokeWidth: 2,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
        }}
        d="M 9 7.998047 L 9 1.998047 "
        transform="matrix(0.666667,0,0,0.666667,0,0)"
      />
      <path
        style={{
          fill: 'none',
          strokeWidth: 2,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
        }}
        d="M 15 7.998047 L 15 1.998047 "
        transform="matrix(0.666667,0,0,0.666667,0,0)"
      />
      <path
        style={{
          fill: 'none',
          strokeWidth: 2,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
        }}
        d="M 18 7.998047 L 18 13.001953 C 18 15.210938 16.207031 16.998047 13.998047 16.998047 L 10.001953 16.998047 C 7.792969 16.998047 6 15.210938 6 13.001953 L 6 7.998047 Z M 18 7.998047 "
        transform="matrix(0.666667,0,0,0.666667,0,0)"
      />
    </svg>
  );
};

export default EventActionIdentify;
