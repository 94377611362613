import {
  CreateFormRequest,
  CreateFormResponse,
  GetTemplatesResponse,
} from '@formo/shared';
import { FormSchema } from '@formo/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { ButtonHTMLAttributes, FC } from 'react';
import { useAuth } from '~/app/hooks/AuthProvider';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '~/components/ui/dialog';
import client from '~/lib/client';
import { cn } from '~/lib/utils';

import { Spinner } from '../../common';

type TemplateOptionProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  thumbnail?: string | null;
  name: string;
  description?: string | null;
};

const TemplateOption: FC<TemplateOptionProps> = ({
  thumbnail,
  name,
  description,
  className,
  ...props
}) => (
  <button
    className={cn(
      'relative flex flex-1 flex-col overflow-clip rounded-xl border border-black/10 p-3 text-left shadow-[0px_0px_5px_0px_rgba(0,0,0,0.05)] transition-all',
      'hover:border-[rgba(51,74,28,0.22)] hover:shadow-[1px_1px_10px_0px_rgba(0,0,0,0.15)] hover:ring-1 hover:ring-[rgba(51,74,28,0.22)] hover:ring-offset-0',
      className,
    )}
    {...props}
  >
    {thumbnail && (
      <img
        src={thumbnail}
        alt="name"
        className="mb-3 aspect-video w-full rounded-md object-cover object-center"
      />
    )}
    <h2 className="line-clamp-2 text-base font-medium">{name}</h2>
    {description && (
      <p className="mt-0.5 line-clamp-3 text-sm text-gray-700">{description}</p>
    )}
  </button>
);

export type CreateFormModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: (form: FormSchema) => void;
  teamId: string;
};

const CreateFormModal: FC<CreateFormModalProps> = ({
  open,
  setOpen,
  onSubmit,
  teamId,
}) => {
  const { user } = useAuth();

  const { mutateAsync, isPending: isCreating } = useMutation<
    CreateFormResponse,
    Error,
    CreateFormRequest['body']
  >({
    mutationFn: async (data) =>
      (await client.post(`/api/teams/${teamId}/forms`, data)).data,
  });

  const { data: templates = [], isLoading } = useQuery<GetTemplatesResponse>({
    queryKey: ['templates'],
    queryFn: async () => (await client.get('/api/templates')).data,
    refetchOnWindowFocus: false,
  });

  const createForm = async (id?: string) => {
    if (!user || !teamId) return;

    try {
      const form = await mutateAsync({
        version: 1,
        templateId: id,
      });

      onSubmit?.(form);
      setOpen?.(false);
    } catch (error) {
      console.error('Failed to create form:', error);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onOpenChange={(value) => {
          if (isCreating) return;
          setOpen((prev) => (prev === value ? prev : value));
        }}
      >
        <DialogContent className="max-h-[80lvh] min-w-[800px] gap-0 overflow-y-auto p-0 rounded-lg">
          <DialogHeader>
            <DialogTitle className="px-8 pb-[11px] pt-[18px] text-2xl font-medium text-black">
              Select a template
            </DialogTitle>
          </DialogHeader>
          <div className="border-b-1.5 border-black/5"></div>
          <div className="px-8 pb-9.5 pt-7.5">
            {isLoading && (
              <div className="flex h-20 w-full items-center justify-center">
                <Spinner />
              </div>
            )}
            {templates.length !== 0 ? (
              <div className="grid grid-cols-3 gap-5">
                {templates.map(({ id, thumbnail, description, name }) => (
                  <TemplateOption
                    key={id}
                    thumbnail={thumbnail}
                    name={name}
                    description={description}
                    onClick={() => createForm(id)}
                    disabled={isCreating}
                  />
                ))}
              </div>
            ) : null}
            {templates.length === 0 && !isLoading ? (
              <div className="text-center">
                <h2 className="mt-4 text-lg font-semibold">
                  No templates found
                </h2>
                <p className="text-sm">
                  There are no templates available at the moment
                </p>
              </div>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateFormModal;
