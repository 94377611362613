import { FC, HTMLAttributes } from 'react';

type SubWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const SubWallet: FC<SubWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      {...props}
    >
      <defs>
        <linearGradient
          id="a"
          x1="352"
          x2="352"
          y1="0"
          y2="704"
          gradientTransform="scale(.03977)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0"
            style={{
              stopColor: '#004bff',
              stopOpacity: 1,
            }}
          />
          <stop
            offset="1"
            style={{
              stopColor: '#4ceaac',
              stopOpacity: 1,
            }}
          />
        </linearGradient>
        <clipPath id="b">
          <path d="M7.637 4.453h12.726v19.094H7.637Zm0 0" />
        </clipPath>
      </defs>
      <path
        d="M0 0h28v28H0z"
        style={{
          fill: 'url(#a)',
          stroke: 'none',
        }}
      />
      <g clipPath="url(#b)">
        <path
          d="M20.363 11.36V8.71L9.753 4.454 7.638 5.531l.011 8.246 7.934 3.196-4.238 1.804v-1.394l-1.946-.793-1.75.828v5.05l2.11 1.079 10.605-4.785v-3.395l-9.547-3.82v-2.32l7.575 3.027Zm0 0"
          style={{
            stroke: 'none',
            fill: '#fff',
            fillRule: 'nonzero',
            fillOpacity: 1,
          }}
        />
      </g>
    </svg>
  );
};

export default SubWallet;
