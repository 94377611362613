import { FC, HTMLAttributes } from 'react';

type FileUploadIconProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
  isSuccess?: boolean;
};

const FileUploadIcon: FC<FileUploadIconProps> = ({
  size = 42,
  isSuccess = true,
  ...props
}) => {
  if (isSuccess)
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={(43 * size) / 42}
        viewBox="0 0 42 43"
        fill="none"
        {...props}
      >
        <path
          d="M5 4.75795C5 3.65339 5.89542 2.75796 6.99998 2.75795L18.8839 2.75781L36.9553 21.7525L36.9928 38.7534C36.9953 39.8597 36.0991 40.7578 34.9928 40.7578H7C5.89543 40.7578 5 39.8624 5 38.7578V4.75795Z"
          fill="url(#paint0_radial_6125_14219)"
        />
        <path
          d="M5.15 4.75795C5.15 3.73623 5.97826 2.90796 6.99998 2.90795L18.8196 2.90781L36.8054 21.8126L36.8428 38.7537C36.8451 39.7771 36.0161 40.6078 34.9928 40.6078H7C5.97827 40.6078 5.15 39.7795 5.15 38.7578V4.75795Z"
          stroke="black"
          strokeOpacity="0.05"
          strokeWidth="0.3"
        />
        <path
          d="M18.8849 17.7578L18.8853 2.75781L37.0002 21.7579H22.8849C20.6757 21.7579 18.8848 19.9669 18.8849 17.7578Z"
          fill="url(#paint1_linear_6125_14219)"
        />
        <path
          d="M18.9938 2.65431L18.7353 2.38312L18.7353 2.75781L18.7349 17.7578C18.7348 20.0498 20.5928 21.9079 22.8849 21.9079H37.0002H37.3504L37.1087 21.6544L18.9938 2.65431Z"
          stroke="white"
          strokeOpacity="0.3"
          strokeWidth="0.3"
        />
        <defs>
          <radialGradient
            id="paint0_radial_6125_14219"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(33.0033 5.28933) rotate(121.597) scale(42.8524 36.0117)"
          >
            <stop offset="0.400501" stopColor="#A6A6AB" />
            <stop offset="0.544522" stopColor="#AEAEB3" />
            <stop offset="0.959909" stopColor="#C7C7CA" />
          </radialGradient>
          <linearGradient
            id="paint1_linear_6125_14219"
            x1="29.9685"
            y1="8.8267"
            x2="20.1021"
            y2="21.7579"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EDEDED" />
            <stop offset="0.938277" stopColor="#C7C7CA" />
          </linearGradient>
        </defs>
      </svg>
    );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 42 42"
      fill="none"
      {...props}
    >
      <path
        d="M5 3.99623C5 2.89167 5.89542 1.99624 6.99998 1.99623L18.8839 1.99609L36.9553 20.9908L36.9928 37.9917C36.9953 39.098 36.0991 39.9961 34.9928 39.9961H7C5.89543 39.9961 5 39.1007 5 37.9961V3.99623Z"
        fill="url(#paint0_radial_5874_21415)"
      />
      <path
        d="M5.15 3.99623C5.15 2.97451 5.97826 2.14624 6.99998 2.14623L18.8196 2.14609L36.8054 21.0509L36.8428 37.992C36.8451 39.0153 36.0161 39.8461 34.9928 39.8461H7C5.97827 39.8461 5.15 39.0178 5.15 37.9961V3.99623Z"
        stroke="black"
        strokeOpacity="0.05"
        strokeWidth="0.3"
      />
      <path
        d="M18.8849 16.996L18.8853 1.99609L37.0002 20.9962H22.8849C20.6757 20.9962 18.8848 19.2052 18.8849 16.996Z"
        fill="url(#paint1_linear_5874_21415)"
      />
      <path
        d="M18.9938 1.89259L18.7353 1.6214L18.7353 1.99609L18.7349 16.996C18.7348 19.2881 20.5928 21.1462 22.8849 21.1462H37.0002H37.3504L37.1087 20.8926L18.9938 1.89259Z"
        stroke="white"
        strokeOpacity="0.3"
        strokeWidth="0.3"
      />
      <defs>
        <radialGradient
          id="paint0_radial_5874_21415"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.0033 4.52761) rotate(121.597) scale(42.8524 36.0117)"
        >
          <stop offset="0.416108" stopColor="#F5667A" />
          <stop offset="0.959909" stopColor="#FAB3BD" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_5874_21415"
          x1="29.9685"
          y1="8.06498"
          x2="20.1021"
          y2="20.9962"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD8DD" />
          <stop offset="0.804866" stopColor="#FAB3BD" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FileUploadIcon;
