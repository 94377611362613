import { FC, HTMLAttributes } from 'react';

type BloomWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const BloomWallet: FC<BloomWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 128 128"
      {...props}
    >
      <g filter="url(#a)">
        <path fill="url(#b)" d="M0 0h128v128H0z" />
      </g>
      <path
        fill="url(#c)"
        d="M64.01 66.918c-1.113 2.016-.626 5.563 9.667 18.082 17.456 21.073 27.332 5.842 23.02-2.225 9.459 16.761-7.859 30.949-21.212 25.037-7.58-3.408-13.77-13.91-16.205-23.924-3.895-16.205 3.269-17.457 4.73-16.97"
      />
      <path
        fill="url(#d)"
        d="M64.01 66.918c1.6 1.67 5.077 2.365 20.17-3.616 25.454-10.085 13.978-24.203 5.007-22.604 18.847-3.825 27.054 17.04 17.248 27.959-5.564 6.19-17.457 8.833-27.75 7.998-16.622-1.321-15.58-8.555-14.675-9.737"
      />
      <path
        fill="url(#e)"
        d="M64.01 66.918c2.087-.974 3.825-4.104 2.782-20.309-1.739-27.263-18.709-20.795-19.96-11.753 2.225-19.126 24.55-20.447 31.922-7.79 4.173 7.233 2.991 19.335-.973 28.863-6.399 15.44-12.936 12.171-13.77 10.989"
      />
      <path
        fill="url(#f)"
        d="M66.792 46.61c-1.739-27.263-18.778-20.795-19.96-11.754-.348 2.782-.21 5.703.277 8.624a49.4 49.4 0 0 1 10.711 6.885c10.293 8.763 8.902 13.91 7.233 15.718 1.53-1.669 2.573-6.05 1.739-19.473"
      />
      <path
        fill="url(#g)"
        d="M64.01 66.918c-.279-2.295-2.713-4.869-18.43-8.902C19.08 51.2 20.054 69.352 28.261 73.386c-17.457-7.998-11.824-29.697 2.434-32.758 8.137-1.738 19.265 3.13 27.124 9.807 12.658 10.78 7.58 15.996 6.19 16.483"
      />
      <path
        fill="url(#h)"
        d="M64.01 66.918c-2.296-.417-5.495 1.113-14.189 14.814-14.674 23.09 2.922 27.819 9.25 21.212-13.005 14.119-31.922 2.087-30.392-12.449.834-8.276 8.971-17.387 17.735-22.742 14.187-8.763 17.595-2.296 17.595-.835"
      />
      <path
        fill="url(#i)"
        d="M84.179 63.302c25.454-10.085 13.979-24.273 5.007-22.604-2.712.487-5.494 1.6-8.137 2.921-.556 4.173-1.67 8.485-3.269 12.31-5.147 12.52-10.432 12.728-12.727 11.754 2.086.974 6.607.557 19.126-4.381"
      />
      <path
        fill="url(#j)"
        d="M73.677 85c17.456 21.074 27.402 5.773 23.02-2.225-1.321-2.434-3.2-4.73-5.285-6.816-4.174.765-8.555 1.044-12.728.696-13.492-1.043-15.3-5.981-15.092-8.485-.278 2.295 1.53 6.468 10.085 16.83"
      />
      <path
        fill="url(#k)"
        d="M49.822 81.732c-14.675 23.09 2.99 27.889 9.25 21.212 1.878-2.017 3.547-4.52 4.868-7.163a49.5 49.5 0 0 1-4.59-11.893c-3.2-13.145.974-16.414 3.408-16.97-2.365.487-5.773 3.477-12.936 14.814"
      />
      <path
        fill="url(#l)"
        d="M45.51 58.016C19.012 51.2 19.916 69.49 28.193 73.386c2.503 1.182 5.355 1.947 8.345 2.365a52.2 52.2 0 0 1 9.876-8.068c11.545-7.094 15.927-4.173 17.179-2.017-1.183-2.017-5.077-4.312-18.083-7.65"
      />
      <defs>
        <linearGradient
          id="b"
          x1="19.678"
          x2="81.672"
          y1="-83.848"
          y2="122.802"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B882FF" />
          <stop offset="1" stopColor="#2F00A6" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="59.455"
          x2="93.998"
          y1="69.265"
          y2="104.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity=".1" />
          <stop offset=".25" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="64.77"
          x2="108.53"
          y1="72.027"
          y2="49.925"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity=".1" />
          <stop offset=".25" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="e"
          x1="69.051"
          x2="61.551"
          y1="67.69"
          y2="19.244"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity=".1" />
          <stop offset=".25" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="f"
          x1="59.85"
          x2="55.672"
          y1="27.609"
          y2="55.361"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity=".9" />
          <stop offset="1" stopColor="#fff" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="g"
          x1="66.33"
          x2="17.936"
          y1="62.373"
          y2="54.536"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity=".1" />
          <stop offset=".25" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="h"
          x1="60.312"
          x2="37.902"
          y1="63.308"
          y2="106.909"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity=".1" />
          <stop offset=".25" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="i"
          x1="100.047"
          x2="72.361"
          y1="50.893"
          y2="55.495"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity=".9" />
          <stop offset="1" stopColor="#fff" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="j"
          x1="90.457"
          x2="77.524"
          y1="96.282"
          y2="71.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity=".9" />
          <stop offset="1" stopColor="#fff" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="k"
          x1="44.197"
          x2="63.889"
          y1="101.142"
          y2="81.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity=".9" />
          <stop offset="1" stopColor="#fff" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="l"
          x1="25.369"
          x2="50.473"
          y1="58.734"
          y2="71.283"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity=".9" />
          <stop offset="1" stopColor="#fff" stopOpacity=".1" />
        </linearGradient>
        <filter
          id="a"
          width="130.718"
          height="130.718"
          x="-1.359"
          y="-1.359"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation=".68" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_159_287"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_159_287"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default BloomWallet;
