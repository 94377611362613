import { ReactNode } from 'react';

import cn from '../lib/cn';
import { TabsContent, TabsCore, TabsList, TabsTrigger } from './tabs.core';

type TabItem = {
  label: ReactNode;
  content: ReactNode;
  icon?: ReactNode;
};

export type TabsProps<T extends string = string> = {
  classnames?: {
    tabs?: string;
    tabsList?: string;
    tabsTrigger?: string;
    tabsContent?: string;
  };
  tabs: Array<TabItem & { key: T }>;
  defaultValue?: T;
};

function Tabs({ classnames, tabs, defaultValue }: TabsProps) {
  const navigation = tabs.map((tab) => (
    <TabsTrigger
      key={tab.key}
      value={tab.key}
      className={cn('', classnames?.tabsTrigger)}
    >
      {tab?.icon}
      <span>{tab.label}</span>
    </TabsTrigger>
  ));

  const contents = tabs.map((tab) => (
    <TabsContent
      key={tab.key}
      value={tab.key}
      className={cn('', classnames?.tabsContent)}
    >
      {tab.content}
    </TabsContent>
  ));

  return (
    <TabsCore
      defaultValue={defaultValue}
      className={cn('w-full', classnames?.tabs)}
    >
      <TabsList className={cn('', classnames?.tabsList)}>{navigation}</TabsList>
      {contents}
    </TabsCore>
  );
}

Tabs.displayName = 'Tabs';

export default Tabs;
