import { FC, HTMLAttributes } from 'react';

type SafeWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const SafeWallet: FC<SafeWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="28" height="28" fill="#12FF80" />
      <path
        d="M22.5151 13.9979H20.4244C19.7981 13.9979 19.2945 14.5058 19.2945 15.128V18.163C19.2945 18.7894 18.7866 19.2931 18.1645 19.2931H9.8398C9.21344 19.2931 8.70981 19.8011 8.70981 20.4233V22.5185C8.70981 23.145 9.21767 23.6487 9.8398 23.6487H18.6427C19.2691 23.6487 19.7642 23.1407 19.7642 22.5185V20.8423C19.7642 20.2159 20.2721 19.7757 20.8942 19.7757H22.5151C23.1415 19.7757 23.6451 19.2677 23.6451 18.6455V15.1196C23.6451 14.4889 23.1373 13.9979 22.5151 13.9979Z"
        fill="black"
      />
      <path
        d="M8.7098 9.84127C8.7098 9.21481 9.21766 8.71111 9.83978 8.71111H18.156C18.7823 8.71111 19.286 8.20317 19.286 7.58095V5.48995C19.286 4.86349 18.7781 4.35979 18.156 4.35979H9.35732C8.73096 4.35979 8.22733 4.86772 8.22733 5.48995V7.10264C8.22733 7.7291 7.71947 8.2328 7.09734 8.2328H5.48912C4.86276 8.2328 4.35913 8.74074 4.35913 9.36296V12.8931C4.35913 13.5196 4.86699 13.9979 5.49335 13.9979H7.58404C8.2104 13.9979 8.71403 13.4899 8.71403 12.8677L8.7098 9.84127Z"
        fill="black"
      />
      <path
        d="M13.0139 11.8011H15.0242C15.6802 11.8011 16.2092 12.3344 16.2092 12.9862V14.9968C16.2092 15.6529 15.6759 16.182 15.0242 16.182H13.0139C12.3579 16.182 11.8289 15.6487 11.8289 14.9968V12.9862C11.8289 12.3302 12.3621 11.8011 13.0139 11.8011Z"
        fill="black"
      />
    </svg>
  );
};

export default SafeWallet;
