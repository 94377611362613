import { FC, HTMLAttributes } from 'react';

type ZerionWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const ZerionWallet: FC<ZerionWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 28 28"
      width={size}
      height={size}
      {...props}
    >
      <path fill="#2962EF" d="M0 0h28v28H0z" />
      <path
        fill="#fff"
        d="M6.073 7c-.48 0-.665.593-.262.841l10.073 6.074a.577.577 0 0 0 .758-.139l4.43-5.814c.3-.404-.004-.962-.525-.962H6.073ZM21.904 21c.48 0 .67-.596.267-.844l-10.075-6.073a.569.569 0 0 0-.751.146l-4.437 5.813c-.301.404.012.958.534.958h14.462Z"
      />
    </svg>
  );
};

export default ZerionWallet;
