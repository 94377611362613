export const FORM_STATUS_MAPPER = ['Draft', 'Published', 'Closed', 'Archived'];

export enum FORM_STATUS {
  DRAFT = 0,
  PUBLISHED = 1,
  CLOSED = 2,
  ARCHIVED = 3,
}

export const FORM_STATUS_CLASSNAMES = [
  'bg-positive-50 text-positive-800',
  'bg-blue-50 text-[#3E486D]',
  'bg-red-50 text-negative-800',
  'bg-base-dark-10 text-base-dark-400',
];

export const TEAM_INVITE_STATUS_MAPPER = [
  'Pending',
  'Accepted',
  'Declined',
  'Expired',
];

// Match 1 or more alphanumeric characters, hyphens
// Cannot start or end with a hyphen
export const validSlugRegex = /(^[a-z0-9]+[a-z0-9-]*[a-z0-9]+$)|(^[a-z0-9]+$)/g;
