import { FC, HTMLAttributes } from 'react';

type FoxWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const FoxWallet: FC<FoxWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 900 900"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="900" height="900" rx="450" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M577.249 215.975C539.956 196.232 511.646 161.549 500.468 119.868C497.021 132.613 495.245 145.984 495.245 159.774C495.245 170.533 496.394 180.98 498.483 191.113C498.483 191.113 498.483 191.113 498.483 191.217C498.483 191.322 498.588 191.531 498.588 191.635C501.408 205.216 506.005 218.065 512.168 229.974C499.006 220.258 487.306 208.663 477.486 195.605C464.323 297.667 501.408 403.907 569.206 473.898C657.687 576.9 576.1 751.669 438.207 747.073C243.068 748.744 209.639 461.362 396.839 416.234L396.735 415.711C446.669 399.519 470.069 367.031 474.143 324.827C402.063 383.223 288.196 310.829 311.805 220.154C41.2425 353.346 141.737 785.411 448.445 780.083C582.055 780.083 695.085 691.706 732.17 570.214C776.463 428.561 704.8 277.609 577.249 215.975Z"
        fill="#12FE74"
      />
    </svg>
  );
};

export default FoxWallet;
