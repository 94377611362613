import { FC, HTMLAttributes } from 'react';

type DesigWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const DesigWallet: FC<DesigWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <g clipPath="url(#a)">
        <path fill="#F8F8F8" d="M0 0h28v28H0z" />
        <path fill="#F8F8F8" d="M0 0h28v28H0z" />
        <path
          fill="#5746DB"
          d="M12.045 8.814A1.959 1.959 0 0 1 14 6.846H8.805a1.962 1.962 0 0 0-1.958 1.967 1.962 1.962 0 0 0 1.958 1.968H14a1.962 1.962 0 0 1-1.955-1.967Z"
        />
        <path
          fill="#7767F6"
          d="M19.15 6.847h-5.196a1.959 1.959 0 0 0-1.907 1.532 1.964 1.964 0 0 0 1.907 2.402h5.193a1.96 1.96 0 0 0 1.96-1.967 1.963 1.963 0 0 0-1.957-1.967Z"
        />
        <path
          fill="#5746DB"
          d="M13.68 14a1.963 1.963 0 0 1 1.96-1.967H8.784a1.941 1.941 0 0 0-1.937 1.945v.074a1.932 1.932 0 0 0 1.937 1.915h6.856A1.964 1.964 0 0 1 13.68 14Z"
        />
        <path
          fill="#7767F6"
          d="M19.216 12.033H15.64A1.963 1.963 0 0 0 13.68 14a1.962 1.962 0 0 0 1.96 1.967h3.576a1.932 1.932 0 0 0 1.937-1.914v-.075a1.941 1.941 0 0 0-1.937-1.945ZM19.306 17.219h-5.292a1.963 1.963 0 0 0-1.958 1.968V19.295a1.974 1.974 0 0 0 1.197 1.706c.24.1.497.153.756.152 2.517 0 4.614-1.323 5.74-2.852l.124-.173a.635.635 0 0 0-.567-.909Z"
        />
        <path
          fill="#5746DB"
          d="M13.03 20.886a1.828 1.828 0 0 1-.261-.183 1.975 1.975 0 0 1-.708-1.408V19.187a1.963 1.963 0 0 1 1.958-1.968l-5.24-.022h-.073a.633.633 0 0 0-.513.955l.072.095A7.09 7.09 0 0 0 14 21.153h.01a1.94 1.94 0 0 1-.98-.267Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DesigWallet;
