import { FC, HTMLAttributes } from 'react';

type KaiaWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const KaiaWallet: FC<KaiaWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_1402_4349)">
        <mask
          id="mask0_1402_4349"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="28"
          height="28"
        >
          <path d="M28 0H0V28H28V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1402_4349)">
          <path
            d="M21.875 0H6.125C2.74226 0 0 2.74226 0 6.125V21.875C0 25.2577 2.74226 28 6.125 28H21.875C25.2577 28 28 25.2577 28 21.875V6.125C28 2.74226 25.2577 0 21.875 0Z"
            fill="#BFF009"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.36608 9.02197L12.4087 18.4791L12.7029 17.5656L9.95425 9.02197H10.9729L13.2124 15.9828L13.5059 15.0692L11.561 9.02197H12.5797L14.0155 13.4856L15.4521 9.02197H22.8672L19.3135 20.0664H11.8985L11.8998 20.0628L8.34741 9.02197H9.36608ZM7.7593 9.02197L11.313 20.0664H10.2943L6.7406 9.02197H7.7593ZM6.15248 9.02197L9.7061 20.0664H8.68743L5.13379 9.02197H6.15248Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1402_4349">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KaiaWallet;
