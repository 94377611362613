import { FC, HTMLAttributes } from 'react';

type MewWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const MewWallet: FC<MewWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 120 120"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M37.0828183,120 C23.473424,120 15.6489493,120 7.82447466,112.175525 C-4.66529593e-08,104.351051 0,96.526576 0,82.9171817 L0,37.0828183 C0,23.473424 -4.66529593e-08,15.6489493 7.82447466,7.82447466 C15.6489493,0 23.473424,0 37.0828183,0 L82.9171817,0 C96.526576,0 104.351051,0 112.175525,7.82447466 C120,15.6489493 120,23.473424 120,37.0828183 L120,82.9171817 C120,96.526576 120,104.351051 112.175525,112.175525 C104.351051,120 96.526576,120 82.9171817,120 L37.0828183,120 Z"
          id="path-1"
        ></path>
      </defs>
      <g
        id="Press-versions"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <path
            stroke="#DADCE0"
            d="M84.5656411,0.50054281 C96.991511,0.513035658 104.415006,0.771062258 111.821972,8.17802805 C119.228938,15.5849938 119.486964,23.008489 119.499457,35.4340955 L119.499457,35.4340955 L119.499997,36.8040129 L119.499997,36.8040129 L119.5,37.0828183 L119.5,82.9171636 L119.49998,83.4729908 L119.499457,84.5656411 C119.486964,96.991511 119.228938,104.415006 111.821972,111.821972 C104.415006,119.228938 96.991511,119.486964 84.5659045,119.499457 L84.5659045,119.499457 L83.1959871,119.499997 L83.1959871,119.499997 L82.9171817,119.5 L37.0828364,119.5 L36.5270092,119.49998 L35.4343589,119.499457 C23.008489,119.486964 15.5849938,119.228938 8.17802805,111.821972 C0.771062212,104.415006 0.513035654,96.991511 0.500543063,84.5661438 L0.500543063,84.5661438 L0.50054281,35.4343589 C0.513035654,23.008489 0.771062212,15.5849938 8.17802805,8.17802805 C15.5849938,0.771062258 23.008489,0.513035658 35.4338562,0.500543063 L35.4338562,0.500543063 Z"
          ></path>
          <g id="Group" mask="url(#mask-2)">
            <g id="icon" transform="translate(2.000000, 2.000000)">
              <path
                d="M78.2902062,106.530612 C100.188166,107.566326 102.396167,88.4204441 95.9815645,77.2712546 C89.7672785,66.4702342 80.4458501,62.7712544 65.3703851,64.1900465 C50.29492,65.6088385 65.4352032,90.169341 75.777958,88.3681934 C80.4702531,87.5510499 76.3984486,106.441137 78.2902062,106.530612 Z"
                id="right-arm"
                stroke="#000000"
                strokeWidth="2.5"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <path
                d="M37.7086826,106.530612 C15.8107234,107.566326 13.6027223,88.4204441 20.0173244,77.2712546 C26.2316101,66.4702341 35.5530388,62.7712544 50.6285038,64.1900464 C65.7039688,65.6088385 50.5636857,90.169341 40.2209306,88.3681933 C35.5286355,87.5510498 39.6004401,106.441137 37.7086826,106.530612 Z"
                id="left-arm"
                stroke="#000000"
                strokeWidth="2.5"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <path
                d="M60.7410433,9.46938776 C68.2263324,9.46938776 73.0871763,10.6723916 77.3062878,12.9313812 C81.5253989,15.1903709 84.8365819,18.5053464 87.0929873,22.7292899 C89.3251303,26.9078146 90.5250571,31.7150649 90.5510204,39.072678 L90.5510204,43.5436364 C90.5510204,51.0374983 89.349393,55.9039096 87.0929873,60.1278531 C84.8365819,64.3517963 81.5253989,67.6667718 77.3062878,69.9257617 C73.0871763,72.1847514 68.2263324,73.3877551 60.7410433,73.3877551 L55.2589567,73.3877551 C47.7736676,73.3877551 42.9128237,72.1847514 38.6937122,69.9257617 C34.4746011,67.6667718 31.1634181,64.3517963 28.9070125,60.1278531 C26.6506072,55.9039096 25.4489796,51.0374983 25.4489796,43.5436364 L25.4489796,39.3135064 C25.4489796,31.8196446 26.6506072,26.9532333 28.9070125,22.7292899 C31.1634181,18.5053464 34.4746011,15.1903709 38.6937122,12.9313812 C42.8674567,10.6966818 47.6692075,9.4953809 55.0184035,9.46938776 L60.7410433,9.46938776 Z"
                id="helmet"
                stroke="#000000"
                strokeWidth="2.5"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <path
                d="M54.0368739,27.8163265 L61.9631261,27.8163265 C65.9787611,27.8163265 69.0898725,28.5567899 71.7010112,29.9472255 C74.3121499,31.3376607 76.361387,33.3780684 77.7578394,35.9779566 C79.1542915,38.5778448 79.8979592,41.6755514 79.8979592,45.6738844 L79.8979592,46.6526462 C79.8979592,50.6509792 79.1542915,53.7486858 77.7578394,56.348574 C76.361387,58.9484622 74.3121499,60.9888699 71.7010112,62.3793051 C69.0898725,63.7697406 65.9787611,64.5102041 61.9631261,64.5102041 L54.0368739,64.5102041 C50.0212389,64.5102041 46.9101275,63.7697406 44.2989888,62.3793051 C41.6878501,60.9888699 39.638613,58.9484622 38.2421606,56.348574 C36.8457085,53.7486858 36.1020408,50.6509792 36.1020408,46.6526462 L36.1020408,45.6738844 C36.1020408,41.6755514 36.8457085,38.5778448 38.2421606,35.9779566 C39.638613,33.3780684 41.6878501,31.3376607 44.2989888,29.9472255 C46.9101275,28.5567899 50.0212389,27.8163265 54.0368739,27.8163265 Z"
                id="screen"
                stroke="#000000"
                strokeWidth="2.5"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
              <circle
                id="left-eye"
                fill="#000000"
                fillRule="nonzero"
                cx="44.0918367"
                cy="46.4591837"
                r="4.73469388"
              ></circle>
              <circle
                id="right-eye"
                fill="#000000"
                fillRule="nonzero"
                cx="71.9081633"
                cy="46.4591837"
                r="4.73469388"
              ></circle>
              <path
                d="M52.9693878,45.5714286 C52.9693878,48.1863278 55.2216695,50.3061224 58,50.3061224 C60.7783305,50.3061224 63.0306122,48.1863278 63.0306122,45.5714286"
                id="month"
                stroke="#000000"
                strokeWidth="2.5"
                strokeLinecap="round"
              ></path>
              <g id="left-ear" transform="translate(16.867347, 27.816327)">
                <path
                  d="M8.50342442,0.0863375259 C9.91857046,0.286175668 11.1461997,0.798351267 12.1319865,1.5504265 C13.1177733,2.30250175 13.8617176,3.29447664 14.309494,4.45391321 C14.7572701,5.61334978 14.9088781,6.94024834 14.7099923,8.36217071 L14.5288878,9.65696686 C14.3300019,11.0788892 13.8202671,12.3123968 13.071776,13.302904 C12.3232849,14.2934111 11.3360374,15.0409178 10.1821262,15.4908383 C9.02821495,15.9407585 7.70764003,16.0930925 6.29249399,15.8932544 C4.87734794,15.6934163 3.64971867,15.1812405 2.66393187,14.4291654 C1.67814507,13.6770901 0.93420071,12.6851152 0.486424508,11.5256786 C0.0386483057,10.366242 -0.112959744,9.0393435 0.0859260681,7.61742112 L0.267030596,6.32262498 C0.465916407,4.9007026 0.975651203,3.667195 1.72414235,2.67668787 C2.4726335,1.6861807 3.459881,0.93867404 4.6137922,0.488753688 C5.76770343,0.0388333657 7.08827838,-0.113500645 8.50342442,0.0863375259 Z"
                  id="ear"
                  stroke="#000000"
                  strokeWidth="2.5"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                ></path>
                <line
                  x1="6.37952543"
                  y1="5.0274423"
                  x2="6.31931734"
                  y2="9.34663542"
                  id="Path-3"
                  stroke="#D8D8D8"
                  strokeWidth="3.25510204"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  transform="translate(6.349421, 7.187039) rotate(8.000000) translate(-6.349421, -7.187039) "
                ></line>
              </g>
              <g id="right-ear" transform="translate(84.336735, 27.816327)">
                <path
                  d="M6.2618911,0.0841025481 C4.85094235,0.278035669 3.62831666,0.773308529 2.64727023,1.50080271 C1.6662238,2.22829697 0.926756924,3.18801232 0.482125803,4.31083077 C0.0374943827,5.43364922 -0.112301284,6.71957077 0.0859944004,8.09947658 L0.33833548,9.85547156 C0.536631465,11.2353777 1.04304614,12.4311036 1.78690638,13.3905656 C2.53076662,14.3500274 3.51207212,15.073225 4.66015005,15.5080737 C5.80822797,15.9429225 7.12307803,16.0894225 8.53402708,15.8954892 C9.94497612,15.7015561 11.1676018,15.2062832 12.1486482,14.4787892 C13.1296944,13.7512949 13.8691612,12.7915795 14.3137927,11.6687611 C14.7584241,10.5459426 14.9082198,9.26002107 14.7099238,7.88011526 L14.457583,6.12412028 C14.259287,4.74421417 13.7528723,3.54848824 13.0090121,2.5890262 C12.2651519,1.62956451 11.2838461,0.906367009 10.1357681,0.471518224 C8.9876902,0.0366694379 7.67284015,-0.109830602 6.2618911,0.0841025481 Z"
                  id="Rectangle"
                  stroke="#000000"
                  strokeWidth="2.5"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                ></path>
                <line
                  x1="8.46951223"
                  y1="5.02744227"
                  x2="8.40930409"
                  y2="9.34663539"
                  id="Path-3"
                  stroke="#D8D8D8"
                  strokeWidth="3.25510204"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  transform="translate(8.439408, 7.187039) scale(-1, 1) rotate(8.000000) translate(-8.439408, -7.187039) "
                ></line>
              </g>
              <g
                id="logo"
                transform="translate(32.255102, 58.591837)"
                fillRule="nonzero"
              >
                <path
                  d="M25.744898,0 C11.5257474,0 0,11.526868 0,25.744898 C0,39.962928 11.5268702,51.4897959 25.744898,51.4897959 C39.9629259,51.4897959 51.4897959,39.962928 51.4897959,25.744898 C51.4897959,11.526868 39.9646053,0 25.744898,0 Z"
                  id="Path"
                  fill="#07A6B3"
                ></path>
                <path
                  d="M25.744898,0 C11.5257474,0 0,11.526868 0,25.744898 C0,39.962928 11.5268702,51.4897959 25.744898,51.4897959 C39.9629259,51.4897959 51.4897959,39.962928 51.4897959,25.744898 C51.4897959,11.526868 39.9646053,0 25.744898,0 Z"
                  id="Path"
                  stroke="#000000"
                  strokeWidth="2.5"
                ></path>
                <path
                  d="M10.5747318,19.933241 L17.5712648,22.9090435 C17.54866,23.0066523 17.5198385,23.1347351 17.4876233,23.278616 C17.2782827,23.9600935 17.155078,24.6650236 17.1208711,25.3770231 C17.1057594,25.5181127 17.1004708,25.6600752 17.1050536,25.8018938 L17.1112704,25.8995026 L17.1112704,25.8995026 C17.1426831,28.5013969 18.3546872,30.9485523 20.4064807,32.5528721 C22.4582839,34.1571827 25.1282665,34.7453775 27.6656853,34.1520604 L31.6428772,40.8952791 C29.7702781,41.6410616 27.77227,42.0229311 25.7562013,42.0203956 C16.987505,42.0203956 9.46938776,34.9228115 9.46938776,26.1652602 C9.46938776,24.1091733 9.8609994,21.7359824 10.5747318,19.933241 Z M25.7578933,9.46940335 C34.5265898,9.46940335 42.0204082,16.6984625 42.0204082,25.4537595 C42.0188219,27.6680417 41.7505206,29.8773667 40.9528869,31.8041756 L40.7853878,32.1843878 L23.0256268,24.6711529 L34.4175299,24.6215044 C34.0083913,20.4957866 29.7616442,17.1419515 25.7567718,17.1419515 C24.8485591,17.1411745 23.9465535,17.2914341 23.087785,17.5865722 L19.1462021,10.9048581 C21.2206294,9.95587716 23.4761075,9.46620246 25.7578933,9.46940335 Z"
                  id="Combined-Shape"
                  fill="#FFFFFF"
                ></path>
              </g>
              <g
                id="hand"
                transform="translate(37.383915, 95.943703) rotate(-1.000000) translate(-37.383915, -95.943703) translate(27.883915, 84.943703)"
                fill="#FFFFFF"
                fillRule="nonzero"
                stroke="#000000"
                strokeWidth="2.5"
              >
                <path
                  d="M2.49642257,16.0564812 L0.670687359,10.6382004 C-0.719178706,6.51345957 1.54691405,2.05927254 5.73214719,0.689495241 L5.82626625,0.659328139 L5.82626625,0.659328139 C10.0961729,-0.680484311 14.6654895,1.61250088 16.0777701,5.80376189 L17.9035053,11.2220427 C19.2933714,15.3467835 17.0272786,19.8009706 12.8420455,21.1707479 L12.7479264,21.200915 L12.7479264,21.200915 C8.47801977,22.5407274 3.90870315,20.2477422 2.49642257,16.0564812 Z"
                  id="Rectangle-Copy-3"
                ></path>
                <line
                  x1="12.1092443"
                  y1="9.54323997"
                  x2="12.1513308"
                  y2="3.32969571"
                  id="Line-Copy-2"
                  strokeLinecap="round"
                  transform="translate(12.130288, 6.436468) rotate(-108.000000) translate(-12.130288, -6.436468) "
                ></line>
                <line
                  x1="14.307877"
                  y1="14.6120534"
                  x2="14.2684898"
                  y2="8.39789246"
                  id="Line-Copy-3"
                  strokeLinecap="round"
                  transform="translate(14.288183, 11.504973) rotate(-108.000000) translate(-14.288183, -11.504973) "
                ></line>
              </g>
              <g
                id="hand"
                transform="translate(78.680251, 95.943703) scale(-1, 1) rotate(-1.000000) translate(-78.680251, -95.943703) translate(69.180251, 84.943703)"
                fill="#FFFFFF"
                fillRule="nonzero"
                stroke="#000000"
                strokeWidth="2.5"
              >
                <path
                  d="M2.49642257,16.0564812 L0.670687359,10.6382004 C-0.719178706,6.51345957 1.54691405,2.05927254 5.73214719,0.689495241 L5.82626625,0.659328139 L5.82626625,0.659328139 C10.0961729,-0.680484311 14.6654895,1.61250088 16.0777701,5.80376189 L17.9035053,11.2220427 C19.2933714,15.3467835 17.0272786,19.8009706 12.8420455,21.1707479 L12.7479264,21.200915 L12.7479264,21.200915 C8.47801977,22.5407274 3.90870315,20.2477422 2.49642257,16.0564812 Z"
                  id="Rectangle-Copy-3"
                ></path>
                <line
                  x1="12.1092443"
                  y1="9.54323997"
                  x2="12.1513308"
                  y2="3.32969571"
                  id="Line-Copy-2"
                  strokeLinecap="round"
                  transform="translate(12.130288, 6.436468) rotate(-108.000000) translate(-12.130288, -6.436468) "
                ></line>
                <line
                  x1="14.307877"
                  y1="14.6120534"
                  x2="14.2684898"
                  y2="8.39789246"
                  id="Line-Copy-3"
                  strokeLinecap="round"
                  transform="translate(14.288183, 11.504973) rotate(-108.000000) translate(-14.288183, -11.504973) "
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MewWallet;
