import { FC, HTMLAttributes } from 'react';

type RequirementErc20Props = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const RequirementErc20: FC<RequirementErc20Props> = ({
  size = 25,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.4375 6.45801C0.4375 3.1443 3.12379 0.458008 6.4375 0.458008H18.4375C21.7512 0.458008 24.4375 3.1443 24.4375 6.45801V18.458C24.4375 21.7717 21.7512 24.458 18.4375 24.458H6.4375C3.12379 24.458 0.4375 21.7717 0.4375 18.458V6.45801Z"
        fill="white"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.125 20.5353C16.5433 20.5353 20.125 16.9536 20.125 12.5353C20.125 8.117 16.5433 4.53528 12.125 4.53528C7.70672 4.53528 4.125 8.117 4.125 12.5353C4.125 16.9536 7.70672 20.5353 12.125 20.5353ZM9.0726 11.9203C8.7909 12.2817 8.79088 12.7883 9.07254 13.1497L11.3357 16.0539C11.7361 16.5676 12.5128 16.5676 12.9132 16.054L15.1774 13.1491C15.4591 12.7877 15.4591 12.2811 15.1775 11.9197L12.9143 9.0155C12.5139 8.50178 11.7372 8.50174 11.3368 9.01543L9.0726 11.9203Z"
        fill="#B4B4B7"
      />
    </svg>
  );
};

export default RequirementErc20;
