type ChevronDownProps = React.SVGProps<SVGSVGElement> & {
  size?: number;
  color?: string;
};

const ChevronDown: React.FC<ChevronDownProps> = ({
  size = 23,
  color = '#8E8E92',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.01165 8.1253C5.36963 7.76732 5.95003 7.76732 6.30801 8.1253L11.1598 12.9771L16.0116 8.1253C16.3696 7.76732 16.95 7.76732 17.308 8.1253C17.666 8.48328 17.666 9.06368 17.308 9.42166L11.808 14.9217C11.45 15.2796 10.8696 15.2796 10.5116 14.9217L5.01165 9.42166C4.65367 9.06368 4.65367 8.48328 5.01165 8.1253Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronDown;
