'use client';

import { FC, HTMLAttributes, useMemo } from 'react';
import { TooltipProvider } from '~/components/ui/tooltip';
import { useToast } from '~/components/ui/use-toast';
import { cn } from '~/lib/utils';
import { useSupabaseBucket } from '~/shared/utils/useSupabase';

import TableHeader from './TableHeader';
import TableCell from './components/TableCell';
import TableDefaultEmptyState from './components/TableDefaultEmptyState';
import TableDefaultLoadingState from './components/TableDefaultLoadingState';

export type Column<T = any> = {
  key: string | string[]; // Key to access the value from the row, array for column spanning
  label: string;
  /**
   * Render the cell
   * @param value Value of the cell
   * @returns JSX.Element
   */
  cellRender?: (
    // eslint-disable-next-line no-unused-vars
    value: any,
    // eslint-disable-next-line no-unused-vars
    props: { row: T; key: string },
  ) => React.JSX.Element;
  /**
   * Render the header
   * @param label Label of the header
   * @returns JSX.Element
   */
  // eslint-disable-next-line no-unused-vars
  headerRender?: (label: string) => React.JSX.Element;
  /**
   * Tooltip for the header
   */
  tooltip?: React.ReactNode;
};

export type TableProps<T = Record<string, any>> =
  HTMLAttributes<HTMLTableElement> & {
    data: T[];
    columns: Column<T>[];
    isLoading?: boolean;
    isLoadingTemplate?: React.ReactNode;
    emptyTemplate?: React.ReactNode;
    classNames?: {
      table?: string;
      td?: string;
      tr?: string;
      tbody?: string;
      header?: string;
      th?: string;
    };
    headerStyles?: string;
    stickyHeader?: boolean;
    passthrough?: {
      tr?: Omit<React.HTMLAttributes<HTMLTableRowElement>, 'onClick'> & {
        onClick?: (
          event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
          row: T,
        ) => void;
      };
    };
    showTooltip?: boolean;
  };

const Table: FC<TableProps> = ({
  data,
  columns,
  isLoading = false,
  isLoadingTemplate = <TableDefaultLoadingState />,
  emptyTemplate = <TableDefaultEmptyState />,
  className,
  classNames,
  stickyHeader,
  passthrough,
  showTooltip = false,
  ...props
}) => {
  const supabase = useSupabaseBucket();
  const { toast } = useToast();

  const headers = useMemo(
    () =>
      columns.map((column) => ({
        label: column.label,
        render: column.headerRender,
        tooltip: column.tooltip,
        size: Array.isArray(column.key) ? column.key.length : 1,
      })),
    [columns],
  );

  const handleDownload = async (filePath: string, downloadName: string) => {
    const { data, error } = await supabase.storage
      .from('files')
      .download(filePath);

    if (error) {
      console.error('Error downloading file:', error.message);
      return;
    }

    if (data) {
      const url = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = downloadName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      toast({
        title: 'Success',
        description: 'File downloaded successfully',
      });
    }
  };

  return (
    <TooltipProvider delayDuration={0}>
      <table
        {...props}
        className={cn(
          'w-full',
          stickyHeader && 'relative',
          className,
          classNames?.table,
        )}
      >
        <TableHeader
          headers={headers}
          className={classNames?.header}
          sticky={stickyHeader}
          th={classNames?.th}
        />
        <tbody className={cn('relative', classNames?.tbody)}>
          {data.map((row, index) => (
            <tr
              key={index}
              className={cn(
                classNames?.tr,
                row.__DEFAULT_ROWS__ && 'bg-gray-200 text-gray-900',
              )}
              {...passthrough?.tr}
              onClick={(e) => {
                passthrough?.tr?.onClick?.(e, row);
              }}
            >
              {columns.flatMap(({ cellRender, key }, columnIndex) => {
                if (Array.isArray(key)) {
                  return key.map((k, subIndex) => {
                    const isFirst = columnIndex === 0 && subIndex === 0;
                    const isLast =
                      columnIndex === columns.length - 1 &&
                      subIndex === key.length - 1;
                    return (
                      <TableCell
                        key={k}
                        rowKey={k}
                        row={row}
                        cellRender={cellRender}
                        showTooltip={showTooltip}
                        classNames={{
                          ...classNames,
                          td: cn(
                            classNames?.td,
                            isFirst && 'border-l',
                            isLast && 'border-r',
                          ),
                        }}
                        onClickDownload={handleDownload}
                      />
                    );
                  });
                }

                const isFirst = columnIndex === 0;
                const isLast = columnIndex === columns.length - 1;

                return (
                  <TableCell
                    key={key}
                    rowKey={key}
                    row={row}
                    cellRender={cellRender}
                    showTooltip={showTooltip}
                    classNames={{
                      ...classNames,
                      td: cn(
                        classNames?.td,
                        isFirst && 'border-l',
                        isLast && 'border-r',
                      ),
                    }}
                    onClickDownload={handleDownload}
                  />
                );
              })}
            </tr>
          ))}
          {isLoading && (
            <tr>
              <td
                className={cn(
                  'absolute top-0 flex w-full items-center rounded-[10px] bg-white/40',
                  data.length === 0 ? 'h-40' : 'h-full',
                )}
              >
                {isLoadingTemplate}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* Placeholder for loading empty table */}
      {data.length === 0 && isLoading ? <div className="h-40"></div> : null}
      {/* Placeholder for empty table */}
      {data.length === 0 && !isLoading ? emptyTemplate : null}
    </TooltipProvider>
  );
};

export default Table;
