'use client';

import * as React from 'react';

import {
  DropdownMenuContent,
  DropdownMenuCore,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../dropdown-menu';
import cn from '../lib/cn';
import {
  BreadcrumbCore,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from './breadcrumb.core';

export type BreadcrumbProps = {
  classnames?: {
    breadcrumb?: string;
    breadcrumbList?: string;
    breadcrumbItem?: string;
    breadcrumbLink?: string;
    breadcrumbPage?: string;
    breadcrumbSeparator?: string;
    breadcrumbEllipsis?: string;
  };
  links: Array<{
    href?: string;
    label: string;
    disabled?: boolean;
    ellipsis?: boolean;
  }>;
};

function Breadcrumb({ classnames, links }: BreadcrumbProps) {
  if (links.length < 2) return 'Please add at least 2 links';
  // Remove last ellipsis
  if (links?.[links.length - 1]?.ellipsis) {
    links[links.length - 1]!.ellipsis = false;
  }

  const renderListItems = () => {
    const result: React.ReactNode[] = [];
    // let ellipsis: typeof links = [];
    let startEllipsis: number | null = null;
    let endEllipsis: number | null = null;

    links.forEach((link, index) => {
      // Add last page
      if (index === links.length - 1) {
        return result.push(
          <BreadcrumbItem
            className={classnames?.breadcrumbItem}
            key={'breadcrumb-page-' + index}
          >
            <BreadcrumbPage className={classnames?.breadcrumbPage}>
              {link.label}
            </BreadcrumbPage>
          </BreadcrumbItem>,
        );
      }

      // Set ellipsis range
      if (link.ellipsis) {
        if (startEllipsis === null) startEllipsis = index;
        endEllipsis = index;

        // Add ellipsis dropdown menu if next link is not ellipsis
        if (!links[index + 1]?.ellipsis) {
          const ellipsis = links.slice(startEllipsis, endEllipsis + 1);
          result.push(
            <BreadcrumbItem
              className={classnames?.breadcrumbItem}
              key={'breadcrumb-ellipsis-' + index}
            >
              <DropdownMenuCore>
                <DropdownMenuTrigger className="flex items-center gap-1">
                  <BreadcrumbEllipsis
                    className={cn(
                      'h-4 w-4 text-content-secondary',
                      classnames?.breadcrumbEllipsis,
                    )}
                  />
                  <span className="sr-only">Toggle menu</span>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start" className="bg-white">
                  {ellipsis.map((item, ellipsisIndex) => (
                    <DropdownMenuItem
                      key={'breadcrumb-ellipsis-item-' + ellipsisIndex}
                    >
                      <BreadcrumbLink
                        className={classnames?.breadcrumbLink}
                        href={item.href}
                      >
                        {item.label}
                      </BreadcrumbLink>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenuCore>
            </BreadcrumbItem>,
            <BreadcrumbSeparator
              className={classnames?.breadcrumbSeparator}
              key={'breadcrumb-separator-' + index}
            />,
          );
          startEllipsis = null;
          endEllipsis = null;
          return;
        }
        return;
      }

      // Append link and separator
      return result.push(
        <BreadcrumbItem
          className={classnames?.breadcrumbItem}
          key={'breadcrumb-link-' + index}
        >
          <BreadcrumbLink
            className={classnames?.breadcrumbLink}
            href={link.href}
          >
            {link.label}
          </BreadcrumbLink>
        </BreadcrumbItem>,
        <BreadcrumbSeparator
          className={classnames?.breadcrumbSeparator}
          key={'breadcrumb-separator-' + index}
        />,
      );
    });

    return result;
  };

  return (
    <BreadcrumbCore className={classnames?.breadcrumb}>
      <BreadcrumbList className={classnames?.breadcrumbList}>
        {renderListItems()}
      </BreadcrumbList>
    </BreadcrumbCore>
  );
}

Breadcrumb.displayName = 'Breadcrumb';

export default Breadcrumb;
