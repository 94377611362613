import { useEffect, useState } from 'react';
import { MIN_WIDTH_SCREEN } from '~/constants/common';

const useOptimalWidth = () => {
  const [isOptimalWidth, setIsOptimalWidth] = useState(() =>
    typeof window !== 'undefined'
      ? window.innerWidth >= MIN_WIDTH_SCREEN
      : false,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsOptimalWidth(window.innerWidth >= MIN_WIDTH_SCREEN);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  return isOptimalWidth;
};

export default useOptimalWidth;
