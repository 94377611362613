import { FC, HTMLAttributes } from 'react';

type DownloadProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const Download: FC<DownloadProps> = ({
  size = 21,
  color = '#47474C',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <path
        d="M17.7969 12.9028V16.2362C17.7969 16.6782 17.6213 17.1021 17.3087 17.4147C16.9962 17.7272 16.5722 17.9028 16.1302 17.9028H4.46354C4.02151 17.9028 3.59759 17.7272 3.28503 17.4147C2.97247 17.1021 2.79688 16.6782 2.79688 16.2362V12.9028"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.12891 8.73608L10.2956 12.9028L14.4622 8.73608"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2969 12.9028V2.90283"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Download;
