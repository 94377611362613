import { User } from '@formo/types';
import { z } from 'zod';

export const isEmailExistWithProviderRequest = z.object({
  body: z.object({
    email: z.string().email(),
    provider: z.string().min(1, 'Provider is required'),
  }),
});
export type IsEmailExistWithProviderRequest = z.infer<
  typeof isEmailExistWithProviderRequest
>;
export type IsEmailExistWithProviderResponse = {
  exists: boolean;
};

export const getMeRequest = z.object({});
export type GetMeRequest = z.infer<typeof getMeRequest>;
export type GetMeResponse = User;

export const saveUserPreferencesRequest = z.object({
  body: z.object({
    preferences: z.record(
      z.string(),
      z.union([z.string(), z.array(z.string())]),
    ),
  }),
});
export type SaveUserPreferencesRequest = z.infer<
  typeof saveUserPreferencesRequest
>;
export type SaveUserPreferencesResponse = any;
