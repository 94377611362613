import React from 'react';

import cn from '../lib/cn';
import {
  TableBody,
  TableCaption,
  TableCell,
  TableCore,
  TableFoot,
  TableHeader,
  TableHeaderCell,
  TableRoot,
  TableRow,
} from './table.core';

// FULL CUSTOM TABLE COMPONENT
type Column<T = any, K extends keyof T = keyof T> = {
  title?: string;
  width?: string | number;
  align?: 'left' | 'center' | 'right';
  key?: number | string | K;
  dataIndex?: K;
  render?: (data: T[K], record: T, index: number) => React.ReactNode;
  onCellClick?: (
    data: T[K],
    record: T,
    index: number,
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
  ) => void;
};

export type TableProps<T = any> = {
  columns: Column<T>[];
  dataSource?: T[];
  footer?: React.ReactNode;
  classNames?: {
    root?: string;
    table?: string;
    caption?: string;
    header?: string;
    headerRow?: string;
    headerCell?: string;
    body?: string;
    bodyRow?: string;
    bodyCell?: string;
    footer?: string;
  };
  events?: {
    onRowClick?: (
      data: T,
      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    ) => void;
  };
  caption?: React.ReactNode;
  indexOffset?: number;
  hasBorder?: boolean;
  showIndex?: boolean;
  isLoading?: boolean;
};

const TableComponent = <T,>(
  {
    columns,
    dataSource = [],
    footer,
    classNames,
    caption,
    indexOffset = 0,
    hasBorder = false,
    showIndex = false,
    isLoading = false,
    events = {},
  }: TableProps<T>,
  forwardedRef: React.Ref<HTMLElement>,
) => {
  // Header columns
  const renderColumnHeads = () => {
    const headCells = columns.map((column, index) => (
      <TableHeaderCell
        key={'th-' + index}
        className={cn(classNames?.headerCell)}
        style={{
          // Cell width
          width: column.width ? column.width + 'px' : 'auto',
        }}
        hasBorder={hasBorder}
        scope="col"
      >
        {column.title}
      </TableHeaderCell>
    ));

    return showIndex
      ? [
          <TableHeaderCell
            key="th-index"
            className={cn('w-auto', classNames?.headerCell)}
            hasBorder={hasBorder}
            scope="col"
          >
            #
          </TableHeaderCell>,
          ...headCells,
        ]
      : headCells;
  };

  // Body cells
  const renderTableCell = (rowData: any, index: number) => {
    const parsedRowIndex = indexOffset + index;

    const bodyCells = columns.map((colConfig, colIndex) => {
      const renderKey = colConfig.dataIndex || colConfig.key;
      const align = colConfig.align || 'left';

      let renderData: React.ReactNode = '';
      if (renderKey && renderKey in rowData) {
        renderData = colConfig.render
          ? colConfig.render(rowData[renderKey], rowData, index)
          : rowData[renderKey];
      }

      return (
        <TableCell
          key={'td-' + index + '-' + colIndex}
          className={cn(
            'text-' + align,
            colConfig.onCellClick && 'cursor-pointer',
            classNames?.bodyCell,
          )}
          style={{
            // Cell width
            width: colConfig.width ? colConfig.width + 'px' : 'auto',
          }}
          hasBorder={hasBorder}
          onClick={(event) =>
            colConfig.onCellClick &&
            colConfig.onCellClick(rowData[renderKey], rowData, index, event)
          }
        >
          {renderData}
        </TableCell>
      );
    });

    return showIndex
      ? [
          <TableCell
            key={'td-index-' + parsedRowIndex}
            className={cn(classNames?.bodyCell)}
            hasBorder={hasBorder}
          >
            {parsedRowIndex + 1}
          </TableCell>,
          ...bodyCells,
        ]
      : bodyCells;
  };

  // Body rows
  const renderTableRows = () => {
    return dataSource.map((row, index) => {
      return (
        <TableRow
          hasBorder={hasBorder}
          className={cn(
            'hover:bg-surface-hover data-[state=selected]:bg-surface-hover',
            events.onRowClick && 'cursor-pointer',
            classNames?.bodyRow,
          )}
          key={'row-' + index}
          onClick={(event) =>
            events.onRowClick && events.onRowClick(row, event)
          }
        >
          {renderTableCell(row, index)}
        </TableRow>
      );
    });
  };

  const renderByState = () => {
    if (isLoading) {
      return (
        <tr className="h-80 bg-white relative">
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <TableLoadingIndicator />
          </div>
        </tr>
      );
    }

    if (!dataSource.length) {
      return (
        <tr className="h-80 bg-white relative">
          <div className="absolute inset-0 flex items-center justify-center">
            <p className="b2 font-medium">No data to show</p>
          </div>
        </tr>
      );
    }

    return renderTableRows();
  };

  return (
    <TableRoot className={cn(classNames?.root)} ref={forwardedRef as any}>
      <TableCore className={cn(classNames?.table)}>
        {caption && (
          <TableCaption className={cn(classNames?.caption)}>
            {caption}
          </TableCaption>
        )}
        <TableHeader className={cn(classNames?.header)}>
          <TableRow className={cn(classNames?.headerRow)}>
            {renderColumnHeads()}
          </TableRow>
        </TableHeader>
        <TableBody className={cn(classNames?.body)}>
          {renderByState()}
        </TableBody>
        {footer && (
          <TableFoot className={cn(classNames?.footer)}>{footer}</TableFoot>
        )}
      </TableCore>
    </TableRoot>
  );
};

const TableLoadingIndicator = () => {
  return (
    <div
      className={cn(
        'aspect-square animate-spin rounded-full border border-neutral-300 border-b-neutral-500 ease-in-out',
      )}
      style={{ width: 40, borderWidth: 4 }}
    />
  );
};

const Table = React.forwardRef(TableComponent) as unknown as <T>(
  props: TableProps<T> & { ref?: React.Ref<HTMLElement> },
) => ReturnType<typeof TableComponent>;

export default Table;
