import { FC, HTMLAttributes } from 'react';

type WarningScreenProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const WarningScreen: FC<WarningScreenProps> = ({ size = 218, ...props }) => {
  return (
    <svg
      width={size}
      height={(157 * size) / 218}
      viewBox="0 0 218 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M119.679 130.291H70.5605L66.1211 153.554H123.902L119.679 130.291Z"
        fill="url(#paint0_linear_8066_16354)"
      />
      <path
        d="M130.918 154.811L123.903 153.551H66.1219L59.1063 154.811C58.8895 154.848 58.7324 155.038 58.7324 155.259V156.668C58.7324 156.851 58.8801 157.001 59.0623 157.001H130.956C131.138 157.001 131.286 156.851 131.286 156.668V155.259C131.286 155.038 131.129 154.851 130.912 154.811H130.918Z"
        fill="#C6C6C8"
      />
      <path
        d="M184.442 0H5.55783C2.4883 0 0 2.70952 0 6.05366V112.207H190V6.05366C190 2.70952 187.512 0 184.442 0ZM181.508 104.231H7.94874V8.62942H181.508V104.231Z"
        fill="#858585"
      />
      <path
        d="M70.5741 130.223L70.5615 130.291H119.68L119.668 130.223H70.5741Z"
        fill="#F6F6F6"
      />
      <path
        d="M70.5741 130.223L70.5615 130.291H119.68L119.668 130.223H70.5741Z"
        fill="url(#paint1_linear_8066_16354)"
      />
      <path d="M190 112.207H0V121.518H190V112.207Z" fill="black" />
      <path
        d="M0 112.207V124.233C0 127.578 2.4883 130.287 5.55783 130.287H70.5616L70.5741 130.219H119.668L119.68 130.287H184.442C187.512 130.287 190 127.578 190 124.233V112.207H0Z"
        fill="url(#paint2_linear_8066_16354)"
      />
      <path
        d="M181.507 8.62891H7.94824V104.231H181.507V8.62891Z"
        fill="#F3F4F6"
      />
      <path
        d="M90.3963 73H205.823C206.565 73 207.168 73.6011 207.168 74.3416V153.084H89.0508V74.3416C89.0508 73.6011 89.6536 73 90.3963 73Z"
        fill="#EAEEF1"
      />
      <path
        d="M90.7784 73.4795H205.378C206.115 73.4795 206.711 74.0744 206.711 74.8087V152.798H89.4453V74.8118C89.4453 74.0775 90.042 73.4826 90.7784 73.4826V73.4795Z"
        fill="#858585"
      />
      <path
        d="M206.733 152.82H89.4238V74.8118C89.4238 74.0651 90.0329 73.4609 90.7787 73.4609H205.378C206.127 73.4609 206.733 74.0682 206.733 74.8118V152.82ZM89.4673 152.78H206.69V74.8118C206.69 74.0899 206.099 73.5012 205.375 73.5012H90.7787C90.0547 73.5012 89.4642 74.0899 89.4642 74.8118V152.78H89.4673Z"
        fill="#858585"
      />
      <path
        d="M206.711 148.412V76.4115C206.711 74.7911 205.415 73.4805 203.815 73.4805H92.3415C90.7412 73.4805 89.4453 74.7942 89.4453 76.4115V148.412C89.4453 149.84 90.592 150.999 92.0028 150.999H204.154C205.568 150.999 206.711 149.84 206.711 148.412Z"
        fill="#858585"
      />
      <path
        d="M204.154 151.024H92.0031C90.5798 151.024 89.4238 149.853 89.4238 148.415V76.4117C89.4238 74.7851 90.7321 73.459 92.3418 73.459H203.815C205.425 73.459 206.733 74.7851 206.733 76.4117V148.412C206.733 149.85 205.577 151.021 204.154 151.021V151.024ZM92.3418 73.5024C90.757 73.5024 89.4642 74.8068 89.4642 76.4148V148.415C89.4642 149.831 90.6016 150.984 92 150.984H204.154C205.552 150.984 206.69 149.831 206.69 148.415V76.4117C206.69 74.8068 205.4 73.4993 203.812 73.4993H92.3418V73.5024Z"
        fill="#858585"
      />
      <path
        d="M217.817 152.799H78.1461C78.0653 152.799 78 152.864 78 152.948V153.821C78 154.543 78.4413 155.191 79.1094 155.442L81.1945 156.232C82.3972 156.687 83.6744 156.923 84.9578 156.923H211.36C212.538 156.923 213.703 156.703 214.8 156.272L217.345 155.275C217.74 155.12 217.998 154.739 217.998 154.311V153.205C217.998 152.79 218.035 152.805 217.814 152.796L217.817 152.799Z"
        fill="#E7EDF1"
      />
      <path
        d="M202.165 78.7168H94.4609V148.737H202.165V78.7168Z"
        fill="#F3F4F6"
      />
      <path
        d="M78.6494 155.178C78.7861 155.286 78.9415 155.376 79.1093 155.441L81.1945 156.231C82.3971 156.687 83.6743 156.922 84.9577 156.922H211.36C212.538 156.922 213.703 156.702 214.8 156.271L217.345 155.274C217.41 155.249 217.469 155.215 217.528 155.178H78.6494Z"
        fill="#CECFD3"
      />
      <path
        d="M78.7178 155.205C78.8514 155.31 79.0006 155.397 79.1653 155.462C79.1684 155.462 81.2131 156.24 81.2318 156.243C82.4251 156.692 83.6898 156.925 84.9608 156.925H211.36C212.519 156.925 213.672 156.708 214.753 156.286C214.772 156.283 217.264 155.307 217.267 155.307C217.333 155.283 217.388 155.248 217.444 155.211L78.7178 155.205Z"
        fill="#CDCED2"
      />
      <path
        d="M78.7861 155.234C78.9166 155.336 79.0627 155.423 79.2243 155.485C79.2336 155.485 81.238 156.247 81.2722 156.253C82.453 156.696 83.7116 156.928 84.9639 156.928H211.36C212.5 156.928 213.638 156.715 214.707 156.299C214.741 156.293 217.183 155.339 217.186 155.336C217.249 155.311 217.305 155.28 217.361 155.243L78.7861 155.23V155.234Z"
        fill="#CCCDD1"
      />
      <path
        d="M78.8516 155.261C78.9759 155.36 79.1219 155.444 79.2773 155.503C79.2897 155.506 81.2537 156.249 81.3034 156.262C82.4749 156.699 83.7242 156.928 84.9579 156.928H211.36C212.485 156.928 213.607 156.717 214.66 156.311C214.713 156.299 217.103 155.369 217.109 155.366C217.171 155.341 217.224 155.31 217.277 155.276L78.8547 155.258L78.8516 155.261Z"
        fill="#CBCCD0"
      />
      <path
        d="M78.9199 155.29C79.0411 155.389 79.181 155.47 79.3363 155.526C79.3519 155.532 81.2754 156.257 81.3438 156.272C82.5029 156.7 83.7428 156.932 84.961 156.932H211.36C212.466 156.932 213.573 156.725 214.614 156.328C214.685 156.312 217.022 155.402 217.028 155.402C217.087 155.38 217.14 155.349 217.193 155.315L78.923 155.293L78.9199 155.29Z"
        fill="#CBCCCF"
      />
      <path
        d="M78.9854 155.317C79.1034 155.413 79.2402 155.491 79.3893 155.547C79.4111 155.553 81.2911 156.262 81.3782 156.281C82.5279 156.702 83.7585 156.932 84.9611 156.932H211.36C212.448 156.932 213.542 156.727 214.564 156.34C214.651 156.321 216.935 155.432 216.944 155.429C217.003 155.407 217.053 155.376 217.106 155.345L78.9854 155.317Z"
        fill="#CACBCE"
      />
      <path
        d="M79.0537 155.346C79.1687 155.439 79.3023 155.513 79.4484 155.569C79.4732 155.575 81.3129 156.269 81.4154 156.294C82.5528 156.709 83.7772 156.935 84.9611 156.935H211.36C212.429 156.935 213.511 156.734 214.517 156.353C214.623 156.331 216.854 155.464 216.867 155.461C216.923 155.439 216.972 155.411 217.022 155.377L79.0537 155.343V155.346Z"
        fill="#C9CACD"
      />
      <path
        d="M79.1191 155.373C79.231 155.463 79.3584 155.537 79.5014 155.59C79.5293 155.599 81.3317 156.275 81.4498 156.303C82.5747 156.712 83.7898 156.938 84.9582 156.938H211.36C212.414 156.938 213.477 156.739 214.471 156.368C214.595 156.34 216.774 155.497 216.786 155.494C216.842 155.472 216.889 155.444 216.938 155.413L79.1222 155.373H79.1191Z"
        fill="#C8C9CC"
      />
      <path
        d="M79.1875 155.402C79.2963 155.488 79.4206 155.56 79.5604 155.612C79.5946 155.622 81.3535 156.281 81.4871 156.312C82.6027 156.712 83.8084 156.938 84.9613 156.938H211.36C212.395 156.938 213.445 156.743 214.424 156.381C214.564 156.35 216.693 155.529 216.708 155.522C216.761 155.501 216.808 155.476 216.854 155.445L79.1906 155.398L79.1875 155.402Z"
        fill="#C7C8CB"
      />
      <path
        d="M79.252 155.43C79.3576 155.513 79.4788 155.582 79.6124 155.634C79.6497 155.647 81.3682 156.288 81.5205 156.325C82.6236 156.719 83.8231 156.945 84.9574 156.945H211.36C212.376 156.945 213.411 156.753 214.374 156.396C214.532 156.362 216.605 155.563 216.624 155.557C216.673 155.538 216.72 155.51 216.767 155.482L79.2551 155.43H79.252Z"
        fill="#C6C7CA"
      />
      <path
        d="M79.3203 155.457C79.4198 155.538 79.5378 155.606 79.6715 155.655C79.7119 155.668 81.3899 156.294 81.5608 156.334C82.6547 156.721 83.8449 156.944 84.9605 156.944H211.36C212.357 156.944 213.379 156.755 214.327 156.408C214.504 156.371 216.524 155.593 216.546 155.587C216.596 155.569 216.642 155.544 216.683 155.516L79.3234 155.46L79.3203 155.457Z"
        fill="#C6C6C9"
      />
      <path
        d="M79.3887 155.484C79.485 155.562 79.6 155.627 79.7274 155.676C79.774 155.689 81.4086 156.299 81.595 156.346C82.6764 156.727 83.8573 156.95 84.9605 156.95H211.36C212.341 156.95 213.345 156.764 214.281 156.426C214.473 156.386 216.443 155.627 216.465 155.621C216.512 155.602 216.558 155.577 216.599 155.549L79.3918 155.487L79.3887 155.484Z"
        fill="#C5C5C8"
      />
      <path
        d="M79.4551 155.513C79.5483 155.587 79.6602 155.649 79.7845 155.695C79.8342 155.711 81.4284 156.303 81.6335 156.352C82.7056 156.727 83.8771 156.947 84.9616 156.947H211.361C212.324 156.947 213.315 156.764 214.235 156.436C214.446 156.39 216.364 155.655 216.386 155.649C216.432 155.63 216.476 155.609 216.513 155.581L79.4551 155.513Z"
        fill="#C4C5C7"
      />
      <path
        d="M79.5225 155.541C79.6126 155.612 79.7182 155.674 79.8394 155.718C79.8923 155.733 81.4491 156.31 81.6698 156.362C82.7294 156.728 83.8948 156.948 84.9606 156.948H211.36C212.304 156.948 213.28 156.768 214.184 156.446C214.414 156.396 216.279 155.684 216.304 155.678C216.347 155.659 216.391 155.637 216.428 155.612L79.5225 155.538V155.541Z"
        fill="#C3C4C6"
      />
      <path
        d="M79.5879 155.569C79.6749 155.638 79.7775 155.696 79.8955 155.74C79.9515 155.758 81.4679 156.316 81.7041 156.375C82.7545 156.734 83.9074 156.954 84.9577 156.954H211.36C212.289 156.954 213.249 156.778 214.138 156.465C214.383 156.412 216.195 155.721 216.223 155.712C216.267 155.696 216.307 155.675 216.341 155.65L79.5879 155.569Z"
        fill="#C2C3C5"
      />
      <path
        d="M79.6562 155.597C79.7402 155.662 79.8396 155.718 79.9515 155.761C80.0136 155.779 81.4866 156.322 81.7414 156.384C82.7793 156.737 83.926 156.954 84.9608 156.954H211.36C212.27 156.954 213.215 156.78 214.091 156.477C214.356 156.421 216.114 155.752 216.145 155.742C216.186 155.727 216.226 155.705 216.26 155.683L79.6594 155.597H79.6562Z"
        fill="#C1C2C4"
      />
      <path
        d="M79.7217 155.621C79.7994 155.683 79.8957 155.739 80.0076 155.779C80.0728 155.798 81.5054 156.325 81.7789 156.39C82.8044 156.737 83.9448 156.954 84.961 156.954H211.36C212.252 156.954 213.184 156.783 214.045 156.486C214.328 156.427 216.034 155.779 216.065 155.767C216.105 155.751 216.143 155.733 216.174 155.708L79.7248 155.618L79.7217 155.621Z"
        fill="#C1C1C3"
      />
      <path
        d="M79.79 155.649C79.8646 155.708 79.9578 155.761 80.0635 155.801C80.1319 155.823 81.524 156.331 81.813 156.402C82.8292 156.74 83.9572 156.957 84.9579 156.957H211.36C212.233 156.957 213.15 156.79 213.998 156.501C214.297 156.436 215.953 155.811 215.984 155.801C216.021 155.786 216.059 155.767 216.09 155.745L79.7931 155.649H79.79Z"
        fill="#C0C0C2"
      />
      <path
        d="M79.8555 155.677C79.9269 155.736 80.0171 155.785 80.1196 155.822C80.1942 155.844 81.5429 156.337 81.8505 156.411C82.8542 156.743 83.9761 156.959 84.9612 156.959H211.36C212.218 156.959 213.119 156.795 213.949 156.516C214.266 156.448 215.869 155.844 215.903 155.835C215.941 155.819 215.975 155.801 216.003 155.782L79.8555 155.68V155.677Z"
        fill="#BFBFC1"
      />
      <path
        d="M79.9238 155.705C79.9922 155.761 80.0761 155.807 80.1755 155.844C80.2532 155.869 81.5646 156.343 81.8878 156.424C82.8822 156.749 83.9947 156.963 84.9611 156.963H211.36C212.199 156.963 213.088 156.802 213.902 156.532C214.238 156.461 215.785 155.879 215.823 155.866C215.857 155.854 215.891 155.835 215.919 155.817L79.9238 155.708V155.705Z"
        fill="#BEBFC1"
      />
      <path
        d="M79.9922 155.732C80.0574 155.785 80.1382 155.828 80.2346 155.866C80.3154 155.89 81.5864 156.349 81.9251 156.433C82.9071 156.752 84.0133 156.962 84.9611 156.962H211.36C212.181 156.962 213.054 156.804 213.856 156.541C214.207 156.467 215.705 155.906 215.745 155.894C215.779 155.881 215.81 155.863 215.835 155.847L79.9922 155.732Z"
        fill="#BDBEC0"
      />
      <path
        d="M80.0576 155.761C80.1198 155.81 80.1975 155.854 80.2876 155.885C80.3746 155.91 81.6021 156.353 81.9594 156.439C82.9321 156.749 84.0259 156.963 84.9582 156.963H211.36C212.165 156.963 213.023 156.808 213.809 156.554C214.179 156.474 215.624 155.938 215.664 155.925C215.695 155.913 215.726 155.897 215.751 155.879L80.0607 155.758L80.0576 155.761Z"
        fill="#BCBDBF"
      />
      <path
        d="M80.125 155.788C80.1809 155.835 80.2555 155.875 80.3456 155.906C80.4358 155.934 81.6228 156.358 81.9988 156.451C82.9591 156.755 84.0467 156.966 84.9634 156.966H211.359C212.146 156.966 212.988 156.814 213.758 156.569C214.147 156.485 215.539 155.971 215.583 155.956C215.614 155.943 215.642 155.928 215.663 155.912L80.125 155.785V155.788Z"
        fill="#BBBCBE"
      />
      <path
        d="M80.1904 155.816C80.2433 155.86 80.3147 155.897 80.3986 155.928C80.4919 155.956 81.6385 156.365 82.0301 156.461C82.981 156.758 84.0593 156.969 84.9574 156.969H211.36C212.127 156.969 212.957 156.823 213.712 156.582C214.116 156.495 215.455 155.999 215.502 155.987C215.53 155.974 215.558 155.962 215.58 155.947L80.1935 155.816H80.1904Z"
        fill="#BBBBBD"
      />
      <path
        d="M80.2588 155.844C80.3085 155.884 80.3769 155.921 80.4577 155.949C80.5571 155.98 81.6603 156.37 82.0705 156.47C83.009 156.761 84.078 156.968 84.9605 156.968H211.36C212.108 156.968 212.923 156.826 213.665 156.594C214.088 156.504 215.374 156.03 215.421 156.017C215.449 156.008 215.474 155.992 215.493 155.98L80.2588 155.844Z"
        fill="#BABABC"
      />
      <path
        d="M80.3242 155.871C80.3708 155.908 80.433 155.942 80.5107 155.97C80.6132 156.001 81.6791 156.376 82.1017 156.481C83.0278 156.763 84.0906 156.971 84.9576 156.971H211.36C212.093 156.971 212.892 156.832 213.619 156.609C214.06 156.516 215.294 156.063 215.344 156.048C215.368 156.038 215.393 156.026 215.412 156.014L80.3273 155.871H80.3242Z"
        fill="#B9B9BB"
      />
      <path
        d="M80.3937 155.899C80.4372 155.933 80.4963 155.964 80.5709 155.992C80.6765 156.023 81.702 156.383 82.1433 156.491C83.06 156.767 84.1135 156.975 84.9618 156.975H211.361C212.076 156.975 212.859 156.838 213.57 156.624C214.027 156.525 215.211 156.098 215.261 156.082C215.286 156.073 215.307 156.061 215.323 156.051L80.3906 155.903L80.3937 155.899Z"
        fill="#B8B8BA"
      />
      <path
        d="M80.4609 155.928C80.5013 155.959 80.5573 155.99 80.6256 156.015C80.7375 156.049 81.7195 156.39 82.1794 156.501C83.0837 156.771 84.1278 156.975 84.9606 156.975H211.36C212.056 156.975 212.826 156.842 213.523 156.634C213.998 156.532 215.129 156.126 215.182 156.111C215.204 156.101 215.225 156.092 215.241 156.08L80.4609 155.925V155.928Z"
        fill="#B7B8B9"
      />
      <path
        d="M80.5264 155.955C80.5637 155.983 80.6134 156.011 80.6817 156.036C80.7967 156.07 81.7383 156.395 82.2138 156.513C83.1087 156.776 84.1435 156.981 84.9608 156.981H211.36C212.037 156.981 212.792 156.851 213.476 156.652C213.97 156.547 215.045 156.163 215.101 156.144C215.123 156.135 215.142 156.125 215.157 156.116L80.5295 155.955H80.5264Z"
        fill="#B6B7B8"
      />
      <path
        d="M80.5947 155.983C80.6258 156.008 80.6755 156.033 80.7377 156.058C80.8558 156.095 81.7569 156.402 82.251 156.523C83.1336 156.777 84.1622 156.981 84.9608 156.981H211.36C212.022 156.981 212.761 156.854 213.429 156.665C213.939 156.557 214.965 156.191 215.021 156.175C215.039 156.169 215.058 156.16 215.07 156.151L80.5947 155.986V155.983Z"
        fill="#B6B6B7"
      />
      <path
        d="M80.6602 156.012C80.6881 156.033 80.7347 156.058 80.7938 156.077C80.9181 156.114 81.7758 156.405 82.2885 156.532C83.1617 156.78 84.181 156.985 84.961 156.985H211.36C212.003 156.985 212.73 156.861 213.38 156.678C213.908 156.566 214.881 156.222 214.94 156.207C214.959 156.201 214.974 156.191 214.987 156.185L80.6633 156.015L80.6602 156.012Z"
        fill="#B5B5B6"
      />
      <path
        d="M80.7285 156.039C80.7534 156.058 80.7938 156.079 80.8497 156.098C80.9771 156.135 81.7975 156.411 82.3227 156.541C83.1835 156.783 84.1934 156.984 84.9579 156.984H211.36C211.985 156.984 212.696 156.863 213.333 156.69C213.88 156.572 214.8 156.253 214.859 156.234C214.875 156.228 214.89 156.222 214.9 156.216L80.7285 156.039Z"
        fill="#B4B4B5"
      />
      <path
        d="M80.7939 156.068C80.8157 156.086 80.853 156.102 80.9058 156.12C81.0363 156.161 81.8163 156.418 82.3601 156.551C83.2116 156.786 84.2122 156.985 84.9612 156.985H211.36C211.969 156.985 212.665 156.867 213.287 156.703C213.849 156.582 214.719 156.284 214.782 156.266C214.797 156.26 214.81 156.253 214.819 156.247L80.7971 156.064L80.7939 156.068Z"
        fill="#B3B3B4"
      />
      <path
        d="M80.8623 156.095C80.8809 156.11 80.9151 156.126 80.9617 156.141C81.0985 156.181 81.835 156.423 82.3974 156.563C83.2365 156.789 84.2309 156.99 84.9611 156.99H211.36C211.951 156.99 212.631 156.876 213.24 156.721C213.821 156.597 214.636 156.321 214.701 156.299C214.713 156.293 214.726 156.29 214.732 156.284L80.8623 156.095Z"
        fill="#B2B2B3"
      />
      <path
        d="M80.9277 156.123C80.9277 156.123 80.9712 156.148 81.0179 156.163C81.1577 156.207 81.8538 156.43 82.4318 156.572C83.2584 156.792 84.2435 156.991 84.9582 156.991H211.36C211.932 156.991 212.6 156.879 213.194 156.73C213.794 156.603 214.555 156.349 214.623 156.328C214.636 156.325 214.645 156.318 214.651 156.315L80.9339 156.12L80.9277 156.123Z"
        fill="#B1B1B2"
      />
      <path
        d="M80.9961 156.15C80.9961 156.15 81.0334 156.172 81.0738 156.184C81.2167 156.228 81.8724 156.435 82.4691 156.581C83.2863 156.795 84.2621 156.993 84.9613 156.993H211.36C211.913 156.993 212.566 156.885 213.144 156.745C213.762 156.612 214.471 156.383 214.539 156.361C214.549 156.358 214.558 156.355 214.561 156.352L80.9961 156.153V156.15Z"
        fill="#B1B1B1"
      />
      <path
        d="M81.0645 156.179C81.0645 156.179 81.0955 156.194 81.1328 156.207C81.282 156.25 81.8942 156.442 82.5095 156.594C83.3143 156.801 84.2839 156.997 84.9644 156.997H211.36C211.898 156.997 212.535 156.891 213.097 156.761C213.731 156.625 214.39 156.414 214.459 156.393C214.468 156.393 214.474 156.386 214.477 156.386L81.0645 156.182V156.179Z"
        fill="#B0B0B0"
      />
      <path
        d="M81.1289 156.207C81.1289 156.207 81.1507 156.216 81.1848 156.229C81.3371 156.275 81.912 156.449 82.5397 156.604C83.3353 156.802 84.2924 157 84.9574 157H211.36C211.878 157 212.5 156.898 213.05 156.774C213.703 156.635 214.305 156.446 214.38 156.424C214.386 156.424 214.393 156.421 214.393 156.418L81.132 156.207H81.1289Z"
        fill="#AFAFAF"
      />
      <path
        d="M81.1973 156.231C81.1973 156.231 83.6771 156.997 84.9605 156.997H211.36C212.537 156.997 214.309 156.448 214.309 156.448L81.2004 156.231H81.1973Z"
        fill="#AEAEAE"
      />
      <path
        d="M148.001 77.2578C148.641 77.2578 149.16 76.732 149.16 76.0835C149.16 75.4349 148.641 74.9092 148.001 74.9092C147.361 74.9092 146.842 75.4349 146.842 76.0835C146.842 76.732 147.361 77.2578 148.001 77.2578Z"
        stroke="#858585"
        strokeWidth="0.265898"
        strokeMiterlimit="10"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8066_16354"
          x1="128.483"
          y1="124.9"
          x2="67.7431"
          y2="153.042"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3F3F4" />
          <stop offset="0.759985" stopColor="#C6C6C8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8066_16354"
          x1="-1.73356e-05"
          y1="130.257"
          x2="190"
          y2="130.257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABABAF" />
          <stop offset="0.04" stopColor="#B0B0B3" />
          <stop offset="0.31" stopColor="#D2D2D4" />
          <stop offset="0.57" stopColor="#EAEAEB" />
          <stop offset="0.81" stopColor="#F9F9FA" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8066_16354"
          x1="0"
          y1="121.25"
          x2="190"
          y2="121.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABABAF" />
          <stop offset="0.04" stopColor="#B0B0B3" />
          <stop offset="0.31" stopColor="#D2D2D4" />
          <stop offset="0.57" stopColor="#EAEAEB" />
          <stop offset="0.81" stopColor="#F9F9FA" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WarningScreen;
