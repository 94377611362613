'use client';

import React from 'react';
import { WarningScreen } from '~/components/icons';

function ScreenWidthMessage({ minWidth }: { minWidth: number }) {
  return (
    <div className="flex flex-col h-screen items-center justify-center mx-auto">
      <WarningScreen />
      <p className="p-4 text-center text-base font-normal text-gray-800 w-full break-words">
        This application works best on screens wider than {minWidth}px.
      </p>
    </div>
  );
}

export default ScreenWidthMessage;
