import { FC, HTMLAttributes } from 'react';

type TalismanWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const TalismanWallet: FC<TalismanWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path fill="#D5FF5C" d="M0 0h28v28H0z" />
      <path
        fill="#FD4848"
        fillRule="evenodd"
        d="M20.186 15.25c.208.453.82.613 1.17.261l.646-.645a1.708 1.708 0 0 1 2.415 2.414l-5.215 5.215a6.816 6.816 0 0 1-5.225 2.432A6.817 6.817 0 0 1 8.594 22.3l-5.02-5.02a1.707 1.707 0 0 1 2.414-2.415l.635.635c.344.345.942.19 1.145-.253a.663.663 0 0 0 .062-.277v-7.8a1.707 1.707 0 0 1 3.415 0v3.946c0 .34.348.57.671.468a.497.497 0 0 0 .354-.467V4.78a1.707 1.707 0 1 1 3.414 0v6.338c0 .216.148.402.353.466.323.103.671-.128.671-.467V7.17a1.707 1.707 0 0 1 3.415 0v7.797c0 .098.022.194.063.283Z"
        clipRule="evenodd"
      />
      <path
        fill="#D5FF5C"
        d="M19.44 18.78s-2.446 3.415-5.463 3.415-5.463-3.415-5.463-3.415 2.446-3.414 5.463-3.414 5.463 3.415 5.463 3.415Z"
      />
      <path
        stroke="#FD4848"
        strokeWidth="1.003"
        d="M16.537 18.78a2.561 2.561 0 1 1-5.12 0 2.56 2.56 0 0 1 5.12 0Z"
      />
      <path
        stroke="#FD4848"
        strokeWidth="1.003"
        d="M15.513 18.78a1.538 1.538 0 0 1-2.63 1.102 1.537 1.537 0 0 1-.442-1.102 1.536 1.536 0 0 1 3.072 0Z"
      />
      <path
        stroke="#FD4848"
        strokeWidth="1.003"
        d="M17.562 18.78a3.585 3.585 0 1 1-7.17 0 3.585 3.585 0 0 1 7.17 0Z"
      />
      <path
        stroke="#FD4848"
        strokeWidth="1.003"
        d="M18.586 18.78a4.609 4.609 0 1 1-9.218 0 4.609 4.609 0 0 1 9.218 0Z"
      />
      <path
        fill="#FD4848"
        stroke="#FD4848"
        strokeWidth="1.003"
        d="M14.489 18.78a.51.51 0 0 1-.512.526.511.511 0 1 1 .512-.526Z"
      />
      <path
        stroke="#D5FF5C"
        strokeWidth="1.003"
        d="M8.76 18.822a7.77 7.77 0 0 1-.032-.041l.032-.042c.44-.557.93-1.071 1.466-1.536.978-.845 2.305-1.666 3.751-1.666 1.447 0 2.773.821 3.752 1.666.535.465 1.026.98 1.466 1.536l.032.041a11.328 11.328 0 0 1-1.498 1.578c-.979.845-2.305 1.666-3.752 1.666-1.446 0-2.773-.821-3.75-1.666a11.333 11.333 0 0 1-1.467-1.536Z"
      />
    </svg>
  );
};

export default TalismanWallet;
