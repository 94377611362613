'use client';

import { GetFormsOfTeamResponse } from '@formo/shared';
import { Check, Pencil, RefreshCw, X } from 'lucide-react';
import Link from 'next/link';
import { FC, useRef, useState } from 'react';
import { VerticalEllipsis } from '~/app/_components/common';
import { Column, Table } from '~/app/_components/tables';
import FormTableProvider from '~/app/context/FormTableContext';
import useClickOutside from '~/app/hooks/useClickOutside';
import useDashboard from '~/app/hooks/useDashboard';
import useFormTable from '~/app/hooks/useFormTable';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/ui/popover';
import { useToast } from '~/components/ui/use-toast';
import { TEAM_ACTIONS } from '~/constants/permission';
import {
  FORM_STATUS,
  FORM_STATUS_CLASSNAMES,
  FORM_STATUS_MAPPER,
} from '~/constants/status';
import { cn } from '~/lib/utils';
import dayjs from '~/utils/dayjs';

import { CreateFormButton } from '../../dashboard/CreateFormButton';
import ActionPopover, {
  PopoverArrow,
} from '../../team/components/ActionPopover';

const label = (header: string) => (
  <div className="px-3 text-center text-sm font-medium text-gray-700">
    {header}
  </div>
);

type Row = GetFormsOfTeamResponse[number];

const FormTitle: FC<Row> = ({ title, id, status }) => {
  const { currentTeamId } = useDashboard();
  const [isRenaming, setIsRenaming] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const divRef = useRef<HTMLDivElement>(null);

  const { toast } = useToast();

  const { onUpdate, isUpdating } = useFormTable();

  const onCancel = () => {
    setNewTitle(title);
    setIsRenaming(false);
  };

  const onRename = async () => {
    if (isUpdating) return;
    const trimmedTitle = newTitle.trim();
    if (!trimmedTitle || trimmedTitle === title) return onCancel();
    try {
      await onUpdate(id, {
        title: trimmedTitle,
        teamId: currentTeamId,
        formId: id,
      });
      setIsRenaming(false);
    } catch (error: any) {
      toast({
        title: 'Failed to rename form',
        description: error.message,
      });
    }
  };

  useClickOutside(divRef, () => {
    if (!isRenaming || isUpdating) return;
    onCancel();
  });

  const { permissions } = useDashboard();

  if (!permissions.includes(TEAM_ACTIONS.CREATE_UPDATE_FORM)) {
    return (
      <div className="group flex w-full items-center gap-1">
        <span className="line-clamp-1 block whitespace-normal font-medium text-black">
          {title}
        </span>
      </div>
    );
  }

  if (isRenaming) {
    return (
      <div
        ref={divRef}
        className="flex items-center gap-2"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <input
          value={newTitle}
          onChange={(e) => {
            setNewTitle(e.target.value);
          }}
          className="w-[200px] border-x-0 border-t-transparent p-0 font-medium text-black"
          disabled={isUpdating}
        />
        <button
          disabled={isUpdating}
          onClick={onRename}
          className="disabled:opacity-50"
        >
          {isUpdating ? (
            <RefreshCw size={16} className="animate-spin" />
          ) : (
            <Check size={16} />
          )}
        </button>
        <button
          disabled={isUpdating}
          onClick={onCancel}
          className="disabled:opacity-50"
        >
          <X size={16} />
        </button>
      </div>
    );
  }

  return (
    <div className="flex w-full items-center gap-1">
      {/* Title is till clickable */}
      <div className="line-clamp-1 block whitespace-normal text-base font-medium text-black group-hover:underline">
        {title}
      </div>
      {/* Block editing form name from dashboard when form is being archived */}
      {!(status === 3) && (
        <Pencil
          size={16}
          className="cursor-pointer opacity-0 transition-opacity group-hover:opacity-100"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsRenaming(true);
          }}
        />
      )}
    </div>
  );
};

const DEFAULT_AVATARS = Array.from({ length: 3 }).map(
  (_, i) => `/images/avatar_${i}.webp`,
);

const baseColumns: Column[] = [
  {
    key: 'title',
    label: 'FORMS',
    headerRender: (header) => (
      <div className="text-left text-sm font-medium text-gray-700">
        {header}
      </div>
    ),
    cellRender: (title: string, { row }: { row: Row }) => {
      return (
        <Link
          href={`/${row.id}/edit`}
          className="flex w-full cursor-pointer gap-4 text-left"
        >
          <img
            className="aspect-square w-12 rounded-lg"
            src={DEFAULT_AVATARS[row.id.charCodeAt(0) % DEFAULT_AVATARS.length]}
            alt="thumbnail"
          />
          <div className="flex w-full flex-col justify-center gap">
            <FormTitle {...row} />
            <div className="text-sm font-medium text-gray-700/80">
              Created by: {row.creator.name}
            </div>
          </div>
        </Link>
      );
    },
  },
  {
    key: '_count.responses',
    label: 'Responses',
    headerRender: label,
    cellRender: (responses: number | string) => {
      return (
        <span
          className={cn(
            'text-sm font-medium text-black',
            responses == 0 && 'text-gray-600',
          )}
        >
          {responses}
        </span>
      );
    },
  },
  {
    key: 'status',
    label: 'Status',
    headerRender: label,
    cellRender: (status: number) => {
      return (
        <div className="flex justify-center">
          <div
            className={cn(
              'w-max rounded-full bg-gray-50 px-3 py-1 text-sm font-medium text-black',
              FORM_STATUS_CLASSNAMES[status],
            )}
          >
            {FORM_STATUS_MAPPER[status] ?? 'N/A'}
          </div>
        </div>
      );
    },
  },
  {
    key: 'updatedAt',
    label: 'Updated',
    headerRender: label,
    cellRender: (date: string) => {
      return (
        <span className="text-sm text-gray-600">
          {dayjs(date).format('DD MMM YYYY')}
        </span>
      );
    },
  },
];

const FormTableRender = () => {
  const { isLoadingTeams, permissions, isMember } = useDashboard();
  const {
    activeTab,
    setActiveTab,
    tabs,
    forms,
    isFetching,
    onArchive,
    isArchiving,
    onUnarchive,
    isDeleting,
    onDuplicate,
    onDelete,
    isDuplicating,
    popover,
    setPopover,
    onCreate,
  } = useFormTable();

  const { toast } = useToast();
  // Too many changes, not worth memorizing
  const columns: Column[] = [
    ...baseColumns,
    {
      key: 'actions',
      label: '',
      headerRender: label,
      cellRender: (_, { row }: { row: Row }) => {
        if (isMember) return <></>;

        return (
          <Popover
            open={!!popover && popover.id === row.id}
            onOpenChange={(isOpen) => {
              if (!isOpen) setPopover(null);
            }}
            modal
          >
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <PopoverTrigger
                  className="h-8"
                  onClick={(e) => e.stopPropagation()}
                >
                  <VerticalEllipsis size={16} />
                </PopoverTrigger>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" alignOffset={-4} className="p-0">
                <DropdownMenuItem
                  className="cursor-pointer rounded-none px-[14px] py-2.5"
                  disabled={row.status !== FORM_STATUS.PUBLISHED}
                  onClick={async () => {
                    try {
                      const url =
                        row.slug || `${window.location.origin}/${row.id}`;
                      await navigator.clipboard.writeText(url);
                      toast({
                        title: 'Copied!',
                        description: 'Link has been copied to clipboard',
                      });
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                >
                  Copy share link
                </DropdownMenuItem>
                {permissions.includes(TEAM_ACTIONS.CREATE_UPDATE_FORM) && (
                  <>
                    <DropdownMenuItem
                      className="cursor-pointer rounded-none px-[14px] py-2.5"
                      onClick={() => onDuplicate(row)}
                      disabled={isDuplicating}
                    >
                      Duplicate
                    </DropdownMenuItem>
                  </>
                )}
                {permissions.includes(TEAM_ACTIONS.ARCHIVE_FORMS) &&
                  (row.status === FORM_STATUS.ARCHIVED ? (
                    <DropdownMenuItem
                      onClick={() => onUnarchive(row)}
                      disabled={isArchiving}
                      className="cursor-pointer rounded-none px-[14px] py-2.5"
                    >
                      Unarchive
                    </DropdownMenuItem>
                  ) : (
                    <DropdownMenuItem
                      onClick={() => onArchive(row)}
                      disabled={isArchiving}
                      className="cursor-pointer rounded-none px-[14px] py-2.5"
                    >
                      Archive
                    </DropdownMenuItem>
                  ))}
                {permissions.includes(TEAM_ACTIONS.DELETE_FORMS) && (
                  <>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      disabled={isDeleting}
                      className="cursor-pointer rounded-none px-[14px] py-2.5"
                      onClick={() => setPopover({ id: row.id, type: 'delete' })}
                      variant="destructive"
                    >
                      Delete
                    </DropdownMenuItem>
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
            <PopoverContent
              align="end"
              sideOffset={12}
              alignOffset={-4}
              className="max-w-[300px] border-1 border-black/5 p-3"
            >
              <PopoverArrow open={!!popover && popover.id === row.id} />
              <ActionPopover
                title="Delete form?"
                description="You'll lose all responses. We can't recover them once you delete."
                variant="destructive"
                confirmText="Delete"
                onConfirm={() => {
                  onDelete(row);
                  setPopover(null);
                }}
                onCancel={() => setPopover(null)}
                cancelText="Cancel"
              />
            </PopoverContent>
          </Popover>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex items-center">
        <div className="flex h-12">
          {tabs.map((tab) => (
            <Link
              key={tab}
              href={`#${tab}`}
              className={cn(
                'flex items-center justify-center border-b-2 border-b-transparent px-2.5 py-[27px] font-medium text-gray-600',
                tab === activeTab && 'border-b-black font-bold text-black',
              )}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </Link>
          ))}
        </div>
      </div>
      {forms.length === 0 ? (
        <div className="mt-20 flex h-full flex-col items-center">
          <div className="relative aspect-square w-[150px] rounded-full [background:_radial-gradient(82.36%_82.36%_at_50%_0%,_rgba(217,_217,_217,_0.6)_0%,_rgba(217,_217,_217,_0.5)_16.54%,_rgba(217,_217,_217,_0.4)_38.78%,_rgba(217,_217,_217,_0.3)_64.79%,_rgba(217,_217,_217,_0)_100%)]">
            {activeTab === 'Active' ? (
              <>
                <img
                  src="/icons/folder.svg"
                  className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                />
                <img
                  src="/icons/ghost.svg"
                  className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-[calc(50%-6px)]"
                />
              </>
            ) : (
              <img
                src="/illustrations/empty-archived.svg"
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
              />
            )}
          </div>
          <div className="-mt-2 text-center text-base font-medium text-gray-700">
            {activeTab === 'Archived' ? (
              <>Looks like there's nothing here</>
            ) : (
              <>Looks like you don't have a form yet</>
            )}
          </div>
          {activeTab === 'Active' && (
            <>
              <CreateFormButton
                className="mt-8"
                plusIconSize={20}
                classNames={{ plusIconClassName: 'mr-1.5' }}
                onCreate={onCreate}
                isDashboardTable
              />
            </>
          )}
        </div>
      ) : (
        <div className={cn('mt-5 h-full max-h-full overflow-auto')}>
          <Table
            // stickyHeader
            classNames={{
              table:
                'w-full px-2 border-separate border-spacing-x-0 border-spacing-y-3',
              tr: 'bg-white rounded-lg shadow-sm hover:shadow-[1px_1px_4px_0px_rgba(0,0,0,0.1)]',
              td: 'border-t border-b text-center first:rounded-l-lg last:rounded-r-lg py-2 px-2',
            }}
            columns={columns}
            data={forms ?? []}
            isLoading={isLoadingTeams || isFetching || isDuplicating}
            emptyTemplate={null}
          />
        </div>
      )}
    </>
  );
};

export default function FormTable() {
  return (
    <FormTableProvider>
      <FormTableRender />
    </FormTableProvider>
  );
}
