import { FC, HTMLAttributes } from 'react';

type BybitWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const BybitWallet: FC<BybitWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      {...props}
    >
      <g clipPath="url(#a)">
        <path fill="#404347" d="M0 0h28v28H0z" />
        <path
          fill="url(#b)"
          d="M2.41 8.53a1.556 1.556 0 0 1 1.171-1.955l14.75-3.133a1.556 1.556 0 0 1 1.722.844l6.625 13.68L8.01 27.845 2.41 8.53Z"
        />
        <path
          fill="#fff"
          d="M2.603 9.597a1.556 1.556 0 0 1 1.295-1.975l19.548-2.535a1.556 1.556 0 0 1 1.726 1.24L28 20.612 7.856 27.767 2.603 9.597Z"
        />
        <path
          fill="#000"
          d="M0 10.889a3.111 3.111 0 0 1 3.111-3.111h18.667A6.222 6.222 0 0 1 28 14v14H0V10.889Z"
        />
        <path fill="#F7A600" d="M18.524 19.471v-5.829h1.138v5.83h-1.137Z" />
        <path
          fill="#fff"
          d="M5.55 21.211H3.11v-5.829h2.34c1.137 0 1.8.638 1.8 1.637 0 .647-.426 1.065-.72 1.204.352.164.802.532.802 1.31 0 1.089-.745 1.678-1.784 1.678m-.189-4.814H4.248v1.343H5.36c.483 0 .753-.27.753-.67 0-.403-.27-.673-.753-.673Zm.074 2.367H4.248v1.432h1.186c.515 0 .76-.328.76-.72.001-.394-.244-.712-.76-.712m5.032.056v2.391h-1.13v-2.39l-1.75-3.44h1.235l1.088 2.35 1.072-2.35h1.235l-1.75 3.44Zm4.986 2.391h-2.438v-5.829h2.34c1.137 0 1.8.638 1.8 1.637 0 .647-.426 1.065-.72 1.204.352.164.802.532.802 1.31-.001 1.089-.746 1.678-1.784 1.678Zm-.188-4.814H14.15v1.343h1.113c.482 0 .753-.27.753-.67-.001-.403-.27-.673-.753-.673Zm.073 2.367h-1.186v1.432h1.186c.516 0 .76-.328.76-.72 0-.394-.245-.712-.76-.712Zm8.03-2.367v4.814H22.23v-4.814h-1.521v-1.015h4.18v1.015h-1.522Z"
        />
      </g>
      <defs>
        <linearGradient
          id="b"
          x1="2.333"
          x2="26.93"
          y1="8.144"
          y2="6.912"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD748" />
          <stop offset="1" stopColor="#F7A600" />
        </linearGradient>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BybitWallet;
