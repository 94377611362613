import { FC } from 'react';

const OverviewPlaceholder: FC = ({ ...props }) => {
  return (
    <svg
      className="w-fit h-fit relative"
      viewBox="0 0 889 223"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M77.0603 69.4308C44.815 69.4308 35.4446 73.9563 1.35669 60.1121V223.004H887.643V1.21729C862.716 10.5361 840.559 10.5361 820.249 10.5361C799.938 10.5361 789.782 57.13 759.316 57.13C728.85 57.13 717.036 39.6107 700.231 39.6107C683.425 39.6107 689.378 58.9938 671.139 58.9938C653.002 58.9938 651.3 69.4308 618.064 69.4308C584.829 69.4308 582.783 84.3409 556.898 84.3409C499.778 84.3409 469.375 26.5644 443.579 26.5644C429.667 26.5644 430.652 49.675 389.975 49.675C349.298 49.675 352.548 87.3229 333.023 87.3229C312.094 87.3229 304.171 104.469 280.61 104.469C257.049 104.469 251.22 114.534 235.366 114.534C204.042 114.534 204.042 73.1584 164.426 73.1584C146.037 73.1584 148.148 57.13 120.758 57.13C93.3687 57.13 94.3378 69.4308 77.0603 69.4308Z"
        fill="url(#paint0_linear_613_72897)"
      />
      <path
        d="M1.35669 60.1121C35.4446 73.9563 44.815 69.4308 77.0603 69.4308C94.3378 69.4308 93.3687 57.13 120.758 57.13C148.148 57.13 146.037 73.1584 164.426 73.1584C204.042 73.1584 204.042 114.534 235.366 114.534C251.22 114.534 257.049 104.469 280.61 104.469C304.171 104.469 312.094 87.3229 333.023 87.3229C352.548 87.3229 349.298 49.675 389.975 49.675C430.652 49.675 429.667 26.5644 443.579 26.5644C469.375 26.5644 499.778 84.3409 556.898 84.3409C582.783 84.3409 584.829 69.4308 618.064 69.4308C651.3 69.4308 653.002 58.9938 671.139 58.9938C689.378 58.9938 683.425 39.6107 700.231 39.6107C717.036 39.6107 728.85 57.13 759.316 57.13C789.782 57.13 799.938 10.5361 820.249 10.5361C840.559 10.5361 862.716 10.5361 887.643 1.21729"
        stroke="#DCDCDD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_613_72897"
          x1="405.303"
          y1="62.3386"
          x2="428.554"
          y2="225.28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#47474C" stopOpacity="0.08" />
          <stop offset="0.581834" stopColor="#8E8E92" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default OverviewPlaceholder;
