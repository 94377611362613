import { FC, HTMLAttributes } from 'react';

type UniWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const UniWallet: FC<UniWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path fill="#FFD8EA" d="M0 0h28v28H0z" />
      <g fill="#FF007A" clipPath="url(#a)">
        <path d="M9.635 6.068c-.255-.039-.266-.043-.145-.06.23-.035.773.012 1.148.099.874.202 1.67.722 2.519 1.646l.225.246.323-.051c1.36-.213 2.741-.044 3.899.477.319.144.821.43.882.503.022.024.058.173.082.335.086.557.044.982-.13 1.301-.096.173-.101.228-.037.377a.367.367 0 0 0 .334.206c.287 0 .597-.454.74-1.086l.056-.252.113.126c.62.685 1.106 1.618 1.189 2.282l.021.174-.103-.158a1.966 1.966 0 0 0-.59-.604c-.415-.268-.855-.36-2.017-.42-1.05-.053-1.645-.141-2.234-.329-1.003-.32-1.508-.744-2.699-2.27-.53-.678-.855-1.053-1.18-1.354-.74-.687-1.466-1.047-2.396-1.187v-.001Z" />
        <path d="M18.725 7.581c.026-.453.088-.753.216-1.026.028-.07.063-.136.104-.198a.69.69 0 0 1-.049.178c-.092.266-.108.629-.043 1.052.081.536.126.613.706 1.192.273.272.589.613.704.761l.21.267-.21-.191c-.255-.233-.841-.69-.972-.755-.086-.042-.1-.042-.153.01-.05.048-.06.12-.068.461-.01.532-.084.873-.263 1.213-.096.187-.111.147-.025-.062.066-.155.072-.224.072-.739 0-1.033-.127-1.283-.864-1.707a8.169 8.169 0 0 0-.684-.346 2.614 2.614 0 0 1-.334-.16c.02-.02.74.185 1.029.293.431.163.502.183.555.164.034-.013.051-.113.068-.407h.001Zm-8.595 1.77c-.517-.696-.838-1.764-.768-2.563l.02-.247.119.02c.222.04.603.179.783.286.49.29.703.673.918 1.657.064.288.147.613.185.725.061.178.294.592.483.862.136.194.046.286-.255.26-.46-.04-1.083-.46-1.485-1Zm7.965 5.182c-2.42-.95-3.273-1.777-3.273-3.17 0-.206.007-.374.016-.374.008 0 .102.067.208.151.491.384 1.04.548 2.563.765.896.128 1.398.231 1.864.382 1.48.478 2.392 1.449 2.61 2.773.063.384.028 1.104-.076 1.484-.082.3-.329.84-.394.861-.019.007-.037-.061-.04-.154-.025-.496-.283-.98-.715-1.343-.49-.412-1.15-.741-2.763-1.375Zm-1.699.396a4.13 4.13 0 0 0-.117-.5l-.062-.18.115.127c.16.173.285.396.391.693.082.227.09.294.09.662 0 .361-.011.437-.087.64-.104.3-.28.572-.51.794-.443.44-1.011.683-1.833.784-.142.018-.557.047-.922.066-.922.046-1.529.144-2.073.33a.446.446 0 0 1-.156.036c-.021-.021.35-.237.656-.381.431-.204.861-.315 1.825-.47.475-.078.968-.173 1.093-.21 1.178-.352 1.784-1.263 1.59-2.39Z" />
        <path d="M17.506 16.855c-.321-.675-.396-1.327-.22-1.935.02-.067.05-.119.068-.119a.64.64 0 0 1 .169.09c.149.097.445.261 1.237.682.988.527 1.553.934 1.936 1.4.335.407.542.872.643 1.438.057.32.023 1.093-.061 1.416-.266 1.018-.882 1.818-1.764 2.285a2.129 2.129 0 0 1-.258.124c-.013 0 .035-.116.104-.259.297-.605.331-1.193.107-1.848-.138-.401-.418-.89-.982-1.718-.657-.96-.817-1.218-.979-1.556Zm-9.095 3.644c.9-.742 2.016-1.267 3.036-1.43a4.85 4.85 0 0 1 1.575.06c.652.163 1.235.528 1.538.962.296.425.423.794.556 1.618.052.324.109.651.125.724.1.427.293.768.532.939.38.272 1.037.29 1.682.044a.853.853 0 0 1 .21-.064c.023.023-.301.235-.53.347-.27.141-.572.213-.879.208-.59 0-1.08-.294-1.49-.891a7.027 7.027 0 0 1-.402-.784c-.431-.961-.644-1.253-1.145-1.575-.437-.28-1-.329-1.424-.126-.556.266-.71.96-.312 1.4.187.189.43.313.695.354a.75.75 0 0 0 .589-.174.728.728 0 0 0 .251-.551c0-.29-.114-.453-.4-.581-.392-.172-.812.029-.81.388 0 .153.069.249.226.32.1.044.103.046.02.03-.358-.073-.442-.496-.154-.776.347-.336 1.064-.188 1.31.272.103.194.115.578.025.81-.203.52-.79.793-1.387.644-.407-.102-.571-.211-1.06-.703-.852-.856-1.182-1.023-2.408-1.21l-.237-.035.268-.22Z" />
        <path
          fillRule="evenodd"
          d="M4.418 5.179c2.842 3.368 4.8 4.757 5.017 5.05.18.243.112.462-.195.632a1.899 1.899 0 0 1-.699.191c-.2 0-.269-.075-.269-.075-.115-.106-.18-.088-.773-1.116A176.911 176.911 0 0 0 5.968 7.56c-.044-.04-.042-.04 1.448 2.564.24.543.047.741.047.819 0 .157-.044.24-.242.457-.332.36-.48.765-.587 1.604-.12.94-.456 1.604-1.39 2.741-.545.666-.635.787-.773 1.056-.174.338-.221.527-.24.952a2.9 2.9 0 0 0 .16 1.174c.122.378.252.626.582 1.125.285.43.448.75.448.876 0 .099.019.099.461.001 1.054-.232 1.91-.64 2.392-1.142.298-.311.367-.482.37-.907.002-.277-.008-.336-.085-.496-.125-.26-.353-.477-.855-.813-.66-.44-.942-.794-1.018-1.28-.065-.4.01-.682.373-1.427.377-.773.47-1.103.535-1.88.04-.503.096-.701.244-.861.155-.166.293-.222.676-.272.623-.084 1.018-.24 1.343-.534a1.14 1.14 0 0 0 .421-.869l.014-.28-.16-.179C9.563 9.34 4.036 4.667 4 4.667c-.007 0 .18.23.418.512Zm1.325 13.086a.486.486 0 0 0-.157-.652c-.203-.133-.522-.069-.522.103 0 .053.03.092.098.125.114.058.122.122.032.254s-.082.248.02.328c.168.126.404.057.529-.158ZM10.683 12c-.29.086-.575.389-.664.706-.053.194-.022.534.058.638.13.169.255.213.596.212.665-.004 1.243-.283 1.311-.632.054-.286-.2-.68-.55-.855a1.33 1.33 0 0 0-.75-.07Zm.78.593c.102-.143.057-.296-.118-.4-.331-.199-.835-.035-.835.272 0 .153.262.32.502.32.16 0 .38-.093.45-.192Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M4 4h19v20H4z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UniWallet;
