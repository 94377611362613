'use client';

import { GetTeamMembersResponse, GetTeamsOfUserResponse } from '@formo/shared';
import { useQuery } from '@tanstack/react-query';
import { useParams, useRouter } from 'next/navigation';
import { FC, createContext, useEffect, useMemo, useState } from 'react';
import {
  ROLE_ORDER,
  TEAM_ACTIONS,
  TEAM_PERMISSIONS,
  TEAM_ROLES,
} from '~/constants/permission';
import { LAST_ACTIVE_PROJECT_KEY_NAME } from '~/constants/projects';
import client from '~/lib/client';
import { formoSessionStorage } from '~/utils/storage';

import { useAuth } from '../hooks/AuthProvider';

type RefetchFn = () => any | Promise<any>;

type DashboardContextType = {
  currentTeamId: string;
  currentProjectId: string | null;
  activeTeam: GetTeamsOfUserResponse[number] | null;
  teams: GetTeamsOfUserResponse;
  members: GetTeamMembersResponse;
  refetchTeams: RefetchFn;
  refetchMembers: RefetchFn;
  isLoadingTeams: boolean;
  isLoadingMembers: boolean;
  isAdmin: boolean;
  isOwner: boolean;
  isEditor: boolean;
  isMember: boolean;
  setCurrentProject: (projectId: string | null) => void;
  permissions: TEAM_ACTIONS[];
};

const noop = async () => {};

export const DashboardContext = createContext<DashboardContextType>({
  currentTeamId: '',
  currentProjectId: null,
  activeTeam: null,
  teams: [],
  members: [],
  refetchTeams: noop,
  refetchMembers: noop,
  setCurrentProject: noop,
  isLoadingTeams: true,
  isLoadingMembers: true,
  isAdmin: false,
  isOwner: false,
  isEditor: false,
  isMember: false,
  permissions: [],
});

type DashboardProviderProps = {
  children: React.ReactNode;
};

const DashboardProvider: FC<DashboardProviderProps> = ({ children }) => {
  const { teamId: teamIdParams } = useParams<{ teamId: string }>();
  const { user } = useAuth();
  const router = useRouter();
  const [currentProject, setCurrentProject] = useState<string | null>(
    formoSessionStorage.getItem(LAST_ACTIVE_PROJECT_KEY_NAME) as string,
  );

  const {
    data: teams = [],
    refetch: refetchTeams,
    isLoading,
  } = useQuery<GetTeamsOfUserResponse>({
    queryKey: ['teams', user?.id],
    queryFn: async () => (await client.get('api/teams')).data,
    enabled: !!user,
    refetchOnWindowFocus: false,
  });

  const teamIdFromSession = formoSessionStorage.getItem(
    'activeTeamId',
  ) as string;
  if (teamIdFromSession === null) {
    formoSessionStorage.removeItem('activeTeamId');
  }

  const teamId: string =
    teamIdParams || teamIdFromSession || (teams[0]?.teamId as string);

  useEffect(() => {
    if (teamId) {
      formoSessionStorage.setItem('activeTeamId', teamId);
    }
  }, [teamId]);

  const {
    data: members = [],
    refetch: refetchMembers,
    isFetching,
  } = useQuery<GetTeamMembersResponse>({
    queryKey: ['teams', 'members', teamId],
    queryFn: async () =>
      (await client.get(`/api/teams/${teamId}/members`)).data,
    enabled: !!teamId,
    refetchOnWindowFocus: false,
  });

  const activeTeam = teams.find((team) => team.teamId === teamId) || null;

  useEffect(() => {
    if (teamIdParams && !activeTeam && teams.length !== 0) {
      router.replace('/');
    }
  }, [teamIdParams, activeTeam, router, teams]);

  const sortedMembers = useMemo(() => {
    return [...members].sort((a, b) => {
      const aIndex = ROLE_ORDER[a.role.toUpperCase() as TEAM_ROLES];
      const bIndex = ROLE_ORDER[b.role.toUpperCase() as TEAM_ROLES];
      return aIndex - bIndex;
    });
  }, [members]);

  return (
    <DashboardContext.Provider
      value={{
        currentTeamId: teamId,
        currentProjectId: currentProject,
        activeTeam: activeTeam,
        teams,
        refetchTeams,
        members: sortedMembers,
        refetchMembers,
        setCurrentProject,
        isLoadingTeams: isLoading,
        isLoadingMembers: isFetching,
        isAdmin: activeTeam?.role === TEAM_ROLES.ADMIN,
        isOwner: activeTeam?.role === TEAM_ROLES.OWNER,
        isEditor: activeTeam?.role === TEAM_ROLES.EDITOR,
        isMember: activeTeam?.role === TEAM_ROLES.MEMBER,
        permissions:
          TEAM_PERMISSIONS[
            activeTeam?.role.toUpperCase() as keyof typeof TEAM_ROLES
          ] ?? [],
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
