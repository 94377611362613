import { FC, HTMLAttributes } from 'react';

type BinanceWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const BinanceWallet: FC<BinanceWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 130 130"
      {...props}
    >
      <path fill="#000" d="M0 0h130v130H0z" />
      <path
        fill="#F3BA2F"
        d="M45.587 57.02 65.01 37.606l19.43 19.43 11.295-11.303L65.01 15 34.284 45.725zM15 65.004l11.299-11.299 11.298 11.299L26.3 76.302zM45.587 72.983 65.01 92.406l19.43-19.43 11.303 11.287-.008.007-30.725 30.734-30.725-30.718-.016-.016zM92.403 65.006 103.7 53.708 115 65.006l-11.299 11.299z"
      />
      <path
        fill="#F3BA2F"
        d="m76.471 64.998-11.46-11.469-8.476 8.475-.98.972-2.005 2.006-.016.016.016.024 11.46 11.453 11.461-11.47.008-.007z"
      />
    </svg>
  );
};

export default BinanceWallet;
