import React, { useMemo } from 'react';

import {
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  Pagination as PaginationRoot,
} from './pagination.core';

// FULL CUSTOM PAGINATION COMPONENT
export type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => any | Promise<any>;
  onPreviousClick?: () => any | Promise<any>;
  onNextClick?: () => any | Promise<any>;
  deltaNumber?: number;
  hideArrowButtons?: boolean;
  hideArrowText?: boolean;
  isLoading?: boolean;
};

function paginationFn(
  currentPage: number,
  nrOfPages: number,
  deltaValue?: any,
) {
  const delta = deltaValue,
    range = [],
    rangeWithDots = [];
  let l;

  if (nrOfPages < 1) return [];

  range.push(1);

  if (nrOfPages <= 1) {
    return range;
  }

  for (let i = currentPage - delta; i <= currentPage + delta; i++) {
    if (i < nrOfPages && i > 1) {
      range.push(i);
    }
  }
  range.push(nrOfPages);

  for (const i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
}

const Pagination = React.forwardRef<HTMLElement, PaginationProps>(
  (
    {
      currentPage,
      totalPages,
      onPageChange,
      onNextClick,
      onPreviousClick,
      deltaNumber = 2,
      hideArrowButtons = false,
      hideArrowText = false,
      isLoading = false,
    },
    ref,
  ) => {
    const pages = useMemo(
      () => paginationFn(currentPage, totalPages, deltaNumber),
      [totalPages, currentPage, deltaNumber],
    );

    const emptyPages = pages && pages.length === 0;

    return (
      <PaginationRoot ref={ref}>
        <PaginationContent>
          {hideArrowButtons === false && !emptyPages && (
            <PaginationItem key={'previous'}>
              <PaginationPrevious
                disabled={currentPage === 1 || totalPages === 0 || isLoading}
                aria-disabled={currentPage === 1}
                hideText={hideArrowText}
                onClick={() => {
                  if (currentPage <= 1) return;
                  onPreviousClick && onPreviousClick();
                }}
              />
            </PaginationItem>
          )}
          {pages.map((page, index) => {
            if (typeof page === 'string' && page === '...') {
              return (
                <PaginationItem key={'ellipsis' + index}>
                  <PaginationEllipsis onClick={() => null} />
                </PaginationItem>
              );
            }
            return (
              <PaginationItem key={index}>
                <PaginationLink
                  isActive={page === currentPage}
                  disabled={isLoading}
                  onClick={(e) => {
                    e.preventDefault();
                    if (page === currentPage) return;
                    onPageChange(page as number);
                  }}
                  className="h-9 w-9"
                >
                  {page}
                </PaginationLink>
              </PaginationItem>
            );
          })}
          {hideArrowButtons === false && !emptyPages && (
            <PaginationItem key={'next'}>
              <PaginationNext
                disabled={
                  currentPage === totalPages || totalPages === 0 || isLoading
                }
                aria-disabled={currentPage === totalPages}
                hideText={hideArrowText}
                onClick={() => {
                  if (currentPage >= totalPages) return;
                  onNextClick && onNextClick();
                }}
              />
            </PaginationItem>
          )}
        </PaginationContent>
      </PaginationRoot>
    );
  },
);

Pagination.displayName = 'Pagination';

export default Pagination;
