import { FC, HTMLAttributes } from 'react';

type OneKeyWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const OneKeyWallet: FC<OneKeyWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      version="1.1"
      {...props}
    >
      <g id="surface1">
        <rect
          x="0"
          y="0"
          width="28"
          height="28"
          style={{
            fill: 'rgb(26.666667%,83.921569%,17.254902%)',
            fillOpacity: 1,
            stroke: 'none',
          }}
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(0%,0%,0%)',
            fillOpacity: 1,
          }}
          d="M 28 14 C 28 23.664062 23.664062 28 14 28 C 4.335938 28 0 23.664062 0 14 C 0 4.335938 4.335938 0 14 0 C 23.664062 0 28 4.335938 28 14 Z M 28 14 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(0%,0%,0%)',
            fillOpacity: 1,
          }}
          d="M 15.265625 5.9375 L 11.371094 5.9375 L 10.6875 8.003906 L 12.851562 8.003906 L 12.851562 12.355469 L 15.265625 12.355469 Z M 15.265625 5.9375 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(0%,0%,0%)',
            fillOpacity: 1,
          }}
          d="M 18.441406 17.621094 C 18.441406 20.074219 16.453125 22.0625 14 22.0625 C 11.546875 22.0625 9.558594 20.074219 9.558594 17.621094 C 9.558594 15.167969 11.546875 13.179688 14 13.179688 C 16.453125 13.179688 18.441406 15.167969 18.441406 17.621094 Z M 16.425781 17.621094 C 16.425781 18.960938 15.339844 20.046875 14 20.046875 C 12.660156 20.046875 11.574219 18.960938 11.574219 17.621094 C 11.574219 16.28125 12.660156 15.195312 14 15.195312 C 15.339844 15.195312 16.425781 16.28125 16.425781 17.621094 Z M 16.425781 17.621094 "
        />
      </g>
    </svg>
  );
};

export default OneKeyWallet;
