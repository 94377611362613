import { Rdns } from '@formo/types';
import { ReactNode } from 'react';
import {
  BitgetWallet,
  BraveWallet,
  BybitWallet,
  Coin98Wallet,
  CoinbaseWallet,
  CompassWallet,
  CoreWallet,
  EnkryptWallet,
  FrameWallet,
  FrontierWallet,
  GateWallet,
  MagicEdenWallet,
  MetaMaskWallet,
  NestWallet,
  OkxWallet,
  OneKeyWallet,
  PhantomWallet,
  RabbyWallet,
  RainbowWallet,
  RoninWallet,
  SeifWallet,
  SubWallet,
  TalismanWallet,
  TokenPocketWallet,
  TrustWallet,
  XDeFiWallet,
  ZealWallet,
  ZerionWallet,
} from '~/components/icons/wallets';

type WalletMap = Record<Rdns, { rdns: string; name: string; icon: ReactNode }>;
// https://github.com/WalletConnect/EIP6963/blob/master/src/utils/constants.ts
// https://github.dev/rainbow-me/rainbowkit/tree/e8bcd6a3274e09fc8fb372927fa4a7da010e04b0/packages/rainbowkit/src/wallets/walletConnectors
export const WALLET_MAP: WalletMap = {
  'io.metamask': {
    name: 'MetaMask',
    rdns: 'io.metamask',
    icon: <MetaMaskWallet />,
  },
  'me.rainbow': {
    name: 'Rainbow',
    rdns: 'me.rainbow',
    icon: <RainbowWallet />,
  },
  'app.phantom': {
    name: 'Phantom',
    rdns: 'app.phantom',
    icon: <PhantomWallet />,
  },
  'app.core.extension': {
    name: 'Core',
    rdns: 'app.core.extension',
    icon: <CoreWallet />,
  },
  'app.subwallet': {
    name: 'SubWallet',
    rdns: 'app.subwallet',
    icon: <SubWallet />,
  },
  'app.zeal': {
    name: 'Zeal',
    rdns: 'app.zeal',
    icon: <ZealWallet />,
  },
  'coin98.com': {
    name: 'Coin98 Wallet',
    rdns: 'coin98.com',
    icon: <Coin98Wallet />,
  },
  'com.bitget.web3': {
    name: 'Bitget Wallet',
    rdns: 'com.bitget.web3',
    icon: <BitgetWallet />,
  },
  'com.brave.wallet': {
    name: 'Brave Wallet',
    rdns: 'com.brave.wallet',
    icon: <BraveWallet />,
  },
  'com.bybit': {
    name: 'Bybit Wallet',
    rdns: 'com.bybit',
    icon: <BybitWallet />,
  },
  'com.coinbase.wallet': {
    name: 'Coinbase Wallet',
    rdns: 'com.coinbase.wallet',
    icon: <CoinbaseWallet />,
  },
  'com.enkrypt': {
    name: 'Enkrypt Wallet',
    rdns: 'com.enkrypt',
    icon: <EnkryptWallet />,
  },
  'com.okex.wallet': {
    name: 'OKX Wallet',
    rdns: 'com.okex.wallet',
    icon: <OkxWallet />,
  },
  'com.passkeywallet.seif': {
    name: 'Seif',
    rdns: 'com.passkeywallet.seif',
    icon: <SeifWallet />,
  },
  'com.roninchain.wallet': {
    name: 'Ronin Wallet',
    rdns: 'com.roninchain.wallet',
    icon: <RoninWallet />,
  },
  'com.trustwallet.app': {
    name: 'Trust Wallet',
    rdns: 'com.trustwallet.app',
    icon: <TrustWallet />,
  },
  'io.gate.wallet': {
    name: 'Gate Wallet',
    rdns: 'io.gate.wallet',
    icon: <GateWallet />,
  },
  'io.leapwallet.CompassWallet': {
    name: 'Compass Wallet',
    rdns: 'io.leapwallet.CompassWallet',
    icon: <CompassWallet />,
  },
  'io.magiceden.wallet': {
    name: 'Magic Eden Wallet',
    rdns: 'io.magiceden.wallet',
    icon: <MagicEdenWallet />,
  },
  'io.rabby': {
    name: 'Rabby Wallet',
    rdns: 'io.rabby',
    icon: <RabbyWallet />,
  },
  'io.xdefi': {
    name: 'XDEFI Wallet',
    rdns: 'io.xdefi',
    icon: <XDeFiWallet />,
  },
  'io.zerion.wallet': {
    name: 'Zerion',
    rdns: 'io.zerion.wallet',
    icon: <ZerionWallet />,
  },
  'pro.tokenpocket': {
    name: 'TokenPocket',
    rdns: 'pro.tokenpocket',
    icon: <TokenPocketWallet />,
  },
  'sh.frame': {
    name: 'Frame',
    rdns: 'sh.frame',
    icon: <FrameWallet />,
  },
  'so.onekey.app.wallet': {
    name: 'OneKey',
    rdns: 'so.onekey.app.wallet',
    icon: <OneKeyWallet />,
  },
  'xyz.frontier.wallet': {
    name: 'Frontier Wallet',
    rdns: 'xyz.frontier.wallet',
    icon: <FrontierWallet />,
  },
  'xyz.nestwallet': {
    name: 'Nest',
    rdns: 'xyz.nestwallet',
    icon: <NestWallet />,
  },
  'xyz.talisman': {
    name: 'Talisman',
    rdns: 'xyz.talisman',
    icon: <TalismanWallet />,
  },
};
