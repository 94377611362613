import { FC, HTMLAttributes } from 'react';

type EventDeviceDesktopProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const EventDeviceDesktop: FC<EventDeviceDesktopProps> = ({
  size = 20,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={(21 * size) / 20}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
      {...props}
    >
      <path d="M12 8V4H8" />
      <rect width="16" height="12" x="4" y="8" rx="2" />
      <path d="M2 14h2" />
      <path d="M20 14h2" />
      <path d="M15 13v2" />
      <path d="M9 13v2" />
    </svg>
  );
};

export default EventDeviceDesktop;
