import { FC, HTMLAttributes } from 'react';

type HeadingProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const Heading: FC<HeadingProps> = ({
  size = 40,
  shouldBlend = false,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={(41 * size) / 40}
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_1903_27092)">
        <rect x="5" y="4.375" width="30" height="30" rx="6" fill="#FEE2E2" />
        <rect
          x="5.5"
          y="4.875"
          width="29"
          height="29"
          rx="5.5"
          stroke="#C42222"
          strokeOpacity="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6666 19.375C11.6666 18.8227 12.1143 18.375 12.6666 18.375H20C20.5522 18.375 21 18.8227 21 19.375C21 19.9273 20.5522 20.375 20 20.375H12.6666C12.1143 20.375 11.6666 19.9273 11.6666 19.375Z"
          fill="#C42222"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6666 12.875C13.2189 12.875 13.6666 13.3227 13.6666 13.875V24.875C13.6666 25.4273 13.2189 25.875 12.6666 25.875C12.1143 25.875 11.6666 25.4273 11.6666 24.875V13.875C11.6666 13.3227 12.1143 12.875 12.6666 12.875Z"
          fill="#C42222"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 12.875C20.5522 12.875 21 13.3227 21 13.875V24.875C21 25.4273 20.5522 25.875 20 25.875C19.4477 25.875 19 25.4273 19 24.875V13.875C19 13.3227 19.4477 12.875 20 12.875Z"
          fill="#C42222"
        />
        <path
          d="M27.8664 17.2976C27.8664 16.9288 27.6634 16.59 27.3382 16.4159C27.0131 16.2419 26.6185 16.261 26.3117 16.4656L23.4702 18.3599C23.0107 18.6662 22.8865 19.2871 23.1928 19.7466C23.4992 20.2062 24.1201 20.3303 24.5796 20.024L25.8664 19.1661V23.8749H24.4577C23.9054 23.8749 23.4577 24.3227 23.4577 24.8749C23.4577 25.4272 23.9054 25.8749 24.4577 25.8749H28.8969C29.4491 25.8749 29.8969 25.4272 29.8969 24.8749C29.8969 24.3227 29.4491 23.8749 28.8969 23.8749H27.8664V17.2976Z"
          fill="#C42222"
        />
      </g>
      {shouldBlend && (
        <defs>
          <filter
            id="filter0_d_1903_27092"
            x="0"
            y="0.375"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.87959 0 0 0 0 0.87959 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1903_27092"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1903_27092"
              result="shape"
            />
          </filter>
        </defs>
      )}
    </svg>
  );
};

export default Heading;
