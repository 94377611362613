import { FC, HTMLAttributes } from 'react';

type EventActionSignatureProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const EventActionSignature: FC<EventActionSignatureProps> = ({
  size = 16,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 11.3332L12.5627 10.0878C12.5152 10.0428 12.4554 10.0127 12.391 10.0014C12.3265 9.9901 12.2601 9.99802 12.2001 10.0242C12.1401 10.0503 12.0891 10.0936 12.0535 10.1486C12.0179 10.2035 11.9993 10.2677 12 10.3332V10.6665C12 10.8433 11.9298 11.0129 11.8047 11.1379C11.6797 11.2629 11.5101 11.3332 11.3333 11.3332H10C9.82319 11.3332 9.65362 11.2629 9.5286 11.1379C9.40357 11.0129 9.33333 10.8433 9.33333 10.6665C9.33333 8.96985 6.67267 8.01985 3.66667 7.99985C3.22464 7.99985 2.80072 8.17544 2.48816 8.488C2.17559 8.80056 2 9.22449 2 9.66651C2 10.1085 2.17559 10.5325 2.48816 10.845C2.80072 11.1576 3.22464 11.3332 3.66667 11.3332C6.43533 11.3332 6.83 3.80318 7.472 2.33318C7.58045 2.08504 7.74773 1.86707 7.95937 1.69812C8.17101 1.52917 8.42062 1.41434 8.68662 1.36355C8.95262 1.31277 9.22698 1.32757 9.48598 1.40666C9.74497 1.48576 9.98079 1.62677 10.173 1.8175C10.3653 2.00823 10.5081 2.24293 10.5893 2.50129C10.6704 2.75965 10.6874 3.0339 10.6387 3.30029C10.59 3.56667 10.4771 3.81719 10.3099 4.03015C10.1426 4.24311 9.92594 4.41211 9.67867 4.52251M2 13.9998H14"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EventActionSignature;
