import { FC, HTMLAttributes } from 'react';

type DawnWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const DawnWallet: FC<DawnWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 192 192"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-1"
        >
          <stop stopColor="#151515" offset="0%"></stop>
          <stop stopColor="#232323" offset="100%"></stop>
        </linearGradient>
        <rect id="path-2" x="0" y="0" width="192" height="192"></rect>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="99.7051532%"
          id="linearGradient-3"
        >
          <stop stopColor="#F3DC83" offset="0%"></stop>
          <stop stopColor="#F9EEC8" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Icons---Pixel-Fitted"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group">
          <g id="Rectangle">
            <use fill="#232323" xlinkHref="#path-2"></use>
            <use fill="url(#linearGradient-1)" xlinkHref="#path-2"></use>
          </g>
          <path
            d="M123.748399,157.001808 C115.293464,160.853955 105.89735,163 96,163 C86.1026503,163 76.7065363,160.853955 68.2516012,157.001808 Z M146.526595,140.002206 C144.677242,142.123986 142.696426,144.128189 140.59684,146.002121 L51.4031598,146.002121 C49.3035743,144.128189 47.3227581,142.123986 45.4734054,140.002206 Z M157.337411,122.999613 C155.955969,126.133404 154.34141,129.141319 152.515704,132.001388 L39.484296,132.001388 C37.6585895,129.141319 36.0440306,126.133404 34.662589,122.999613 Z M96,29 C133.003078,29 163,58.9969218 163,96 C163,103.706309 161.69895,111.108742 159.304524,117.999626 L32.6954763,117.999626 C30.3010496,111.108742 29,103.706309 29,96 C29,58.9969218 58.9969218,29 96,29 Z"
            id="Combined-Shape"
            fill="url(#linearGradient-3)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default DawnWallet;
