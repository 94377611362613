'use client';

import { Button } from '@formo/ui';
import { useRouter } from 'next/navigation';
import { FC } from 'react';
import { Plus } from '~/app/_components/common';
import useDashboard from '~/app/hooks/useDashboard';
import useDashboardModal from '~/app/hooks/useDashboardModal';
import { TEAM_ACTIONS } from '~/constants/permission';
import { cn } from '~/lib/utils';

interface CreateFormButtonProps {
  className?: string;
  plusIconSize?: number;
  classNames?: Record<string, string>;
  onCreate?: () => void;
  isDashboardTable?: boolean;
}

const CreateFormButton: FC<CreateFormButtonProps> = ({
  className,
  plusIconSize = 16,
  classNames = { plusIconClassName: '' },
  onCreate,
  isDashboardTable = false,
}) => {
  const {
    permissions,
    activeTeam,
    isLoadingMembers,
    isLoadingTeams,
    refetchTeams,
  } = useDashboard();
  const { openModal } = useDashboardModal();
  const router = useRouter();

  const onCreateForm = () => {
    if (!activeTeam?.teamId || isLoadingTeams || isLoadingMembers) return;

    openModal({
      type: 'create_form',
      teamId: activeTeam?.teamId,
      onSubmit: async (form) => {
        await refetchTeams();
        router.push(`/${form.id}/edit`);
      },
    });
  };

  if (!permissions.includes(TEAM_ACTIONS.CREATE_UPDATE_FORM)) return null;

  return (
    <div className={cn(isDashboardTable ? 'mt-8' : 'ml-auto', className)}>
      <Button
        onClick={onCreate || onCreateForm}
        disabled={!activeTeam || isLoadingMembers || isLoadingTeams}
        icon={
          <Plus size={plusIconSize} className={classNames.plusIconClassName} />
        }
        iconPosition="leading"
        size="sm"
      >
        Create a form
      </Button>
    </div>
  );
};

export default CreateFormButton;
