import { FC, HTMLAttributes } from 'react';

type SafeHeronWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const SafeHeronWallet: FC<SafeHeronWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 38 38"
      {...props}
    >
      <defs>
        <path id="prefix__a" d="M0 0h38v38H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          d="M19 0C8.507 0 0 8.507 0 19s8.507 19 19 19c10.494 0 19-8.507 19-19S29.494 0 19 0z"
          fill="#109D84"
          mask="url(#prefix__b)"
        />
        <path fill="#109D84" mask="url(#prefix__b)" d="M0 0h38v38H0z" />
        <path
          d="M7.59 15.792l16.07 10.477c.149.11.236.283.236.469l-.079 5.095c0 .547-.66.822-1.047.436L7.558 18.25a1.12 1.12 0 01-.33-.793v-1.483c0-.186.212-.294.362-.182zm8.73-10.065l15.29 14.154c.215.21.336.5.336.8v1.54a.227.227 0 01-.361.184l-11.577-7.196a.219.219 0 00-.347.177v.771c0 .255.103.499.285.677l3.579 3.504c.238.233.371.551.371.885V24.2c0 .172-.204.26-.33.143L17.7 18.871l-2.034-1.92a1.235 1.235 0 01-.389-.9V6.168c0-.544.655-.82 1.044-.44z"
          fill="#FFF"
          mask="url(#prefix__b)"
        />
      </g>
    </svg>
  );
};

export default SafeHeronWallet;
