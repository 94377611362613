import { FC, HTMLAttributes } from 'react';

type SafePalWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const SafePalWallet: FC<SafePalWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path fill="#4A21EF" d="M0 0h28v28H0z" />
      <g fill="#F7F6FF" clipPath="url(#a)">
        <path d="M13.014 6c-.487 0-.954.193-1.298.538l-5.409 5.409a1.046 1.046 0 0 0 0 1.483l3.545 3.545V10.7c0-.468.377-.848.845-.848h7.451L22 6h-8.986ZM9.852 18.148H17.3c.469 0 .848-.38.848-.848v-6.275l3.545 3.545a1.046 1.046 0 0 1 0 1.483l-5.409 5.41a1.836 1.836 0 0 1-1.298.537H6l3.852-3.852Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M6 6h16v16H6z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SafePalWallet;
