import { FC, HTMLAttributes } from 'react';

type FrontierWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const FrontierWallet: FC<FrontierWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <g clipPath="url(#a)">
        <mask
          id="b"
          width="28"
          height="28"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path fill="#fff" d="M0 0h28v28H0V0Z" />
        </mask>
        <g mask="url(#b)">
          <path fill="#CC703C" d="M28 0H0v28h28V0Z" />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="m22 5-.102.244-.333.78-.221.504c-1.678 3.771-2.984 5.561-4.373 5.561-.8 0-1.274-.209-1.887-.7l-.176-.146c-.441-.37-.627-.46-1.052-.46-.221 0-.617.256-1.117.857-.52.622-1.089 1.541-1.7 2.752l-.058.114h5.957l-.742 1.396h-5.563v5.733H9V5h13Zm-2.318 1.397h-9.048v5.543c1.083-1.706 2.114-2.563 3.222-2.563.862 0 1.37.22 2.01.733l.183.15c.412.345.57.425.922.425.389 0 1.418-1.483 2.711-4.288Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FrontierWallet;
