import { FC, HTMLAttributes } from 'react';

type CLVWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const CLVWallet: FC<CLVWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path fill="url(#a)" d="M0 0h28v28H0z" />
      <path
        fill="#0C0B0B"
        fillRule="evenodd"
        d="M14.014 22.96c4.954 0 8.97-4.012 8.97-8.96 0-4.948-4.016-8.96-8.97-8.96-4.953 0-8.97 4.012-8.97 8.96 0 4.948 4.017 8.96 8.97 8.96ZM12.432 9.723c0-.258.208-.467.466-.467h2.232c.258 0 .467.21.467.467v2.729h-3.165v3.096h3.165v2.729a.467.467 0 0 1-.466.466h-2.233a.467.467 0 0 1-.466-.466v-2.63h-2.7a.467.467 0 0 1-.466-.466v-2.262c0-.258.21-.467.467-.467h2.699V9.723Zm3.165 2.729h2.7c.258 0 .466.209.466.467v2.262a.467.467 0 0 1-.466.467h-2.7v-3.196Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="a"
          x1="2.331"
          x2="26.805"
          y1="25.667"
          y2="1.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BDFDE2" />
          <stop offset="1" stopColor="#9BDAF6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CLVWallet;
