import { FC, HTMLAttributes } from 'react';

type WigwamWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const WigwamWallet: FC<WigwamWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="800" height="800" fill="#80EF6E" />
      <path
        d="M30 306.266C30 209.564 30 161.213 48.8195 124.277C65.3736 91.7881 91.7881 65.3736 124.277 48.8195C161.213 30 209.564 30 306.266 30H493.734C590.436 30 638.787 30 675.723 48.8195C708.212 65.3736 734.626 91.7881 751.18 124.277C770 161.213 770 209.564 770 306.266V493.734C770 590.436 770 638.787 751.18 675.723C734.626 708.212 708.212 734.626 675.723 751.18C638.787 770 590.436 770 493.734 770H306.266C209.564 770 161.213 770 124.277 751.18C91.7881 734.626 65.3736 708.212 48.8195 675.723C30 638.787 30 590.436 30 493.734V306.266Z"
        fill="#80EF6E"
      />
      <path
        d="M564.444 264.236C565.179 260.829 568.191 258.397 571.676 258.397H671.999C676.899 258.397 680.445 263.073 679.123 267.791L588.092 592.73C587.197 595.924 584.285 598.133 580.968 598.133H486.038C482.757 598.133 479.867 595.971 478.941 592.824L404.114 338.667C402.548 333.348 395.01 333.362 393.463 338.686L319.648 592.798C318.729 595.958 315.834 598.133 312.543 598.133H219.116C215.799 598.133 212.888 595.925 211.992 592.731L120.886 267.793C119.563 263.074 123.109 258.397 128.009 258.397H228.209C231.713 258.397 234.736 260.855 235.451 264.285L269.133 425.801C270.296 431.375 278.096 431.792 279.846 426.374L350.687 206.989C351.673 203.935 354.517 201.865 357.727 201.865H442.075C445.298 201.865 448.15 203.95 449.126 207.022L518.875 426.433C520.598 431.853 528.388 431.481 529.587 425.921L564.444 264.236Z"
        fill="#0D1311"
      />
    </svg>
  );
};

export default WigwamWallet;
