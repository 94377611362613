import { FC, HTMLAttributes } from 'react';

type BifrostWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const BifrostWallet: FC<BifrostWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1618_12583)">
        <path d="M512 0H0V512H512V0Z" fill="url(#paint0_radial_1618_12583)" />
        <path
          d="M125.918 87.0209C122.011 86.7026 119 90.0541 119 94.2288V152C119 161.353 123.137 171.555 132.852 174.757L360.798 251.479C368.09 253.932 377.708 260.783 368.074 270.214C376.547 265.493 392 261.759 392 245.406V203.184C392 183.182 382.617 175.555 367.685 170.392L127.644 87.3937C127.055 87.1828 126.477 87.0668 125.917 87.0206L125.918 87.0209ZM259.62 222.633L200.122 243.2C185.191 248.363 175.807 256.005 175.807 276.008V318.229C175.807 335.984 190.136 340.465 199.734 343.037C190.1 333.606 199.722 326.757 207.01 324.303L360.59 272.615C365.458 270.976 367.409 267.04 366.893 263.568C366.3 259.578 361.583 256.955 355.932 255.052C323.828 244.246 259.62 222.633 259.62 222.633ZM308.063 295.938C308.063 295.938 245.514 316.992 214.239 327.519C209.876 328.986 204.109 331.887 203.387 336.275C202.914 339.152 204.278 344.585 209.584 346.371C245.535 358.469 317.438 382.664 317.438 382.664C324.73 385.117 333.089 392.885 323.455 402.315C333.053 399.742 348.656 394.344 348.656 376.59V334.353C348.656 314.351 339.257 306.724 324.325 301.561L308.063 295.938ZM224.064 356.629V412.241C224.064 418.982 232.378 425.553 239.736 423.674L315.329 404.366C320.947 402.933 321.728 397.625 321.294 395.151C320.523 390.765 315.616 387.437 311.312 385.989L224.064 356.629Z"
          fill="white"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_1618_12583"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(180.544 188.072) rotate(43.8972) scale(366.433 366.622)"
        >
          <stop stopColor="#435AE1" stopOpacity="0.996078" />
          <stop offset="1" stopColor="#3247C7" />
        </radialGradient>
        <clipPath id="clip0_1618_12583">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BifrostWallet;
