import { FC, HTMLAttributes } from 'react';

type ImTokenWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const ImTokenWallet: FC<ImTokenWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      {...props}
    >
      <path fill="url(#a)" d="M0 0h28v28H0z" />
      <path
        fill="#fff"
        d="M14.193 20.693c-4.435.374-8.547-2.224-8.87-6.26-.298-3.326 1.825-4.758 3.504-4.885a2.908 2.908 0 0 1 3.282 2.376c.12 1.325-.742 1.952-1.343 1.996a1.007 1.007 0 0 1-1.128-.83c-.044-.513.165-.583.101-1.121a1.172 1.172 0 0 0-1.432-1.033c-.57.044-1.622.703-1.489 2.332.134 1.628 1.774 2.94 3.903 2.743a4.346 4.346 0 0 0 4.03-4.372c0-.128.03-.254.088-.367.027-.044.057-.087.09-.127.056-.089.122-.172.196-.247l.209-.21c1-.911 4.637-3.104 8.052-2.42a.14.14 0 0 1 .076 0c.133 0 .21.09.222.222.539 7.996-4.745 11.785-9.49 12.203Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1="27.236"
          x2=".05"
          y1=".889"
          y2="27.978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#11C4D1" />
          <stop offset="1" stopColor="#0062AD" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ImTokenWallet;
