import { createClientComponentClient } from '@supabase/auth-helpers-nextjs';
import { Database } from '~/lib/database.types';

const supabaseUrl: string = process.env.NEXT_PUBLIC_SUPABASE_URL!;
const supabaseAnonKey: string = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!;

export default createClientComponentClient<Database>({
  supabaseUrl: supabaseUrl,
  supabaseKey: supabaseAnonKey,
  isSingleton: true,
});
