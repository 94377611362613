export { default as Table } from './table';
export type { TableProps } from './table';

export {
  TableCore,
  TableBody,
  TableCaption,
  TableCell,
  TableFoot,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from './table.core';
