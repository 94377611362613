import React, { FC } from 'react';
import { Button } from '~/components/ui/button';
import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '~/components/ui/dialog';

type ActionModalProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
};

const ActionModal: FC<ActionModalProps> = ({
  title,
  description,
  onCancel,
  onConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}) => {
  return (
    <>
      <DialogHeader className="space-y-2">
        <DialogTitle className="text-xl font-medium text-black">
          {title}
        </DialogTitle>
        <DialogDescription className="text-base font-medium text-gray-600">
          {description}
        </DialogDescription>
      </DialogHeader>
      <DialogFooter className="mt-[18px] flex justify-end gap-3">
        <Button variant="ghost" onClick={onCancel} className="text-gray-900">
          {cancelText}
        </Button>
        <Button
          className="text-black ring-[1.5px] ring-inset ring-green-400"
          onClick={onConfirm}
        >
          {confirmText}
        </Button>
      </DialogFooter>
    </>
  );
};

export default ActionModal;
