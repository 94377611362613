import { FC, HTMLAttributes } from 'react';

type ConnectXProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const ConnectX: FC<ConnectXProps> = ({
  size = 40,
  shouldBlend = false,
  ...props
}) => {
  if (shouldBlend)
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_549_2285)">
          <rect
            x="5"
            y="4.11096"
            width="30"
            height="30"
            rx="6"
            fill="#F4F5FA"
          />
          <rect
            x="5.5"
            y="4.61096"
            width="29"
            height="29"
            rx="5.5"
            stroke="black"
            strokeOpacity="0.3"
          />
          <mask
            id="mask0_549_2285"
            maskUnits="userSpaceOnUse"
            x="10"
            y="9"
            width="20"
            height="20"
          >
            <path d="M29.5 9.61096H10.5V28.611H29.5V9.61096Z" fill="white" />
          </mask>
          <g mask="url(#mask0_549_2285)">
            <path
              d="M21.8076 17.652L28.8808 9.61096H27.2046L21.063 16.5929L16.1577 9.61096H10.5L17.9178 20.1689L10.5 28.6012H12.1762L18.6619 21.228L23.8423 28.6012H29.5L21.8072 17.652H21.8076ZM19.5118 20.2619L18.7602 19.2106L12.7802 10.845H15.3547L20.1807 17.5963L20.9323 18.6476L27.2054 27.4233H24.6309L19.5118 20.2623V20.2619Z"
              fill="black"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_549_2285"
            x="0"
            y="0.110962"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.862745 0 0 0 0 0.87451 0 0 0 0 0.937255 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_549_2285"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_549_2285"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.4375"
        y="0.458008"
        width="24"
        height="24"
        rx="6"
        fill="#F4F5FA"
      />
      <rect
        x="0.9375"
        y="0.958008"
        width="23"
        height="23"
        rx="5.5"
        stroke="black"
        strokeOpacity="0.3"
      />
      <mask
        id="mask0_6187_26986"
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="17"
        height="17"
      >
        <path
          d="M20.0359 4.85791H4.83594V20.0579H20.0359V4.85791Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6187_26986)">
        <path
          d="M13.882 11.2908L19.5405 4.85791H18.1996L13.2863 10.4435L9.36209 4.85791H4.83594L10.7702 13.3043L4.83594 20.0501H6.17691L11.3655 14.1516L15.5098 20.0501H20.0359L13.8817 11.2908H13.882ZM12.0454 13.3787L11.4441 12.5376L6.66008 5.84516H8.71973L12.5805 11.2462L13.1817 12.0873L18.2003 19.1078H16.1406L12.0454 13.379V13.3787Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default ConnectX;
