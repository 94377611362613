import { cva } from 'class-variance-authority';
import { HTMLAttributes, forwardRef } from 'react';

import cn from '../lib/cn';

const badgeVariants = cva(
  cn(
    // layout
    'rounded inline-flex items-center gap-0.5',
    // text
    's1 font-semibold',
    // border
    'border',
    // inner svg
    '[&>svg]:w-3 [&>svg]:h-3',
  ),
  {
    variants: {
      type: {
        light: '',
        strong: 'text-surface-primary',
      },
      size: {
        md: 'h-5 px-2 py-0.5',
        sm: 'h-4 px-1.5 py-0',
      },
      // Based on type 'strong' for default
      color: {
        gray: '',
        blue: '',
        green: '',
        red: '',
        orange: '',
        yellow: '',
        teal: '',
        cyan: '',
        purple: '',
        pink: '',
      },
    },
    compoundVariants: [
      // strong
      {
        type: 'strong',
        color: 'gray',
        className: 'bg-gray-800 border-gray-800',
      },
      {
        type: 'strong',
        color: 'blue',
        className: 'bg-blue-400 border-blue-400',
      },
      {
        type: 'strong',
        color: 'green',
        className: 'bg-green-600 border-green-600',
      },
      {
        type: 'strong',
        color: 'red',
        className: 'bg-red-500 border-red-500',
      },
      {
        type: 'strong',
        color: 'orange',
        className: 'bg-orange-500 border-orange-500',
      },
      {
        type: 'strong',
        color: 'yellow',
        className: 'bg-yellow-600 border-yellow-600',
      },
      {
        type: 'strong',
        color: 'teal',
        className: 'bg-teal-400 border-teal-400',
      },
      {
        type: 'strong',
        color: 'cyan',
        className: 'bg-cyan-400 border-cyan-400',
      },
      {
        type: 'strong',
        color: 'purple',
        className: 'bg-purple-400 border-purple-400',
      },
      {
        type: 'strong',
        color: 'pink',
        className: 'bg-pink-400 border-pink-400',
      },
      // light
      {
        type: 'light',
        color: 'gray',
        className: 'bg-gray-50 border-gray-200 text-gray-800',
      },
      {
        type: 'light',
        color: 'blue',
        className: 'bg-blue-50 border-blue-100 text-blue-400',
      },
      {
        type: 'light',
        color: 'green',
        className: 'bg-green-50 border-green-200 text-green-600',
      },
      {
        type: 'light',
        color: 'red',
        className: 'bg-red-50 border-red-100 text-red-500',
      },
      {
        type: 'light',
        color: 'orange',
        className: 'bg-orange-50 border-orange-100 text-orange-500',
      },
      {
        type: 'light',
        color: 'yellow',
        className: 'bg-yellow-50 border-yellow-100 text-yellow-600',
      },
      {
        type: 'light',
        color: 'teal',
        className: 'bg-teal-50 border-teal-100 text-teal-400',
      },
      {
        type: 'light',
        color: 'cyan',
        className: 'bg-cyan-50 border-cyan-100 text-cyan-400',
      },
      {
        type: 'light',
        color: 'purple',
        className: 'bg-purple-50 border-purple-100 text-purple-400',
      },
      {
        type: 'light',
        color: 'pink',
        className: 'bg-pink-50 border-pink-100 text-pink-400',
      },
    ],
  },
);

export type BadgeColor =
  | 'gray'
  | 'blue'
  | 'green'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'teal'
  | 'cyan'
  | 'purple'
  | 'pink';
export type BadgeSize = 'sm' | 'md';
export type BadgeStatus =
  | 'none'
  | 'error'
  | 'warning'
  | 'information'
  | 'success';
export type BadgeType = 'light' | 'strong';

export type BadgeProps = HTMLAttributes<HTMLDivElement> & {
  type?: BadgeType;
  color?: BadgeColor;
  size?: BadgeSize;
  status?: BadgeStatus;
  // icon
  icon?: React.ReactNode;
  iconOnly?: boolean;
  iconPosition?: 'leading' | 'trailing';
};

const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      className,
      type = 'strong',
      color = 'gray',
      size = 'md',
      status,
      children,
      icon = null,
      iconOnly,
      iconPosition = 'trailing',
      ...props
    },
    ref,
  ) => {
    const getTypeByStatus = (): BadgeColor => {
      switch (status) {
        case 'error':
          return 'red';
        case 'information':
          return 'blue';
        case 'success':
          return 'green';
        case 'warning':
          return 'orange';
        default:
          return 'gray';
      }
    };

    const colorByStatus = status ? getTypeByStatus() : color;

    const renderChildren = () => {
      if (iconOnly) {
        return icon;
      }

      if (iconPosition === 'trailing') {
        return [children, icon];
      }

      return [icon, children];
    };

    return (
      <div
        {...props}
        ref={ref}
        className={badgeVariants({
          type,
          color: colorByStatus,
          size,
          className,
        })}
      >
        {renderChildren()}
      </div>
    );
  },
);

Badge.displayName = 'Badge';

export default Badge;
