import { FC, HTMLAttributes } from 'react';

type OktoWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const OktoWallet: FC<OktoWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path fill="url(#a)" d="M0 0h28v28H0z" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M14 7.52a6.48 6.48 0 0 0-6.398 7.512c.035.23.17.727.421.882.314.193.67.027.961-.11h.002l1.23-.573a.526.526 0 0 1 .445.01.53.53 0 0 1 .284.504.517.517 0 0 1-.287.426l-1.522.709a.714.714 0 0 0-.386.43.705.705 0 0 0 .44.874.695.695 0 0 0 .514-.021l3.34-1.557a.514.514 0 0 1 .439.016.52.52 0 0 1 .284.488c-.012.14-.09.347-.28.436l-2.265 1.055a.696.696 0 0 0-.384.585.697.697 0 0 0 .984.683l4.068-1.897a.542.542 0 0 1 .45 0 .52.52 0 0 1 .3.461c0 .149-.006.258-.242.469-.206.184-.508.366-1.537.805-.353.15-.593.26-.554.45.03.143.134.197.479.181.345-.015 1.897-.137 3.452-1.436A6.466 6.466 0 0 0 20.48 14c0-.722-.118-1.416-.336-2.064a.624.624 0 0 1-.51-1.139A6.478 6.478 0 0 0 14 7.52Zm2.4 4.08a.624.624 0 1 1-1.247 0 .624.624 0 0 1 1.247 0Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="a"
          x1="14"
          x2="14"
          y1="0"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6246F5" />
          <stop offset=".493" stopColor="#5166EE" />
          <stop offset="1" stopColor="#56B9F9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default OktoWallet;
