import { FC, HTMLAttributes } from 'react';

type IOPayWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const IOPayWallet: FC<IOPayWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 40 40"
      {...props}
    >
      <path
        fill="#363647"
        d="M22.302 40q-5.082 0-9.087-2.108c-2.67-1.406-4.783-3.404-6.297-5.993-1.515-2.59-2.272-5.623-2.272-9.137s.757-6.548 2.272-9.137 3.627-4.587 6.297-5.993 5.739-2.109 9.167-2.109 6.457.703 9.127 2.109q3.945 2.109 6.218 5.993c1.514 2.589 2.271 5.623 2.271 9.137s-.757 6.547-2.271 9.137c-1.515 2.589-3.627 4.587-6.258 5.993C28.759 39.297 25.73 40 22.302 40"
      />
      <defs>
        <linearGradient id="a" x1=".913" x2=".224" y1="1" y2=".166">
          <stop offset="0" stopColor="#44FFB2" />
          <stop offset=".194" stopColor="rgba(71, 252, 181, 1)" />
          <stop offset="1" stopColor="#855EFF" />
        </linearGradient>
      </defs>
      <path
        fill="url(#a)"
        stroke="#363647"
        strokeMiterlimit="8.333"
        strokeWidth=".67"
        d="M36.829 20.191c0 9.679-8.158 17.548-18.248 17.548S.333 29.87.333 20.191 8.491 2.643 18.581 2.643s18.248 7.869 18.248 17.548Z"
      />
      <path
        fill="#292936"
        d="M19.515 25.21c3.109 0 5.58-2.386 5.58-5.303s-2.511-5.304-5.58-5.304-5.58 2.387-5.58 5.304 2.471 5.303 5.58 5.303"
      />
      <path
        fill="#FFF"
        stroke="#343439"
        strokeMiterlimit="8.333"
        strokeWidth=".4"
        d="m32.262 11.372 5.261-5c1.149-1.092.888-3.008-.438-4.268C35.76.845 33.763.605 32.624 1.687l-5.261 5.001c-1.148 1.092-.887 3.007.439 4.268 1.324 1.259 3.322 1.499 4.46.416Z"
      />
    </svg>
  );
};

export default IOPayWallet;
