import { AlertCircle } from 'lucide-react';
import { FC } from 'react';
import { Button } from '~/components/ui/button';
import { cn } from '~/lib/utils';

const variantMapper = {
  destructive: {
    icon: AlertCircle,
    color: {
      shadow: 'bg-red-50',
      main: 'text-red-500',
      background: 'bg-[rgb(255,205,212)]',
    },
  },
};

type Variant = keyof typeof variantMapper;

type ActionPopoverProps = {
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  description?: string;
  variant?: Variant;
  confirmText?: string;
  cancelText?: string;
};

const ActionPopover: FC<ActionPopoverProps> = ({
  onConfirm,
  onCancel,
  title,
  description,
  variant = 'destructive',
  confirmText = 'Revoke',
  cancelText = 'Cancel',
}) => {
  const currentVariant = variantMapper[variant];
  const Icon = currentVariant.icon;

  return (
    <div className="flex flex-col items-center px-2">
      <div
        className={cn(
          currentVariant.color.shadow,
          'relative aspect-square w-[28px] rounded-full bg-negative-50',
        )}
      >
        <div
          className={cn(
            currentVariant.color.background,
            'absolute left-1/2 top-1/2 aspect-square w-[24px] -translate-x-1/2 -translate-y-1/2 rounded-full',
          )}
        ></div>
        <Icon
          size={16}
          className={cn(
            currentVariant.color.main,
            'absolute left-1/2 top-1/2 flex-shrink-0 -translate-x-1/2 -translate-y-1/2',
          )}
        />
      </div>
      <h2 className="mt-2 text-center text-sm font-medium">{title}</h2>
      <p className="mt-2 text-center text-xs font-medium text-gray-700">
        {description}
      </p>
      <div className="mt-[14px] flex justify-center gap-1.5">
        <Button
          variant="ghost"
          className={cn(
            currentVariant.color.main,
            'h-[32px] rounded-lg px-4 py-1.5 hover:text-red-500',
          )}
          onClick={onCancel}
        >
          {cancelText}
        </Button>
        <Button
          variant={variant}
          className="h-[32px] rounded-lg px-4 py-1.5 ring-[1.5px] ring-inset ring-white/30"
          onClick={onConfirm}
        >
          {confirmText}
        </Button>
      </div>
    </div>
  );
};

export const PopoverArrow = ({ open = false }) => (
  <div
    className={cn(
      'absolute left-[calc(100%_-_20px)] top-0 -z-10 aspect-square h-3 -translate-y-1/2 rotate-45 bg-white shadow-[0px_0px_4px_0px_rgba(0,0,0,0.15)]',
      open ? 'animate-in' : 'animate-out',
    )}
  ></div>
);

export default ActionPopover;
