import { FC, HTMLAttributes } from 'react';

type AnalyticsPageProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const AnalyticsPage: FC<AnalyticsPageProps> = ({
  size = 20,
  color = '#030712',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={(21 * size) / 20}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 14.2288V18.3954"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3335 12.5623V18.3956"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6667 9.22876V18.3954"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3334 3.39551L11.1284 10.6005C11.0897 10.6393 11.0437 10.6701 10.9931 10.6911C10.9425 10.7121 10.8882 10.7229 10.8334 10.7229C10.7786 10.7229 10.7243 10.7121 10.6737 10.6911C10.6231 10.6701 10.5771 10.6393 10.5384 10.6005L7.79508 7.85717C7.71695 7.77906 7.61098 7.73518 7.5005 7.73518C7.39001 7.73518 7.28405 7.77906 7.20592 7.85717L1.66675 13.3955"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.3335 15.8955V18.3955"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66675 12.5623V18.3956"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AnalyticsPage;
