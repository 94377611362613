import supabaseClient from './supabase.singleton';
import supabaseClientComponentClient from './supabaseClientComponentClient.singleton';

export const useSupabase = () => {
  return supabaseClientComponentClient;
};

export const useSupabaseBucket = () => {
  return supabaseClient;
};
