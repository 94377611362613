import { FC, HTMLAttributes } from 'react';

type ConnectWorldIdProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const ConnectWorldId: FC<ConnectWorldIdProps> = ({
  size = 40,
  shouldBlend = false,
  ...props
}) => {
  if (shouldBlend)
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 41 41"
        fill="none"
        {...props}
      >
        <g filter="url(#filter0_d_7818_359)">
          <rect
            x="5.91406"
            y="5.62451"
            width="30"
            height="30"
            rx="10"
            fill="white"
          />
          <rect
            x="6.41406"
            y="6.12451"
            width="29"
            height="29"
            rx="9.5"
            stroke="black"
            strokeOpacity="0.1"
          />
          <mask
            id="mask0_7818_359"
            style={{
              maskType: 'luminance',
            }}
            maskUnits="userSpaceOnUse"
            x="11"
            y="11"
            width="20"
            height="20"
          >
            <path
              d="M30.4141 11.1245H11.4141V30.1245H30.4141V11.1245Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_7818_359)">
            <path
              d="M29.7328 17.0962C29.4805 16.4654 29.1609 15.8682 28.7824 15.3047C27.075 12.7814 24.1817 11.1245 20.9099 11.1245C15.6615 11.1245 11.4141 15.3804 11.4141 20.6203C11.4141 25.8686 15.6699 30.1245 20.9099 30.1245C24.1901 30.1245 27.075 28.4676 28.7824 25.9443C29.1609 25.3892 29.4721 24.7921 29.7328 24.1613C30.1701 23.0678 30.4141 21.8735 30.4141 20.6287C30.4057 19.3839 30.1701 18.1896 29.7328 17.0962ZM28.4628 19.6615H17.5876C17.7558 19.0727 18.067 18.5512 18.4875 18.1391C19.1268 17.4999 20.0099 17.1046 20.9856 17.1046H27.6637C28.0759 17.8868 28.345 18.7447 28.4628 19.6615ZM20.8594 12.9665C23.021 12.9665 24.9807 13.8664 26.3769 15.3131H21.2211C19.7492 15.3131 18.4203 15.9103 17.4614 16.8691C16.7129 17.6177 16.1914 18.5765 15.9979 19.6615H13.2644C13.7354 15.885 16.9568 12.9665 20.8594 12.9665ZM20.8594 28.2994C16.9568 28.2994 13.7354 25.3808 13.256 21.6044H15.9895C16.4437 24.0771 18.6137 25.9528 21.2211 25.9528H26.3769C24.9891 27.3994 23.0294 28.2994 20.8594 28.2994ZM20.9856 24.1697C19.3707 24.1697 18.0081 23.0847 17.5876 21.6044H28.4628C28.345 22.5211 28.0674 23.379 27.6637 24.1697H20.9856Z"
              fill="#030712"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_7818_359"
            x="0.914062"
            y="0.624512"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0117647 0 0 0 0 0.027451 0 0 0 0 0.0705882 0 0 0 0.15 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7818_359"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7818_359"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 41 41"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_d_7818_359)">
        <rect
          x="5.91406"
          y="5.62451"
          width="30"
          height="30"
          rx="10"
          fill="white"
        />
        <rect
          x="6.41406"
          y="6.12451"
          width="29"
          height="29"
          rx="9.5"
          stroke="black"
          strokeOpacity="0.1"
        />
        <mask
          id="mask0_7818_359"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="11"
          y="11"
          width="20"
          height="20"
        >
          <path
            d="M30.4141 11.1245H11.4141V30.1245H30.4141V11.1245Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_7818_359)">
          <path
            d="M29.7328 17.0962C29.4805 16.4654 29.1609 15.8682 28.7824 15.3047C27.075 12.7814 24.1817 11.1245 20.9099 11.1245C15.6615 11.1245 11.4141 15.3804 11.4141 20.6203C11.4141 25.8686 15.6699 30.1245 20.9099 30.1245C24.1901 30.1245 27.075 28.4676 28.7824 25.9443C29.1609 25.3892 29.4721 24.7921 29.7328 24.1613C30.1701 23.0678 30.4141 21.8735 30.4141 20.6287C30.4057 19.3839 30.1701 18.1896 29.7328 17.0962ZM28.4628 19.6615H17.5876C17.7558 19.0727 18.067 18.5512 18.4875 18.1391C19.1268 17.4999 20.0099 17.1046 20.9856 17.1046H27.6637C28.0759 17.8868 28.345 18.7447 28.4628 19.6615ZM20.8594 12.9665C23.021 12.9665 24.9807 13.8664 26.3769 15.3131H21.2211C19.7492 15.3131 18.4203 15.9103 17.4614 16.8691C16.7129 17.6177 16.1914 18.5765 15.9979 19.6615H13.2644C13.7354 15.885 16.9568 12.9665 20.8594 12.9665ZM20.8594 28.2994C16.9568 28.2994 13.7354 25.3808 13.256 21.6044H15.9895C16.4437 24.0771 18.6137 25.9528 21.2211 25.9528H26.3769C24.9891 27.3994 23.0294 28.2994 20.8594 28.2994ZM20.9856 24.1697C19.3707 24.1697 18.0081 23.0847 17.5876 21.6044H28.4628C28.345 22.5211 28.0674 23.379 27.6637 24.1697H20.9856Z"
            fill="#030712"
          />
        </g>
      </g>
    </svg>
  );
};

export default ConnectWorldId;
