'use client';

import { format, isEqual } from 'date-fns';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import * as React from 'react';
import {
  DateRange,
  DayPicker,
  NextMonthButtonProps,
  PreviousMonthButtonProps,
  PropsBase,
  PropsMulti,
  PropsMultiRequired,
  PropsRange,
  PropsRangeRequired,
  PropsSingle,
  PropsSingleRequired,
} from 'react-day-picker';
import 'react-day-picker/style.css';

import { Button } from '../button';
import cn from '../lib/cn';

type Presets<T = Date> = {
  label: string;
  date: T;
};

interface PropsSingleCalendar extends PropsSingle {
  presets?: Presets[];
}
interface PropsSingleRequiredCalendar extends PropsSingleRequired {
  presets?: Presets[];
}
interface PropsRangeCalendar extends PropsRange {
  presets?: Presets<DateRange>[];
}
interface PropsRangeRequiredCalendar extends PropsRangeRequired {
  presets?: Presets<DateRange>[];
}
export type CalendarProps = PropsBase &
  (
    | PropsSingleCalendar
    | PropsSingleRequiredCalendar
    | PropsMulti
    | PropsMultiRequired
    | PropsRangeCalendar
    | PropsRangeRequiredCalendar
    | {
        mode?: undefined;
        required?: undefined;
      }
  );

/* eslint-disable react/prop-types */
function Calendar({
  showOutsideDays = true,
  weekStartsOn = 1,
  ...props
}: CalendarProps) {
  switch (props.mode) {
    case 'single':
      return (
        <SingleModeCalendar
          {...props}
          showOutsideDays={showOutsideDays}
          weekStartsOn={weekStartsOn}
        />
      );
    case 'multiple':
      return (
        <MultipleModeCalendar
          {...props}
          showOutsideDays={showOutsideDays}
          weekStartsOn={weekStartsOn}
        />
      );
    case 'range':
      return (
        <RangeModeCalendar
          {...props}
          showOutsideDays={showOutsideDays}
          weekStartsOn={weekStartsOn}
        />
      );

    default:
      return (
        <SingleModeCalendar
          {...props}
          mode="single"
          showOutsideDays={showOutsideDays}
          weekStartsOn={weekStartsOn}
        />
      );
  }
}

Calendar.displayName = 'Calendar';

const SingleModeCalendar: React.FC<
  PropsBase & (PropsSingleCalendar | PropsSingleRequiredCalendar)
> = (props) => {
  const { presets } = props;
  const [month, setMonth] = React.useState(props.month);

  if (presets) {
    return (
      <div className="flex w-fit">
        <div className="p-2 min-w-36 rounded-ss-lg rounded-es-lg border border-r-0 border-content-divider">
          {presets.map((preset, index) => (
            <div
              key={index}
              className={cn(
                'w-full px-2 py-2.5 rounded cursor-pointer bg-transparent hover:bg-surface-tertiary b3',
                props.selected &&
                  isEqual(
                    format(preset.date, 'LLL dd, y'),
                    format(props.selected, 'LLL dd, y'),
                  ) &&
                  'bg-surface-tertiary',
              )}
              onClick={(e) => {
                props.onSelect &&
                  props.onSelect(preset.date, preset.date, {}, e);

                setMonth(preset.date);
              }}
            >
              {preset.label}
            </div>
          ))}
        </div>
        <div className="rounded-se-lg rounded-ee-lg border border-content-divider">
          <DayPicker
            month={month}
            onMonthChange={setMonth}
            classNames={{
              root: 'rdp-root w-fit',
              nav: 'absolute flex items-center justify-between h-[var(--rdp-nav-height)] w-[calc(100%-24px)] top-3 right-3',
              weekdays: 'rdp-weekdays mb-2',
              weekday: 'rdp-weekday text-content-tertiary !s1 !font-normal',
              month: 'rdp-month p-3',
              month_caption: 'rdp-month_caption justify-center',
              caption_label: 'rdp-caption_label b3',
              day: 'h-9 w-9 rounded-md relative hover:bg-surface-tertiary b3',
              day_button: 'h-9 w-9 rounded-md',
              outside: 'rdp-outside text-content-tertiary',
              disabled: 'rdp-disabled text-content-tertiary',
              today:
                'after:content-[""] after:block after:w-3.5 after:h-0.5 after:bg-content-brand-primary after:absolute after:bottom-1.5 after:left-1/2 after:-translate-x-1/2',
              selected: '!bg-content-safe !text-white after:bg-white',
              footer: props.footer
                ? 'rdp-footer p-3 border-t border-t-content-divider'
                : '',
              ...props.classNames,
            }}
            components={{
              PreviousMonthButton: (props: PreviousMonthButtonProps) => (
                <NavButton icon={<ChevronLeft />} {...props} />
              ),
              NextMonthButton: (props: NextMonthButtonProps) => (
                <NavButton icon={<ChevronRight />} {...props} />
              ),
            }}
            {...props}
          />
        </div>
      </div>
    );
  }

  return (
    <DayPicker
      month={month}
      onMonthChange={setMonth}
      classNames={{
        root: 'rdp-root w-fit rounded-md border border-content-divider',
        nav: 'absolute flex items-center justify-between h-[var(--rdp-nav-height)] w-[calc(100%-24px)] top-3 right-3',
        weekdays: 'rdp-weekdays mb-2',
        weekday: 'rdp-weekday text-content-tertiary !s1 !font-normal',
        month: 'rdp-month p-3',
        month_caption: 'rdp-month_caption justify-center',
        caption_label: 'rdp-caption_label b3',
        day: 'h-9 w-9 rounded-md relative hover:bg-surface-tertiary b3',
        day_button: 'h-9 w-9 rounded-md',
        outside: 'rdp-outside text-content-tertiary',
        disabled: 'rdp-disabled text-content-tertiary',
        today:
          'after:content-[""] after:block after:w-3.5 after:h-0.5 after:bg-content-brand-primary after:absolute after:bottom-1.5 after:left-1/2 after:-translate-x-1/2',
        selected: '!bg-content-safe !text-white after:bg-white',
        footer: props.footer
          ? 'rdp-footer p-3 border-t border-t-content-divider'
          : '',
        ...props.classNames,
      }}
      components={{
        PreviousMonthButton: (props: PreviousMonthButtonProps) => (
          <NavButton icon={<ChevronLeft />} {...props} />
        ),
        NextMonthButton: (props: NextMonthButtonProps) => (
          <NavButton icon={<ChevronRight />} {...props} />
        ),
      }}
      {...props}
    />
  );
};

const MultipleModeCalendar: React.FC<
  PropsBase & (PropsMulti | PropsMultiRequired)
> = (props) => {
  return (
    <DayPicker
      classNames={{
        root: 'rdp-root rounded-md border border-content-divider',
        nav: 'absolute flex items-center justify-between h-[var(--rdp-nav-height)] w-[calc(100%-24px)] top-3 right-3',
        weekdays: 'rdp-weekdays mb-2',
        weekday: 'rdp-weekday text-content-tertiary !font-normal !s1',
        month: 'rdp-month p-3',
        month_caption: 'rdp-month_caption justify-center',
        caption_label: 'rdp-caption_label b3',
        day: 'h-9 w-9 rounded-md relative hover:bg-surface-tertiary b3',
        day_button: 'h-9 w-9 rounded-md',
        outside: 'rdp-outside text-content-tertiary',
        disabled: 'rdp-disabled text-content-tertiary',
        today:
          'after:content-[""] after:block after:w-3.5 after:h-0.5 after:bg-content-brand-primary after:absolute after:bottom-1.5 after:left-1/2 after:-translate-x-1/2',
        selected: '!bg-content-safe !text-white after:bg-white',
        footer: props.footer
          ? 'rdp-footer p-3 border-t border-t-content-divider'
          : '',
        ...props.classNames,
      }}
      components={{
        PreviousMonthButton: (props: PreviousMonthButtonProps) => (
          <NavButton icon={<ChevronLeft />} {...props} />
        ),
        NextMonthButton: (props: NextMonthButtonProps) => (
          <NavButton icon={<ChevronRight />} {...props} />
        ),
      }}
      {...props}
    />
  );
};

const RangeModeCalendar: React.FC<
  PropsBase & (PropsRangeCalendar | PropsRangeRequiredCalendar)
> = (props) => {
  const { presets } = props;
  const [month, setMonth] = React.useState(props.month);
  if (presets) {
    return (
      <div className="flex w-fit rounded-lg">
        <div className="p-2 min-w-36 rounded-ss-lg rounded-es-lg border border-r-0 border-content-divider">
          {presets.map((preset, index) => (
            <div
              key={index}
              className={cn(
                'w-full px-2 py-2.5 rounded cursor-pointer bg-transparent hover:bg-surface-tertiary b3',
                props.selected &&
                  isEqual(
                    format(preset.date.from as Date, 'LLL dd, y'),
                    format(props.selected.from as Date, 'LLL dd, y'),
                  ) &&
                  isEqual(
                    format(preset.date.to as Date, 'LLL dd, y'),
                    format(props.selected.to as Date, 'LLL dd, y'),
                  ) &&
                  'bg-surface-tertiary',
              )}
              onClick={(e) => {
                props.onSelect &&
                  props.onSelect(preset.date, preset.date.to as Date, {}, e);

                setMonth(preset.date.to);
              }}
            >
              {preset.label}
            </div>
          ))}
        </div>
        <div className="rounded-se-lg rounded-ee-lg border border-content-divider">
          <DayPicker
            month={month}
            onMonthChange={setMonth}
            classNames={{
              root: 'rdp-root rounded-md',
              nav: 'absolute flex items-center justify-between h-[var(--rdp-nav-height)] w-[calc(100%-24px)] top-3 right-3',
              weekdays: 'rdp-weekdays mb-2',
              weekday: 'rdp-weekday text-content-tertiary !font-normal !s1',
              month: 'rdp-month p-3',
              month_caption: 'rdp-month_caption justify-center',
              caption_label: 'rdp-caption_label b3',
              day: 'h-9 w-9 rounded-md relative hover:bg-surface-tertiary b3',
              day_button: 'h-9 w-9 rounded-md',
              outside: 'rdp-outside text-content-tertiary',
              range_start: 'opacity-100 bg-content-safe hover:!bg-content-safe',
              range_middle:
                'opacity-100 bg-surface-tertiary !text-content-primary hover:bg-surface-tertiary rounded-none',
              range_end: 'opacity-100 bg-content-safe hover:!bg-content-safe',
              disabled: 'rdp-disabled text-content-tertiary',
              today:
                'after:content-[""] after:block after:w-3.5 after:h-0.5 after:bg-content-brand-primary after:absolute after:bottom-1.5 after:left-1/2 after:-translate-x-1/2',
              selected: 'bg-content-safe text-white after:bg-white',
              footer: props.footer
                ? 'rdp-footer p-3 border-t border-t-content-divider'
                : '',
              ...props.classNames,
            }}
            components={{
              PreviousMonthButton: (props: PreviousMonthButtonProps) => (
                <NavButton icon={<ChevronLeft />} {...props} />
              ),
              NextMonthButton: (props: NextMonthButtonProps) => (
                <NavButton icon={<ChevronRight />} {...props} />
              ),
            }}
            {...props}
          />
        </div>
      </div>
    );
  }

  return (
    <DayPicker
      month={month}
      onMonthChange={setMonth}
      classNames={{
        root: 'rdp-root rounded-md border border-content-divider',
        nav: 'absolute flex items-center justify-between h-[var(--rdp-nav-height)] w-[calc(100%-24px)] top-3 right-3',
        weekdays: 'rdp-weekdays mb-2',
        weekday: 'rdp-weekday text-content-tertiary !font-normal !s1',
        month: 'rdp-month p-3',
        month_caption: 'rdp-month_caption justify-center',
        caption_label: 'rdp-caption_label b3',
        day: 'h-9 w-9 rounded-md relative hover:bg-surface-hover b3',
        day_button: 'h-9 w-9 rounded-md',
        outside: 'rdp-outside text-content-tertiary',
        range_start: 'opacity-100 bg-content-safe hover:!bg-content-safe',
        range_middle:
          'opacity-100 bg-surface-tertiary !text-content-primary hover:bg-surface-tertiary rounded-none',
        range_end: 'opacity-100 bg-content-safe hover:!bg-content-safe',
        disabled: 'rdp-disabled text-content-tertiary',
        today:
          'after:content-[""] after:block after:w-3.5 after:h-0.5 after:bg-content-brand-primary after:absolute after:bottom-1.5 after:left-1/2 after:-translate-x-1/2',
        selected: 'bg-content-safe text-white after:bg-white',
        footer: props.footer
          ? 'rdp-footer p-3 border-t border-t-content-divider'
          : '',
        ...props.classNames,
      }}
      components={{
        PreviousMonthButton: (props: PreviousMonthButtonProps) => (
          <NavButton icon={<ChevronLeft />} {...props} />
        ),
        NextMonthButton: (props: NextMonthButtonProps) => (
          <NavButton icon={<ChevronRight />} {...props} />
        ),
      }}
      {...props}
    />
  );
};

const NavButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    icon: React.ReactNode;
  }
>(({ icon, ...props }, ref) => {
  return (
    <Button
      icon={icon}
      iconOnly
      size="sm"
      variant="outline"
      ref={ref}
      {...props}
      className="border-content-divider bg-transparent hover:bg-gray-50 active:bg-gray-200 active:border-gray-200"
    />
  );
});

NavButton.displayName = 'NavButton';

export default Calendar;
