import { FC, HTMLAttributes } from 'react';

type EyeProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const Eye: FC<EyeProps> = ({ size = 25, color = 'currentColor', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_3919_10919)">
        <path
          d="M1.88867 12.6523C1.88867 12.6523 5.88867 4.65234 12.8887 4.65234C19.8887 4.65234 23.8887 12.6523 23.8887 12.6523C23.8887 12.6523 19.8887 20.6523 12.8887 20.6523C5.88867 20.6523 1.88867 12.6523 1.88867 12.6523Z"
          fill="black"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8884 16.164C14.8277 16.164 16.3998 14.5919 16.3998 12.6526C16.3998 10.7132 14.8277 9.14111 12.8884 9.14111C10.9491 9.14111 9.37695 10.7132 9.37695 12.6526C9.37695 14.5919 10.9491 16.164 12.8884 16.164Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3919_10919">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.888672 0.652344)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Eye;
