import { FC, HTMLAttributes } from 'react';

type KrakenWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const KrakenWallet: FC<KrakenWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={size}
      viewBox="0 0 512 512"
      {...props}
    >
      <g clip-path="url(#a)">
        <rect width="512" height="512" fill="#7132F5" rx="114.5" />
        <g filter="url(#b)">
          <path
            fill="#BEA1F9"
            d="M18 122c0-54.124 43.876-98 98-98h280c54.124 0 98 43.876 98 98v268c0 54.124-43.876 98-98 98H116c-54.124 0-98-43.876-98-98V122Z"
          />
        </g>
        <g filter="url(#c)">
          <mask
            id="d"
            width="600"
            height="458"
            x="-44"
            y="54"
            maskUnits="userSpaceOnUse"
            style={{ maskType: 'alpha' }}
          >
            <path
              fill="#fff"
              fill-rule="evenodd"
              d="M256 98c21.583 0 39.536-15.54 43.282-36.04.794-4.347 4.3-7.96 8.718-7.96h85.849c32.682 0 53.273 17.329 72.486 36.543C480.293 104.5 499.5 104.5 523.318 104.5H548a8 8 0 0 1 8 8V504a8 8 0 0 1-8 8H-36a8 8 0 0 1-8-8V112.5a8 8 0 0 1 8-8h24.682c23.818 0 43.025 0 56.983-13.957C64.878 71.329 85.469 54 118.15 54H204c4.418 0 7.924 3.613 8.718 7.96C216.464 82.46 234.417 98 256 98Z"
              clip-rule="evenodd"
            />
          </mask>
          <g mask="url(#d)">
            <path
              fill="url(#e)"
              d="M512 512h512v458H512z"
              transform="rotate(180 512 512)"
            />
            <path
              fill="url(#f)"
              fill-opacity=".8"
              d="M512 512h512v458H512z"
              transform="rotate(180 512 512)"
            />
            <path
              fill="url(#g)"
              fill-opacity=".7"
              d="M512 512h512v458H512z"
              transform="rotate(180 512 512)"
            />
            <path
              fill="url(#h)"
              fill-opacity=".5"
              d="M512 512h512v458H512z"
              transform="rotate(180 512 512)"
            />
            <path
              fill="url(#i)"
              fill-opacity=".5"
              d="M512 512h512v458H512z"
              transform="rotate(180 512 512)"
            />
            <path
              fill="url(#j)"
              fill-opacity=".5"
              d="M512 512h512v458H512z"
              transform="rotate(180 512 512)"
            />
            <path
              fill="url(#k)"
              fill-opacity=".5"
              d="M512 512h512v458H512z"
              transform="rotate(180 512 512)"
            />
            <path
              fill="url(#l)"
              fill-opacity=".5"
              d="M512 512h512v458H512z"
              transform="rotate(180 512 512)"
            />
            <path
              fill="url(#m)"
              fill-opacity=".7"
              d="M512 512h512v458H512z"
              transform="rotate(180 512 512)"
            />
          </g>
        </g>
        <path
          stroke="#fff"
          stroke-opacity=".4"
          stroke-width="3"
          d="M256 99.5c22.32 0 40.884-16.07 44.757-37.27.695-3.802 3.707-6.73 7.243-6.73h85.849c32.044 0 52.26 16.937 71.426 36.103C479.672 106.001 499.453 106 522.926 106H548a6.5 6.5 0 0 1 6.5 6.5V544a6.5 6.5 0 0 1-6.5 6.5H-36a6.5 6.5 0 0 1-6.5-6.5V112.5A6.5 6.5 0 0 1-36 106H-10.926c23.473 0 43.254.001 57.651-14.397C65.891 72.437 86.106 55.5 118.151 55.5H204c3.536 0 6.548 2.928 7.243 6.73C215.116 83.43 233.68 99.5 256 99.5Z"
        />
        <g filter="url(#n)">
          <path
            fill="#7132F5"
            fill-rule="evenodd"
            d="M113 284.517v61.919c0 11.403 9.119 20.63 20.333 20.63 11.226 0 20.388-9.227 20.388-20.63v-61.919c0-11.419 9.072-20.662 20.345-20.662 11.233 0 20.345 9.243 20.345 20.662v61.919c0 11.403 9.119 20.63 20.344 20.63 11.265 0 20.381-9.227 20.381-20.63v-61.919c0-11.419 9.111-20.662 20.34-20.662 11.277 0 20.396 9.243 20.396 20.662v61.919c0 11.403 9.116 20.63 20.329 20.63 11.226 0 20.345-9.227 20.345-20.63v-61.919c0-11.419 9.115-20.662 20.388-20.662 11.23 0 20.345 9.243 20.345 20.662v61.919c0 11.403 9.119 20.63 20.376 20.63 11.23 0 20.345-9.227 20.345-20.63v-61.919C398 204.71 334.186 140 255.476 140 176.771 140 113 204.71 113 284.517Z"
            clip-rule="evenodd"
          />
        </g>
      </g>
      <defs>
        <radialGradient
          id="f"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(22.85748 531.6779 -435.87638 18.73886 807.619 247.157)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7132F5" stop-opacity=".4" />
          <stop offset="1" stop-color="#7132F5" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="g"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(89.312 261.461 575.243) scale(507.819 415.816)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".224" stop-color="#7132F5" stop-opacity=".4" />
          <stop offset="1" stop-color="#7132F5" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="h"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-97.52244 -205.10454 189.84287 -90.26587 937.143 1019.78)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".224" stop-color="#7132F5" stop-opacity=".4" />
          <stop offset="1" stop-color="#7132F5" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="i"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(152.38091 -131.4259 151.27288 175.39238 585.143 970)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7D2FE" />
          <stop offset="1" stop-color="#F7D2FE" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="j"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(141.853 434.15 436.718) scale(373.964 247.182)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".313" stop-color="#F7D2FE" />
          <stop offset="1" stop-color="#F7D2FE" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="k"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-255.99919 171.25361 -117.22792 -175.23866 1024 512)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFCAB1" />
          <stop offset="1" stop-color="#F7D2FE" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="l"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-70.529 1119.662 2.99) scale(333.712 113.857)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C7E1FF" />
          <stop offset="1" stop-color="#C7E1FF" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="m"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(144.213 382.61 406.218) scale(605.212 279.153)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFAF8A" />
          <stop offset=".824" stop-color="#FFE5B1" stop-opacity="0" />
        </radialGradient>
        <filter
          id="b"
          width="500"
          height="488"
          x="6"
          y="10"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="-2" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.168627 0 0 0 0 0.0784314 0 0 0 0 0.388235 0 0 0 0.16 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1_16" />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1_16"
            result="shape"
          />
        </filter>
        <filter
          id="c"
          width="544"
          height="490"
          x="-16"
          y="36"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="-2" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.168627 0 0 0 0 0.0784314 0 0 0 0 0.388235 0 0 0 0.16 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1_16" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feMorphology
            in="SourceAlpha"
            radius="4"
            result="effect2_dropShadow_1_16"
          />
          <feOffset dy="-2" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix values="0 0 0 0 0.443137 0 0 0 0 0.196078 0 0 0 0 0.960784 0 0 0 0.32 0" />
          <feBlend
            in2="effect1_dropShadow_1_16"
            result="effect2_dropShadow_1_16"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1_16"
            result="shape"
          />
        </filter>
        <filter
          id="n"
          width="285"
          height="230.066"
          x="113"
          y="140"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1_16" />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1_16"
            result="shape"
          />
        </filter>
        <linearGradient
          id="e"
          x1="512"
          x2="1111.12"
          y1="512"
          y2="725.412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7D2FE" />
          <stop offset="1" stop-color="#C7E1FF" />
        </linearGradient>
        <clipPath id="a">
          <rect width="512" height="512" fill="#fff" rx="114.5" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrakenWallet;
