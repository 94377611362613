import { cva } from 'class-variance-authority';
import {
  ReactNode,
  SelectHTMLAttributes,
  forwardRef,
  useRef,
  useState,
} from 'react';

import cn from '../lib/cn';

const hoverClassName =
  'hover:border-1.5 hover:border-green-600 hover:shadow-[0_0_4px_0_rgba(var(--color-green-600))]';
const focusClassName =
  'focus-within:border-green-600 focus-within:border-1.5 focus-within:shadow-[0_0_4px_0_rgba(var(--color-green-600))]';
const filledClassName =
  'has-[input:not(:placeholder-shown)]:border-1.5 has-[input:not(:placeholder-shown)]:border-green-600 has-[input:not(:placeholder-shown)]:shadow-[0_0_4px_0_rgba(var(--color-green-600))]';

const wrapperVariants = cva(
  'relative flex justify-between w-60 px-3 border border-dark-50 text-base font-normal gap-2.5 items-center bg-white group shadow-[1px_1px_2px_0_rgba(0,0,0,0.05)] transition-all rounded-lg',
  {
    variants: {
      size: {
        sm: 'h-8 rounded-lg',
        md: 'h-10 rounded-lg',
        lg: 'h-12 rounded-lg',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

const selectVariants = cva(
  'absolute bg-white border rounded-lg z-10 left-0 w-60 shadow-[0px_2px_10px_0px_rgba(0,0,0,0.1),0px_-1px_2px_0px_rgba(0,0,0,0.05)] p-1.5 text-base font-normal border border-dark-50 focus:outline-none focus:border-none focus:shadow-none focus:ring-0 placeholder:leading-normal placeholder:text-dark-200 disabled:pointer-events-none',
  {
    variants: {
      size: {
        sm: 'placeholder:text-sm text-sm',
        md: 'placeholder:text-base text-base',
        lg: 'placeholder:text-lg text-lg',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

export type SelectProps = Omit<
  SelectHTMLAttributes<HTMLSelectElement>,
  'size'
> & {
  options: any;
  leftSection?: ReactNode;
  rightSection?: React.ReactNode;
  placeholder?: string;
  classNames?: {
    wrapper?: string;
    select?: string;
    error?: string;
    leftSection?: string;
  };
  error?: boolean;
  size?: 'sm' | 'md' | 'lg';
};

const getClassName = ({
  error,
  disabled,
}: {
  error?: boolean;
  disabled?: boolean;
}) => {
  if (disabled) return 'cursor-not-allowed border-0.5 bg-dark-10 text-dark-200';
  if (error)
    return 'border-red-500 border-1.5 shadow-[0_0_4px_0_rgba(var(--color-red-500))]';
  return hoverClassName + ' ' + focusClassName + ' ' + filledClassName;
};

const Select = forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      options,
      leftSection,
      rightSection,
      classNames,
      className,
      error,
      placeholder = 'Select an option',
      size = 'md',
      disabled,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState('');
    const [isDropUp, setIsDropUp] = useState(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const toggleDropdown = () => {
      if (!disabled) {
        if (!isOpen && wrapperRef.current) {
          const rect = wrapperRef.current.getBoundingClientRect();
          const isAtBottom = rect.bottom + 200 > window.innerHeight;
          setIsDropUp(isAtBottom);
        }
        setIsOpen((prev) => !prev);
      }
    };

    const handleSelect = (option: any) => {
      setSelected(option);
      setIsOpen(false);
    };

    return (
      <div
        className={cn(
          wrapperVariants({ size }),
          getClassName({ error, disabled }),
          classNames?.wrapper,
          className,
        )}
        ref={(node) => {
          if (typeof ref === 'function') ref(node);
          wrapperRef.current = node;
        }}
        onClick={toggleDropdown}
      >
        <div className="cursor-pointer flex gap-2">
          {leftSection}
          <div>{selected || placeholder}</div>
        </div>
        {rightSection}
        {isOpen && (
          <ul
            className={cn(
              selectVariants({ size }),
              isDropUp ? 'bottom-full mb-1' : 'top-full mt-1',
            )}
          >
            {options.map((option: any) => (
              <li
                key={option}
                onClick={() => handleSelect(option)}
                className="p-2 hover:bg-black/5 cursor-pointer rounded-[4px]"
              >
                {option}
              </li>
            ))}
          </ul>
        )}
        <div className="flex w-fit max-w-fit flex-1 items-end cursor-pointer text-end">
          <svg
            className="flex w-fit max-w-fit flex-1 items-end cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </div>
      </div>
    );
  },
);
Select.displayName = 'Select';

export default Select;
