import { FC, HTMLAttributes } from 'react';

type WarningIconProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const WarningIcon: FC<WarningIconProps> = ({
  size = 21,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_2637_15519)">
        <path
          d="M18.8199 15.5001L12.1533 3.83344C12.0079 3.57694 11.7971 3.3636 11.5424 3.21516C11.2876 3.06673 10.9981 2.98853 10.7033 2.98853C10.4085 2.98853 10.1189 3.06673 9.86417 3.21516C9.60944 3.3636 9.39864 3.57694 9.25328 3.83344L2.58661 15.5001C2.43968 15.7546 2.36263 16.0434 2.36329 16.3372C2.36394 16.631 2.44226 16.9195 2.59032 17.1733C2.73837 17.4271 2.9509 17.6373 3.20636 17.7825C3.46181 17.9277 3.75112 18.0027 4.04494 18.0001H17.3783C17.6707 17.9998 17.9579 17.9226 18.211 17.7762C18.4641 17.6298 18.6743 17.4193 18.8204 17.166C18.9664 16.9127 19.0433 16.6254 19.0432 16.333C19.0432 16.0406 18.9662 15.7533 18.8199 15.5001Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.7109 8V11.3333"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.7109 14.6667H10.7193"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2637_15519">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.710938 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WarningIcon;
