import { FC, HTMLAttributes } from 'react';

type TokenaryWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const TokenaryWallet: FC<TokenaryWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 28 28"
      width={size}
      height={size}
      {...props}
    >
      <g clipPath="url(#a)">
        <path fill="#fff" d="M28 0H0v28h28V0Z" />
        <path
          fill="#2C7CF5"
          d="M14 22.941A8.941 8.941 0 1 0 14 5.06a8.941 8.941 0 0 0 0 17.882Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TokenaryWallet;
