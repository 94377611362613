'use client';

import { HTMLAttributes, forwardRef, useId } from 'react';

import {
  Input,
  InputDescription,
  InputDescriptionProps,
  InputLabel,
  InputLabelProps,
  InputProps,
} from '../input';
import cn from '../lib/cn';

export type TextInputProps = Omit<InputProps, 'type'> & {
  label?: React.ReactNode;
  description?: React.ReactNode;
  labelProps?: InputLabelProps;
  descriptionProps?: InputDescriptionProps;
  classNames?: {
    root?: string;
    label?: string;
    description?: string;
  };
  wrapperProps?: HTMLAttributes<HTMLDivElement>;
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      label,
      description,
      labelProps,
      descriptionProps,
      disabled,
      readOnly,
      wrapperProps,
      classNames,
      ...props
    }: TextInputProps,
    ref,
  ) => {
    const internalId = useId();
    return (
      <div
        {...wrapperProps}
        className={cn(
          'flex flex-col',
          classNames?.root,
          wrapperProps?.className,
        )}
      >
        {label && (
          <InputLabel
            {...labelProps}
            className={cn(
              'mb-2 w-fit',
              labelProps?.className,
              classNames?.label,
            )}
            disabled={disabled}
            readOnly={readOnly}
            htmlFor={id || internalId}
          >
            {label}
          </InputLabel>
        )}
        <Input
          {...props}
          ref={ref}
          type="text"
          id={id || internalId}
          disabled={disabled}
          readOnly={readOnly}
        />
        {description && (
          <InputDescription
            {...descriptionProps}
            variant={props.variant}
            className={cn(
              'mt-2',
              descriptionProps?.className,
              classNames?.description,
            )}
            disabled={disabled}
            readOnly={readOnly}
          >
            {description}
          </InputDescription>
        )}
      </div>
    );
  },
);
TextInput.displayName = 'TextInput';

export default TextInput;
