import { FC, HTMLAttributes } from 'react';

type ConnectWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const ConnectWallet: FC<ConnectWalletProps> = ({
  size = 40,
  shouldBlend = false,
  ...props
}) => {
  if (shouldBlend)
    return (
      <svg
        width={size}
        height={(41 * size) / 40}
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_1102_13993)">
          <rect
            x="5"
            y="4.11096"
            width="30"
            height="30"
            rx="6"
            fill="#E5E6E7"
          />
          <rect
            x="5.5"
            y="4.61096"
            width="29"
            height="29"
            rx="5.5"
            stroke="#030712"
            strokeOpacity="0.2"
          />
          <path
            d="M24.578 23.6425H28.8526V25.761C28.8526 26.3131 28.6283 26.8426 28.2294 27.233C27.8302 27.6237 27.2886 27.8428 26.7244 27.8428H13.1279C12.5634 27.8428 12.0221 27.6233 11.6231 27.2332C11.2242 26.8429 10.9996 26.3131 10.9996 25.761V15.261C10.9996 12.5648 13.2341 10.3792 15.9903 10.3792H25.2932C25.6781 10.3792 25.9902 10.6844 25.9902 11.061V13.8787H15.9904C15.6054 13.8787 15.2934 14.1839 15.2934 14.5605C15.2934 14.9371 15.6054 15.2423 15.9904 15.2423H26.7245C26.919 15.2423 27.1058 15.3179 27.2435 15.4525C27.3813 15.5873 27.4587 15.7701 27.4587 15.9605V18.0786H24.5785C24.3239 18.0786 24.0851 18.1444 23.8788 18.2597C23.4526 18.4976 23.1653 18.9463 23.1653 19.4607V22.2607C23.1653 23.0238 23.7978 23.6425 24.578 23.6425Z"
            fill="#030712"
          />
          <rect
            x="24.556"
            y="19.4423"
            width="5.05426"
            height="2.83594"
            rx="0.1"
            fill="#030712"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1102_13993"
            x="0"
            y="0.110962"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.862552 0 0 0 0 0.875589 0 0 0 0 0.938167 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1102_13993"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1102_13993"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.4375"
        y="0.458008"
        width="24"
        height="24"
        rx="6"
        fill="#E5E6E7"
      />
      <rect
        x="0.9375"
        y="0.958008"
        width="23"
        height="23"
        rx="5.5"
        stroke="#030712"
        strokeOpacity="0.2"
      />
      <path
        d="M16.1009 16.0832H19.5207V17.778C19.5207 18.2197 19.3412 18.6433 19.0221 18.9556C18.7028 19.2681 18.2694 19.4434 17.8181 19.4434H6.94086C6.48928 19.4434 6.05626 19.2679 5.73702 18.9558C5.41796 18.6435 5.23828 18.2197 5.23828 17.778V9.37799C5.23828 7.22108 7.02581 5.47253 9.2308 5.47253H16.6731C16.9811 5.47253 17.2307 5.71674 17.2307 6.01799V8.27216H9.23089C8.92293 8.27216 8.67327 8.51637 8.67327 8.81762C8.67327 9.11886 8.92293 9.36307 9.23089 9.36307H17.8182C17.9738 9.36307 18.1232 9.42355 18.2334 9.53121C18.3436 9.63905 18.4055 9.7853 18.4055 9.93762V11.6321H16.1014C15.8977 11.6321 15.7066 11.6848 15.5416 11.777C15.2007 11.9673 14.9708 12.3263 14.9708 12.7378V14.9778C14.9708 15.5883 15.4768 16.0832 16.1009 16.0832Z"
        fill="#030712"
      />
      <rect
        x="16.082"
        y="12.723"
        width="4.04341"
        height="2.26875"
        rx="0.1"
        fill="#030712"
      />
    </svg>
  );
};

export default ConnectWallet;
