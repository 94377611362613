import { FC, HTMLAttributes } from 'react';

type ZealWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const ZealWallet: FC<ZealWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 28 28"
      width={size}
      height={size}
      {...props}
    >
      <path fill="#0FF" d="M0 0h28v28H0z" />
      <path
        fill="#0B1821"
        d="M6.51 21.49h14.98v-6.74H9.506a2.996 2.996 0 0 0-2.996 2.995v3.745ZM21.49 6.51H6.51v6.741h11.984a2.996 2.996 0 0 0 2.996-2.996V6.51Z"
      />
    </svg>
  );
};

export default ZealWallet;
