import { FC, HTMLAttributes } from 'react';

type EventActionTransactionProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const EventActionTransaction: FC<EventActionTransactionProps> = ({
  size = 16,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.33341 2L2.66675 4.66667M2.66675 4.66667L5.33341 7.33333M2.66675 4.66667H13.3334M10.6667 14L13.3334 11.3333M13.3334 11.3333L10.6667 8.66667M13.3334 11.3333H2.66675"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EventActionTransaction;
