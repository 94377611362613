import { FC, HTMLAttributes } from 'react';

type RequirementNftProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const RequirementNft: FC<RequirementNftProps> = ({ size = 25, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.4375 6.45801C0.4375 3.1443 3.12379 0.458008 6.4375 0.458008H18.4375C21.7512 0.458008 24.4375 3.1443 24.4375 6.45801V18.458C24.4375 21.7717 21.7512 24.458 18.4375 24.458H6.4375C3.12379 24.458 0.4375 21.7717 0.4375 18.458V6.45801Z"
        fill="white"
        fillOpacity="0.15"
      />
      <rect
        x="7.10156"
        y="7.8938"
        width="12.4713"
        height="12.2705"
        rx="2.02729"
        fill="#B4B4B7"
      />
      <path
        d="M13.342 10.3662L13.7676 11.5163C14.126 12.4848 14.8897 13.2485 15.8582 13.6069L17.0083 14.0325L15.8582 14.458C14.8897 14.8164 14.126 15.5801 13.7676 16.5487L13.342 17.6987L12.9165 16.5486C12.5581 15.5801 11.7944 14.8164 10.8258 14.458L9.67578 14.0325L10.8258 13.6069C11.7944 13.2485 12.5581 12.4848 12.9165 11.5163L13.342 10.3662Z"
        fill="#464549"
      />
      <path
        d="M5.13778 17.3625L5.13778 7.09541C5.13778 6.4929 5.62618 6.0045 6.22869 6.0045L16.0469 6.0045"
        stroke="#B4B4B7"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RequirementNft;
