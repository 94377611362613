import { FC, HTMLAttributes } from 'react';

type ConnectFarcasterProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const ConnectFarcaster: FC<ConnectFarcasterProps> = ({
  size = 41,
  shouldBlend = false,
  ...props
}) => {
  if (shouldBlend)
    return (
      <svg
        width={size}
        height={(40 * size) / 41}
        viewBox="0 0 41 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_6386_67067)">
          <rect
            x="5.69415"
            y="4"
            width="30"
            height="30"
            rx="6"
            fill="#EDE3FF"
          />
          <rect
            x="6.19415"
            y="4.5"
            width="29"
            height="29"
            rx="5.5"
            stroke="#855DCD"
            strokeOpacity="0.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.8147 27.25H12.0901V26.6113C12.0901 26.3174 12.3236 26.0791 12.6116 26.0791H12.7159V25.4403C12.7159 25.1464 12.9493 24.9081 13.2373 24.9081V15.4339H12.6637L11.9858 13.0919H15.0103V10.75H26.3781V13.0919H29.4025L28.7246 15.4339H28.151V24.9081C28.439 24.9081 28.6725 25.1464 28.6725 25.4403V26.0791H28.7767C29.0647 26.0791 29.2982 26.3174 29.2982 26.6113V27.25H23.5801V26.6113C23.5801 26.3174 23.8135 26.0791 24.1015 26.0791H24.2058V25.4403C24.2058 25.1525 24.4297 24.918 24.7094 24.9084V19.6919C24.525 17.6031 22.7887 15.9661 20.6942 15.9661C18.5997 15.9661 16.8634 17.6031 16.679 19.6919V24.9082C16.9616 24.9144 17.189 25.1503 17.189 25.4403V25.7597V26.0791H17.2932C17.5812 26.0791 17.8147 26.3174 17.8147 26.6113V27.25Z"
            fill="#865BD4"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_6386_67067"
            x="0.694153"
            y="0"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.862745 0 0 0 0 0.87451 0 0 0 0 0.937255 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_6386_67067"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_6386_67067"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.0507812"
        y="0.237793"
        width="24"
        height="24"
        rx="6"
        fill="#EDE3FF"
      />
      <rect
        x="0.550781"
        y="0.737793"
        width="23"
        height="23"
        rx="5.5"
        stroke="#855DCD"
        strokeOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.74513 18.8378H5.16546V18.3268C5.16546 18.0917 5.35224 17.901 5.58264 17.901H5.66606V17.3901C5.66606 17.1549 5.85283 16.9643 6.08322 16.9643V9.38488H5.62435L5.08203 7.51132H7.5016V5.63779H16.5958V7.51132H19.0154L18.473 9.38488H18.0142V16.9643C18.2446 16.9643 18.4313 17.1549 18.4313 17.3901V17.901H18.5148C18.7452 17.901 18.9319 18.0917 18.9319 18.3268V18.8378H14.3574V18.3268C14.3574 18.0917 14.5442 17.901 14.7746 17.901H14.858V17.3901C14.858 17.1598 15.0371 16.9722 15.2609 16.9645V12.7913C15.1133 11.1202 13.7243 9.81068 12.0487 9.81068C10.3731 9.81068 8.98407 11.1202 8.83652 12.7913V16.9643C9.06267 16.9693 9.24452 17.158 9.24452 17.3901V17.6455V17.901H9.32795C9.55834 17.901 9.74513 18.0917 9.74513 18.3268V18.8378Z"
        fill="#865BD4"
      />
    </svg>
  );
};

export default ConnectFarcaster;
