import { FC, HTMLAttributes } from 'react';

type LinkProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const Link: FC<LinkProps> = ({ size = 41, shouldBlend = false, ...props }) => {
  if (shouldBlend)
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_6745_30422)">
          <rect
            x="5"
            y="4.03864"
            width="30"
            height="30"
            rx="6"
            fill="#F7F7F7"
          />
          <rect
            x="5.5"
            y="4.53864"
            width="29"
            height="29"
            rx="5.5"
            stroke="#6A6A6F"
            strokeOpacity="0.5"
          />
          <path
            d="M18.2839 19.8969C18.6525 20.3896 19.1227 20.7973 19.6627 21.0923C20.2027 21.3873 20.7998 21.5627 21.4135 21.6067C22.0272 21.6506 22.6432 21.5621 23.2197 21.347C23.7963 21.132 24.3198 20.7954 24.7548 20.3603L27.3294 17.7857C28.111 16.9764 28.5435 15.8925 28.5338 14.7674C28.524 13.6423 28.0727 12.5661 27.2771 11.7705C26.4815 10.9749 25.4053 10.5236 24.2802 10.5138C23.1551 10.504 22.0712 10.9366 21.2619 11.7182L19.7858 13.1857"
            stroke="#6A6A6F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.716 18.1804C21.3475 17.6877 20.8773 17.28 20.3373 16.985C19.7973 16.6899 19.2002 16.5145 18.5865 16.4706C17.9727 16.4266 17.3567 16.5152 16.7802 16.7302C16.2037 16.9453 15.6802 17.2818 15.2452 17.717L12.6706 20.2916C11.889 21.1008 11.4564 22.1848 11.4662 23.3099C11.476 24.4349 11.9273 25.5112 12.7229 26.3068C13.5185 27.1024 14.5947 27.5536 15.7198 27.5634C16.8449 27.5732 17.9288 27.1407 18.7381 26.359L20.2056 24.8915"
            stroke="#6A6A6F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_6745_30422"
            x="0"
            y="0.0386353"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.929412 0 0 0 0 0.929412 0 0 0 0 0.929412 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_6745_30422"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_6745_30422"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.0507812"
        y="0.458008"
        width="24"
        height="24"
        rx="6"
        fill="#F7F7F7"
      />
      <rect
        x="0.550781"
        y="0.958008"
        width="23"
        height="23"
        rx="5.5"
        stroke="#6A6A6F"
        strokeOpacity="0.5"
      />
      <path
        d="M10.6758 13.1445C10.9706 13.5387 11.3468 13.8648 11.7788 14.1008C12.2108 14.3368 12.6884 14.4772 13.1794 14.5124C13.6704 14.5475 14.1632 14.4767 14.6244 14.3046C15.0856 14.1326 15.5044 13.8634 15.8525 13.5152L17.9121 11.4556C18.5374 10.8081 18.8835 9.94099 18.8756 9.04092C18.8678 8.14084 18.5068 7.27985 17.8703 6.64338C17.2338 6.00692 16.3729 5.64589 15.4728 5.63807C14.5727 5.63025 13.7056 5.97626 13.0581 6.60157L11.8773 7.77559"
        stroke="#6A6A6F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4226 11.7713C13.1278 11.3771 12.7516 11.051 12.3196 10.8149C11.8877 10.5789 11.41 10.4386 10.919 10.4034C10.428 10.3683 9.93519 10.4391 9.47399 10.6111C9.01278 10.7832 8.59398 11.0524 8.24597 11.4005L6.18629 13.4602C5.56097 14.1077 5.21496 14.9748 5.22279 15.8749C5.23061 16.7749 5.59163 17.6359 6.2281 18.2724C6.86457 18.9089 7.72556 19.2699 8.62563 19.2777C9.5257 19.2855 10.3928 18.9395 11.0403 18.3142L12.2143 17.1402"
        stroke="#6A6A6F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Link;
