import { FC, HTMLAttributes } from 'react';

type EventDeviceMobileProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const EventDeviceMobile: FC<EventDeviceMobileProps> = ({
  size = 20,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={(21 * size) / 20}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.9667 1.6665H6.63334C5.71287 1.6665 4.96667 2.4127 4.96667 3.33317V16.6665C4.96667 17.587 5.71287 18.3332 6.63334 18.3332H14.9667C15.8871 18.3332 16.6333 17.587 16.6333 16.6665V3.33317C16.6333 2.4127 15.8871 1.6665 14.9667 1.6665Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8 15H10.8084"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EventDeviceMobile;
