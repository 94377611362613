import { FC, HTMLAttributes } from 'react';

type BitskiWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const BitskiWallet: FC<BitskiWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <g clip-path="url(#a)">
        <mask
          id="b"
          width="28"
          height="28"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path fill="#fff" d="M0 0h28v28H0V0Z" />
        </mask>
        <g mask="url(#b)">
          <path fill="#FF245A" d="M0 0h28v28H0V0Z" />
          <g filter="url(#c)">
            <path stroke="url(#d)" strokeWidth="16" d="M7 7h14v14H7V7Z" />
          </g>
          <mask
            id="e"
            width="18"
            height="18"
            x="5"
            y="5"
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M9.08 7.103 5.98 10.202c-.232.232-.217.421.015.654l9.513 9.513c.233.233.422.248.655.015l4.35-4.35c2.268-2.268 2.08-4.669.29-6.458-1.28-1.28-2.618-1.746-4.146-1.324-.247.073-.364.044-.48-.072-.043-.043-.077-.093-.122-.157l-.053-.076c-.175-.262-.466-.67-.698-.902-1.833-1.833-4.175-1.993-6.226.057v.001Zm4 4-.742.742c-.232.233-.421.218-.654-.015l-.698-.698c-.233-.233-.248-.422-.015-.655l.742-.742c.378-.378.916-.421 1.353.015.45.45.392.975.014 1.353Zm4.757 2.342-1.949 1.95c-.232.232-.421.218-.654-.015l-.698-.698c-.233-.233-.248-.422-.015-.655l1.95-1.95c.566-.566 1.104-.669 1.57-.203.48.48.364 1.003-.204 1.57v.001ZM6.818 15.183a.857.857 0 0 0 0 1.212l6.061 6.061a.857.857 0 0 0 1.212-1.212L8.03 15.183a.857.857 0 0 0-1.212 0Z"
              clipRule="evenodd"
            />
          </mask>
          <g filter="url(#f)" mask="url(#e)">
            <path
              fill="url(#g)"
              d="M22.18 13.8a8.183 8.183 0 0 0-16.366 0v.889a8.183 8.183 0 0 0 16.366 0V13.8Z"
            />
            <path
              fill="url(#h)"
              fillOpacity=".12"
              d="M22.18 13.8a8.183 8.183 0 0 0-16.366 0v.889a8.183 8.183 0 0 0 16.366 0V13.8Z"
            />
            <path
              fill="#fff"
              fillOpacity=".2"
              d="M22.18 13.8a8.183 8.183 0 0 0-16.366 0v.889a8.183 8.183 0 0 0 16.366 0V13.8Z"
              style={{
                mixBlendMode: 'overlay',
              }}
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="d"
          x1="14"
          x2="14"
          y1="0"
          y2="27.645"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity=".5" />
          <stop offset=".633" stopColor="#E00037" />
        </linearGradient>
        <linearGradient
          id="g"
          x1="13.997"
          x2="13.997"
          y1="5.618"
          y2="22.872"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset=".544" stopColor="#F9F7FA" />
          <stop offset=".77" stopColor="#F7F7F7" />
          <stop offset="1" stopColor="#E6E6E6" />
        </linearGradient>
        <filter
          id="c"
          width="230"
          height="230"
          x="-101"
          y="-101"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_2903_48224"
            stdDeviation="50"
          />
        </filter>
        <filter
          id="f"
          width="136.366"
          height="137.254"
          x="-54.186"
          y="-10.382"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="44" />
          <feGaussianBlur stdDeviation="30" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.28 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2903_48224"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_2903_48224"
            result="shape"
          />
        </filter>
        <radialGradient
          id="h"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 12.4616 -11.8201 0 13.997 10.41)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".479" stopColor="#fff" />
          <stop offset="1" />
        </radialGradient>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BitskiWallet;
