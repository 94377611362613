import { cva } from 'class-variance-authority';
import React, { forwardRef, useRef, useState } from 'react';

import cn from '../lib/cn';

const hoverClassName =
  'hover:border-1.5 hover:border-green-600 hover:shadow-[0_0_4px_0_rgba(var(--color-green-600))]';
const focusClassName =
  'focus-within:border-green-600 focus-within:border-1.5 focus-within:shadow-[0_0_4px_0_rgba(var(--color-green-600))]';
const filledClassName =
  'has-[input:not(:placeholder-shown)]:border-1.5 has-[input:not(:placeholder-shown)]:border-green-600 has-[input:not(:placeholder-shown)]:shadow-[0_0_4px_0_rgba(var(--color-green-600))]';

const wrapperVariants = cva(
  'w-fit border border-dark-50 flex gap-2.5 items-center bg-white group shadow-[1px_1px_2px_0_rgba(0,0,0,0.05)] transition-all',
  {
    variants: {
      size: {
        sm: 'h-8 rounded-lg px-2.5',
        md: 'h-10 rounded-lg px-3',
        lg: 'h-12 rounded-lg px-3',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

const dropdownVariants = cva(
  'absolute left-0 w-60 bg-white border border-dark-50 shadow-lg z-10 rounded-lg text-base font-normal shadow-[0px_2px_10px_0px_rgba(0,0,0,0.1),0px_-1px_2px_0px_rgba(0,0,0,0.05)]',
  {
    variants: {
      size: {
        sm: 'text-sm',
        md: 'text-base',
        lg: 'text-lg',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

export type DropdownMenuGroupProps = {
  children?: React.ReactNode;
  className?: string;
};

// DropdownMenuGroup component to wrap specific items with custom styling
const DropdownMenuGroup = ({ children, className }: DropdownMenuGroupProps) => {
  return <div className={cn(className)}>{children}</div>;
};

export type DropdownMenuProps = {
  children?: React.ReactNode;
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
  classNames?: {
    wrapper?: string;
    dropdown?: string;
    error?: string;
    leftSection?: string;
  };
  className?: string;
  error?: boolean;
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
};

const getClassName = ({
  error,
  disabled,
}: {
  error?: boolean;
  disabled?: boolean;
}) => {
  if (disabled) return 'cursor-not-allowed border-0.5 bg-dark-10 text-dark-200';
  if (error)
    return 'border-red-500 border-1.5 shadow-[0_0_4px_0_rgba(var(--color-red-500))]';
  return hoverClassName + ' ' + focusClassName + ' ' + filledClassName;
};

const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(
  (
    {
      children,
      leftSection,
      rightSection,
      classNames,
      className,
      error,
      size = 'md',
      disabled,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [, setSelected] = useState('');
    const [isDropUp, setIsDropUp] = useState(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const toggleDropdownMenu = () => {
      if (!disabled) {
        if (!isOpen && wrapperRef.current) {
          const rect = wrapperRef.current.getBoundingClientRect();
          const isAtBottom = rect.bottom + 200 > window.innerHeight;
          setIsDropUp(isAtBottom);
        }
        setIsOpen((prev) => !prev);
      }
    };

    const handleSelect = (option: any) => {
      setSelected(option);
      setIsOpen(false);
    };

    return (
      <div
        className={cn(
          'relative',
          wrapperVariants({ size }),
          getClassName({ error, disabled }),
          classNames?.wrapper,
          className,
        )}
        ref={(node) => {
          if (typeof ref === 'function') ref(node);
          wrapperRef.current = node;
        }}
      >
        {leftSection}
        <button
          onClick={toggleDropdownMenu}
          className="w-fit bg-transparent text-center disabled:cursor-not-allowed text-base font-normal"
          disabled={disabled}
        >
          Open
        </button>
        {rightSection}
        {isOpen && (
          <div
            ref={dropdownRef}
            className={cn(
              dropdownVariants({ size }),
              classNames?.dropdown,
              isDropUp ? 'bottom-full mb-1' : 'top-full mt-1',
            )}
          >
            {children &&
              React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                  const existingClassName = child.props.className || '';

                  if (child.type === DropdownMenuGroup) {
                    return React.cloneElement(
                      child as React.ReactElement<{
                        className?: string;
                        onClick?: () => void;
                      }>,
                      {
                        className: 'p-1.5',
                      },
                      React.Children.map(
                        child.props.children,
                        (dropdownChild) =>
                          React.isValidElement(dropdownChild)
                            ? React.cloneElement(
                                dropdownChild as React.ReactElement<{
                                  className?: string;
                                  onClick?: () => void;
                                }>,
                                {
                                  className: cn(
                                    existingClassName,
                                    'px-4 py-[5.5px] hover:bg-black/5 cursor-pointer rounded-[4px]',
                                  ),
                                  onClick: () => handleSelect(dropdownChild),
                                },
                              )
                            : dropdownChild,
                      ),
                    );
                  }
                  // Map over each child within DropdownMenuGroup
                  return React.cloneElement(
                    child as React.ReactElement<{
                      className?: string;
                      onClick?: () => void;
                    }>,
                    {
                      className: cn(
                        existingClassName,
                        'px-4 py-[5.5px] hover:bg-black/5 cursor-pointer rounded-[4px]',
                      ),
                      onClick: () => handleSelect(child),
                    },
                  );
                }
                return child;
              })}
          </div>
        )}
      </div>
    );
  },
);

DropdownMenu.displayName = 'DropdownMenu';

export { DropdownMenu, DropdownMenuGroup };
export default DropdownMenu;
