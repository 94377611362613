import { FC, HTMLAttributes } from 'react';

type ValoraWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const ValoraWallet: FC<ValoraWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path fill="url(#a)" d="M0 0h28v28H0z" />
      <g clip-path="url(#b)">
        <path
          d="M15.613 21c.724-5.46 3.377-8.569 7.387-11.386L20.95 7c-2.623 1.946-5.488 4.705-6.814 8.51C13.05 12.403 10.789 9.672 7.14 7L5 9.672C9.553 12.81 11.844 16.324 12.477 21h3.136Z"
          fill="#fff"
        />
      </g>
      <defs>
        <linearGradient
          id="a"
          x1="0"
          y1="28"
          x2="25.5"
          y2="4"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".17" stopColor="#FFD52C" />
          <stop offset="1" stopColor="#26D98A" />
        </linearGradient>
        <clipPath id="b">
          <path fill="#fff" transform="translate(5 7)" d="M0 0h18v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ValoraWallet;
