import { FC, HTMLAttributes } from 'react';

type TokenPocketWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const TokenPocketWallet: FC<TokenPocketWalletProps> = ({
  size = 16,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>tokenpocket</title>
      <defs>
        <linearGradient
          x1="107.511425%"
          y1="50.0147427%"
          x2="0.0459570557%"
          y2="50.0147427%"
          id="linearGradient-1"
        >
          <stop stopColor="#FFFFFF" offset="0%"></stop>
          <stop stopColor="#FFFFFF" stopOpacity="0.3233" offset="96.67%"></stop>
          <stop stopColor="#FFFFFF" stopOpacity="0.3" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="p1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="tokenpocket" fillRule="nonzero">
          <polygon
            id="path"
            fill="#2980FE"
            points="27.9874275 0 0 0 0 28 27.9874275 28"
          ></polygon>
          <g id="group" transform="translate(5.107577, 7.574219)">
            <path
              d="M6.28678209,4.45186719 L6.29988209,4.45186719 C6.28678209,4.42028824 6.28678209,4.38344613 6.28678209,4.35186719 L6.28678209,4.45186719 Z"
              id="path"
              fill="#29AEFF"
            ></path>
            <path
              d="M13.085927,5.10051172 L9.30493171,5.10051172 L9.30493171,12.2247344 C9.30493171,12.561418 9.56568007,12.8336523 9.88819083,12.8336523 L12.5026417,12.8336523 C12.8251787,12.8336523 13.085927,12.561418 13.085927,12.2247344 L13.085927,5.10051172 Z"
              id="path"
              fill="#FFFFFF"
            ></path>
            <path
              d="M7.47966698,0 L7.35271094,0 L0.583285313,0 C0.260748363,0 0,0.272207031 0,0.608917969 L0,3.08035547 C0,3.41706641 0.260748363,3.68927344 0.583285313,3.68927344 L2.17184659,3.68927344 L2.80316932,3.68927344 L2.80316932,4.41995313 L2.80316932,12.2426445 C2.80316932,12.5793555 3.06391768,12.8515625 3.38642844,12.8515625 L5.87051824,12.8515625 C6.193029,12.8515625 6.45377736,12.5793555 6.45377736,12.2426445 L6.45377736,4.41995313 L6.45377736,4.35192188 L6.45377736,3.68927344 L7.08510009,3.68927344 L7.34241721,3.68927344 L7.46937325,3.68927344 C8.4437942,3.68927344 9.23635921,2.86187891 9.23635921,1.84463672 C9.24665295,0.827394531 8.45408793,0 7.47966698,0 Z"
              id="path"
              fill="#FFFFFF"
            ></path>
            <path
              d="M13.0894107,5.10051172 L13.0894107,10.0720703 C13.2197979,10.1043086 13.3535903,10.1293828 13.49084,10.150875 C13.6829897,10.1795313 13.8819757,10.1974414 14.0809878,10.2010234 C14.0912816,10.2010234 14.1015753,10.2010234 14.1153003,10.2010234 L14.1153003,6.24667969 C13.5423087,6.20727734 13.0894107,5.70940234 13.0894107,5.10051172 Z"
              id="path"
              fill="url(#linearGradient-1)"
            ></path>
            <path
              d="M14.1907091,0 C11.4939345,0 9.30493171,2.28519922 9.30493171,5.10051172 C9.30493171,7.52182812 10.9209429,9.54912109 13.0893583,10.0720703 L13.0893583,5.10051172 C13.0893583,4.46651953 13.5834312,3.95073438 14.1907091,3.95073438 C14.7980131,3.95073438 15.2920861,4.46651953 15.2920861,5.10051172 C15.2920861,5.63420703 14.9455566,6.08193359 14.4720711,6.21085938 C14.3828587,6.23593359 14.2867839,6.25026172 14.1907091,6.25026172 L14.1907091,10.2010234 C14.2867839,10.2010234 14.3794275,10.1974414 14.4720711,10.1938594 C17.0384846,10.039832 19.0765167,7.81910938 19.0765167,5.10051172 C19.0799439,2.28519922 16.8909411,0 14.1907091,0 Z"
              id="path"
              fill="#FFFFFF"
            ></path>
            <path
              d="M14.2117905,10.2010234 L14.2117905,6.25026172 C14.1770295,6.25026172 14.1465846,6.25026172 14.1117905,6.24667969 L14.1117905,10.2010234 C14.1465846,10.2010234 14.1813788,10.2010234 14.2117905,10.2010234 Z"
              id="path"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TokenPocketWallet;
