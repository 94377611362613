import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

// Add plugins if needed
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(minMax);

export default dayjs;
