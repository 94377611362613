export { default as Pagination } from './pagination';
export type { PaginationProps } from './pagination';

export {
  Pagination as PaginationRoot,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from './pagination.core';
