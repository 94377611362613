import React from 'react';

import cn from '../lib/cn';

// CORE TABLE ELEMENTS

type TableRootProps = React.HTMLAttributes<HTMLDivElement>;

const TableRoot = React.forwardRef<HTMLDivElement, TableRootProps>(
  ({ className, children, ...props }, forwardedRef) => (
    <div
      ref={forwardedRef}
      // Activate if table is used in a float environment
      // className="flow-root"
    >
      <div
        // make table scrollable on mobile
        className={cn('w-full overflow-auto whitespace-nowrap', className)}
        {...props}
      >
        {children}
      </div>
    </div>
  ),
);

TableRoot.displayName = 'TableRoot';

type TableCoreProps = React.TableHTMLAttributes<HTMLTableElement> & {
  className?: string;
};

const TableCore = React.forwardRef<HTMLTableElement, TableCoreProps>(
  ({ className, ...props }, forwardedRef) => (
    <table
      ref={forwardedRef}
      tremor-id="tremor-raw"
      className={cn(
        // base
        'w-full caption-bottom rounded-lg border-separate border-spacing-0',
        className,
      )}
      {...props}
    />
  ),
);

TableCore.displayName = 'TableCore';

type TableHeaderProps = React.HTMLAttributes<HTMLTableSectionElement>;

const TableHeader = React.forwardRef<HTMLTableSectionElement, TableHeaderProps>(
  ({ className, ...props }, forwardedRef) => (
    <thead
      ref={forwardedRef}
      className={cn('rounded-ss-lg rounded-se-lg', className)}
      {...props}
    />
  ),
);

TableHeader.displayName = 'TableHeader';

type TableHeaderCellProps = React.ThHTMLAttributes<HTMLTableCellElement> & {
  className?: string;
  hasBorder?: boolean;
};

const TableHeaderCell = React.forwardRef<
  HTMLTableCellElement,
  TableHeaderCellProps
>(({ className, hasBorder, ...props }, forwardedRef) => (
  <th
    ref={forwardedRef}
    className={cn(
      // base
      'px-6 py-3 text-left b3 font-semibold',
      // text color
      'text-content-primary dark:text-content-tertiary',
      // border
      'border-t border-b last:border-r first:border-l border-x-transparent border-t-transparent border-b-gray-200',
      // border color
      hasBorder && '!border-gray-200 dark:!border-gray-800',
      className,
    )}
    {...props}
  />
));

TableHeaderCell.displayName = 'TableHeaderCell';

type TableBodyProps = React.HTMLAttributes<HTMLTableSectionElement>;

const TableBody = React.forwardRef<HTMLTableSectionElement, TableBodyProps>(
  ({ className, ...props }, forwardedRef) => (
    <tbody
      ref={forwardedRef}
      className={cn(
        // base
        'divide-y',
        // divide color
        'divide-gray-200 dark:divide-gray-800',
        className,
      )}
      {...props}
    />
  ),
);

TableBody.displayName = 'TableBody';

type TableRowProps = React.HTMLAttributes<HTMLTableRowElement> & {
  hasBorder?: boolean;
};

const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ className, hasBorder, ...props }, forwardedRef) => (
    <tr
      ref={forwardedRef}
      className={cn(
        'border-b transition-colors',
        '[&_td:last-child]:pr-4 [&_th:last-child]:pr-4',
        // border radius
        'rounded-ss-lg rounded-se-lg [&_th:first-child]:rounded-ss-lg [&_th:last-child]:rounded-se-lg',
        hasBorder &&
          '[&:last-child_td:first-child]:rounded-es-lg [&:last-child_td:last-child]:rounded-ee-lg',
        '[&_td:first-child]:pl-4 [&_th:first-child]:pl-4',
        className,
      )}
      {...props}
    />
  ),
);

TableRow.displayName = 'TableRow';

type TableCellProps = React.TdHTMLAttributes<HTMLTableCellElement> & {
  className?: string;
  hasBorder?: boolean;
};

const TableCell = React.forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ className, hasBorder, ...props }, forwardedRef) => {
    return (
      <td
        ref={forwardedRef}
        className={cn(
          // base
          'px-6 py-3 text-sm',
          // text color
          'text-gray-600 dark:text-gray-400',
          // border
          'border-b last:border-r first:border-l border-x-transparent border-b-gray-200',
          // border color
          hasBorder && '!border-gray-200 dark:!border-gray-800',
          className,
        )}
        {...props}
      />
    );
  },
);

TableCell.displayName = 'TableCell';

type TableFootProps = React.HTMLAttributes<HTMLTableSectionElement>;

const TableFoot = React.forwardRef<HTMLTableSectionElement, TableFootProps>(
  ({ className, ...props }, forwardedRef) => {
    return (
      <tfoot
        ref={forwardedRef}
        className={cn(
          // base
          'border-t text-left font-medium',
          // text color
          'text-gray-900 dark:text-gray-50',
          // border color
          'border-gray-200 dark:border-gray-800',
          className,
        )}
        {...props}
      />
    );
  },
);

TableFoot.displayName = 'TableFoot';

type TableCaptionProps = React.HTMLAttributes<HTMLTableCaptionElement>;

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  TableCaptionProps
>(({ className, ...props }, forwardedRef) => (
  <caption
    ref={forwardedRef}
    className={cn(
      // base
      'mt-3 px-3 text-center text-sm',
      // text color
      'text-gray-500 dark:text-gray-500',
      className,
    )}
    {...props}
  />
));

TableCaption.displayName = 'TableCaption';

export {
  TableCore,
  TableBody,
  TableCaption,
  TableCell,
  TableFoot,
  TableHeader,
  TableHeaderCell,
  TableRoot,
  TableRow,
  type TableCoreProps,
  type TableBodyProps,
  type TableCaptionProps,
  type TableCellProps,
  type TableFootProps,
  type TableHeaderProps,
  type TableHeaderCellProps,
  type TableRowProps,
};
