import { FC, HTMLAttributes } from 'react';

type CompassWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const CompassWallet: FC<CompassWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <g clipPath="url(#a)">
        <rect width="28" height="28" fill="#0D233D" rx="4" />
        <path
          fill="#fff"
          d="M14.792 8.879a.978.978 0 1 1-1.957 0 .978.978 0 0 1 1.957 0"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M16.682 4.698c-1.242-2.264-4.495-2.264-5.737 0L9.191 7.896c-.3.546-.749.995-1.295 1.295l-3.198 1.754c-2.264 1.242-2.264 4.495 0 5.737l3.198 1.754c.546.3.995.75 1.295 1.295l1.754 3.198c1.242 2.264 4.495 2.264 5.737 0l1.754-3.198c.3-.546.75-.995 1.295-1.295l3.198-1.754c2.264-1.242 2.264-4.495 0-5.737l-3.198-1.754a3.27 3.27 0 0 1-1.295-1.295zm-1.315 2.355c-.535-1.448-2.583-1.448-3.119 0l-1.14 3.079a1.66 1.66 0 0 1-.982.982l-3.079 1.14c-1.448.536-1.448 2.583 0 3.119l3.08 1.14c.454.168.813.527.982.982l1.14 3.08c.535 1.447 2.583 1.447 3.118 0l1.14-3.08c.168-.455.527-.814.982-.983l3.08-1.139c1.447-.536 1.447-2.583 0-3.12l-3.08-1.139a1.66 1.66 0 0 1-.982-.982z"
          clipRule="evenodd"
        />
        <g fill="#2989FC" filter="url(#b)">
          <path d="M31.627-10.6a.897.897 0 1 1-1.793 0 .897.897 0 0 1 1.793 0" />
          <path
            fillRule="evenodd"
            d="M33.359-14.432c-1.139-2.074-4.12-2.074-5.257 0L26.494-11.5c-.275.5-.686.912-1.187 1.187l-2.93 1.607c-2.075 1.139-2.075 4.12 0 5.258l2.93 1.608c.5.274.912.686 1.187 1.186l1.608 2.93c1.138 2.076 4.119 2.076 5.257 0l1.608-2.93c.274-.5.686-.912 1.186-1.186l2.93-1.608c2.076-1.139 2.076-4.12 0-5.258l-2.93-1.607a3 3 0 0 1-1.186-1.187zm-1.205 2.158c-.49-1.327-2.367-1.327-2.858 0l-1.044 2.822a1.52 1.52 0 0 1-.9.9l-2.823 1.045c-1.327.49-1.327 2.367 0 2.858l2.822 1.044c.417.155.746.484.9.9L29.297.119c.49 1.326 2.367 1.326 2.858 0l1.044-2.822c.155-.417.484-.746.9-.9L36.92-4.65c1.327-.49 1.327-2.367 0-2.858L34.1-8.551a1.52 1.52 0 0 1-.9-.9z"
            clipRule="evenodd"
          />
        </g>
        <g fill="#2989FC" filter="url(#c)">
          <path d="M31.627-10.6a.897.897 0 1 1-1.793 0 .897.897 0 0 1 1.793 0" />
          <path
            fillRule="evenodd"
            d="M33.359-14.432c-1.139-2.074-4.12-2.074-5.257 0L26.494-11.5c-.275.5-.686.912-1.187 1.187l-2.93 1.607c-2.075 1.139-2.075 4.12 0 5.258l2.93 1.608c.5.274.912.686 1.187 1.186l1.608 2.93c1.138 2.076 4.119 2.076 5.257 0l1.608-2.93c.274-.5.686-.912 1.186-1.186l2.93-1.608c2.076-1.139 2.076-4.12 0-5.258l-2.93-1.607a3 3 0 0 1-1.186-1.187zm-1.205 2.158c-.49-1.327-2.367-1.327-2.858 0l-1.044 2.822a1.52 1.52 0 0 1-.9.9l-2.823 1.045c-1.327.49-1.327 2.367 0 2.858l2.822 1.044c.417.155.746.484.9.9L29.297.119c.49 1.326 2.367 1.326 2.858 0l1.044-2.822c.155-.417.484-.746.9-.9L36.92-4.65c1.327-.49 1.327-2.367 0-2.858L34.1-8.551a1.52 1.52 0 0 1-.9-.9z"
            clipRule="evenodd"
          />
        </g>
        <g fill="#2989FC" filter="url(#d)">
          <path d="M31.627-10.6a.897.897 0 1 1-1.793 0 .897.897 0 0 1 1.793 0" />
          <path
            fillRule="evenodd"
            d="M33.359-14.432c-1.139-2.074-4.12-2.074-5.257 0L26.494-11.5c-.275.5-.686.912-1.187 1.187l-2.93 1.607c-2.075 1.139-2.075 4.12 0 5.258l2.93 1.608c.5.274.912.686 1.187 1.186l1.608 2.93c1.138 2.076 4.119 2.076 5.257 0l1.608-2.93c.274-.5.686-.912 1.186-1.186l2.93-1.608c2.076-1.139 2.076-4.12 0-5.258l-2.93-1.607a3 3 0 0 1-1.186-1.187zm-1.205 2.158c-.49-1.327-2.367-1.327-2.858 0l-1.044 2.822a1.52 1.52 0 0 1-.9.9l-2.823 1.045c-1.327.49-1.327 2.367 0 2.858l2.822 1.044c.417.155.746.484.9.9L29.297.119c.49 1.326 2.367 1.326 2.858 0l1.044-2.822c.155-.417.484-.746.9-.9L36.92-4.65c1.327-.49 1.327-2.367 0-2.858L34.1-8.551a1.52 1.52 0 0 1-.9-.9z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <filter
          id="b"
          width="60.39"
          height="60.389"
          x=".535"
          y="-36.273"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_987_3105"
            stdDeviation="10.143"
          />
        </filter>
        <filter
          id="c"
          width="60.39"
          height="60.389"
          x=".535"
          y="-36.273"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_987_3105"
            stdDeviation="10.143"
          />
        </filter>
        <filter
          id="d"
          width="60.39"
          height="60.389"
          x=".535"
          y="-36.273"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_987_3105"
            stdDeviation="10.143"
          />
        </filter>
        <clipPath id="a">
          <rect width="28" height="28" fill="#fff" rx="4" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CompassWallet;
