import { ImgHTMLAttributes, ReactNode } from 'react';
import {
  Checkbox,
  ConnectDiscord,
  ConnectFarcaster,
  ConnectWallet,
  ConnectWorldId,
  ConnectX,
  Dropdown,
  Email,
  FileUpload,
  Heading,
  Image,
  Link,
  LongText,
  Paragraph,
  Rating,
  ShortText,
  SingleChoice,
} from '~/components/icons/fields';
import ConnectTelegram from '~/components/icons/fields/ConnectTelegram';
import RequirementErc20 from '~/components/icons/fields/RequirementErc20';
import RequirementNative from '~/components/icons/fields/RequirementNative';
import RequirementNft from '~/components/icons/fields/RequirementNft';

export const otherOptionKey = '__other__';

export type FieldSpecs = Record<string, unknown> & {
  required: boolean;
  placeholder?: string;
  options: Option[];
};

export enum MediaType {
  Image = 'formImage',
}

export enum RatingType {
  Rating = 'rating',
}

export enum DisplayTextType {
  Heading = 'heading',
  Paragraph = 'paragraph',
}

export enum IdentityType {
  Discord = 'discord',
  Twitter = 'twitter',
  Wallet = 'wallet',
  WorldId = 'world-id',
  Farcaster = 'farcaster',
  Telegram = 'telegram',
  Solana = 'solana',
}

export enum TextType {
  ShortText = 'text',
  Email = 'email',
  LongText = 'textarea',
}

export enum ChoiceType {
  Select = 'select',
  RadioGroup = 'radio-group',
  CheckboxGroup = 'checkbox-group',
}

export enum OthersType {
  FileUpload = 'file',
  Url = 'url',
}

export type Field = 'input' | 'image' | 'identity' | 'text';

export type FieldSpecsType =
  | TextType
  | ChoiceType
  | DisplayTextType
  | IdentityType
  | MediaType
  | RatingType
  | OthersType;

export enum FieldAction {
  DUPLICATE = 'duplicate',
  DELETE = 'delete',
}

export enum StepAction {
  RENAME = 'rename',
  DUPLICATE = 'duplicate',
  DELETE = 'delete',
}

export enum StepType {
  REVIEW = 'review',
  ENDING = 'ending',
  FORM = 'form',
  COVER = 'cover',
  AUTH = 'auth',
  CLOSED = 'closed',
  WELCOME = 'welcome',
}

export const stepTypes = [
  { value: 'form', name: 'Form' },
  { value: 'review', name: 'Review' },
  { value: 'ending', name: 'Ending' },
  { value: 'closed', name: 'Closed' },
  { value: 'cover', name: 'Cover' },
  { value: 'auth', name: 'Auth' },
  { value: 'welcome', name: 'Welcome' },
];

export type FieldType = {
  name: string;
  value: FieldSpecsType;
  fieldType: Field;
};

export const fieldTypes: Record<string, FieldType[]> = {
  text: [
    {
      name: 'Short Text', // Display text
      value: TextType.ShortText, // Will map with fieldSpecsType value in function handleChangeFieldType
      fieldType: 'input',
    },
    {
      name: 'Long Text',
      value: TextType.LongText,
      fieldType: 'input',
    },
    {
      name: 'Email',
      value: TextType.Email,
      fieldType: 'input',
    },
  ],

  choices: [
    {
      name: 'Single Choice',
      value: ChoiceType.RadioGroup,
      fieldType: 'input',
    },
    {
      name: 'Dropdown',
      value: ChoiceType.Select,
      fieldType: 'input',
    },
    {
      name: 'Checkboxes',
      value: ChoiceType.CheckboxGroup,
      fieldType: 'input',
    },
  ],

  verification: [
    {
      name: 'Connect Wallet',
      value: IdentityType.Wallet,
      fieldType: 'identity',
    },
    {
      name: 'Connect Solana',
      value: IdentityType.Solana,
      fieldType: 'identity',
    },
    {
      name: 'Connect Discord',
      value: IdentityType.Discord,
      fieldType: 'identity',
    },
    {
      name: 'Connect X',
      value: IdentityType.Twitter,
      fieldType: 'identity',
    },
    {
      name: 'Connect Farcaster',
      value: IdentityType.Farcaster,
      fieldType: 'identity',
    },
    {
      name: 'Connect Telegram',
      value: IdentityType.Telegram,
      fieldType: 'identity',
    },
    {
      name: 'Connect World ID',
      value: IdentityType.WorldId,
      fieldType: 'identity',
    },
  ],

  displayText: [
    {
      name: 'Heading',
      value: DisplayTextType.Heading,
      fieldType: 'text',
    },
    {
      name: 'Paragraph',
      value: DisplayTextType.Paragraph,
      fieldType: 'text',
    },
  ],

  media: [
    {
      name: 'Image',
      value: MediaType.Image,
      fieldType: 'image',
    },
  ],

  rating: [
    {
      name: 'Rating',
      value: RatingType.Rating,
      fieldType: 'input',
    },
  ],

  others: [
    {
      name: 'File Upload',
      value: OthersType.FileUpload,
      fieldType: 'input',
    },
    {
      name: 'Link',
      value: OthersType.Url,
      fieldType: 'input',
    },
  ],
};

export type FieldIconProps = {
  size?: number;
  shouldBlend?: boolean;
} & ImgHTMLAttributes<HTMLImageElement>;

// Icons to display in responses table
export const iconComponents: Record<string, React.FC> = {
  text: ShortText,
  textarea: LongText,
  email: Email,

  'radio-group': SingleChoice,
  select: Dropdown,
  'checkbox-group': Checkbox,

  wallet: ConnectWallet,
  discord: ConnectDiscord,
  twitter: ConnectX,
  farcaster: ConnectFarcaster,
  telegram: ConnectTelegram,
  solana: ConnectWallet,

  heading: Heading,
  paragraph: Paragraph,

  formImage: Image,

  rating: Rating,

  file: FileUpload,
  url: Link,

  nft: RequirementNft,
  native: RequirementNative,
  erc20: RequirementErc20,
  'world-id': ConnectWorldId,
};

export enum BackgroundType {
  Color = 'color',
  Image = 'image',
}

export enum RadiusType {
  Round = 'rounded-[22px]',
  Default = 'rounded-lg',
  Square = 'rounded-[2px]',
}

export const backgroundTypes = [
  { value: BackgroundType.Color, name: 'Color' },
  { value: BackgroundType.Image, name: 'Image' },
];

export const radiusTypes: { value: RadiusType }[] = [
  { value: RadiusType.Round },
  { value: RadiusType.Default },
  { value: RadiusType.Square },
];

export type Option = {
  label: ReactNode;
  value: string;
  disabled?: boolean;
};

export enum ImageAlignment {
  Center = 'center',
  Full = 'full',
}
