import { FC, HTMLAttributes } from 'react';

type CoreWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const CoreWallet: FC<CoreWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <path fill="#1A1A1C" d="M0 0h28v28H0z" />
      <path
        fill="#1A1A1C"
        d="m14.22 8.796 1.798 1.01.75-.728 2.507-.282 1.473 1.64.246 2.362-1.719 1.519-3.257 1.337-3.985-1.599-1.01-2.046.749-2.383 2.447-.83Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M10.96 20.016c-1.6 0-3.1-.341-4.4-.939l5.285-4.824a3.26 3.26 0 0 1-1.196-2.526 3.26 3.26 0 0 1 1.236-2.558V8.46h2.03c.816 0 1.563.3 2.135.795a3.253 3.253 0 0 1 2.135-.795h2.03v.708a3.266 3.266 0 0 1-.632 5.51c-.395 1.166-1.137 2.214-2.131 3.067v1.507h-2.499a10.614 10.614 0 0 1-3.993.763Zm2.955-5.777c.595 0 1.14-.207 1.57-.552l.56 1.26.562-1.266a2.512 2.512 0 1 0-.557-3.278 2.512 2.512 0 1 0-2.135 3.836Zm0-.754a1.758 1.758 0 1 0 0-3.516 1.758 1.758 0 0 0 0 3.516Zm0-.753a1.005 1.005 0 1 0 0-2.01 1.005 1.005 0 0 0 0 2.01Zm6.029-1.005a1.758 1.758 0 1 1-3.517 0 1.758 1.758 0 0 1 3.517 0Zm-.754 0a1.005 1.005 0 1 1-2.01 0 1.005 1.005 0 0 1 2.01 0Z"
        clipRule="evenodd"
      />
      <path
        fill="#1A1A1C"
        fillRule="evenodd"
        d="M14.954 19.253h2.498v-1.507c.994-.853 1.736-1.901 2.131-3.067a3.266 3.266 0 0 0 .632-5.51V8.46h-2.03c-.816 0-1.562.3-2.135.795a3.253 3.253 0 0 0-2.135-.795h-2.03v.708a3.26 3.26 0 0 0-1.236 2.558 3.254 3.254 0 0 0 1.196 2.526L6.56 19.077c.168.077.34.15.513.219 1.176.462 2.494.72 3.887.72 1.435 0 2.791-.274 3.993-.763Zm.767-9.272a2.512 2.512 0 1 0-.235 3.706l.56 1.26.561-1.266a2.502 2.502 0 0 0 1.578.558 2.512 2.512 0 1 0-2.135-3.836 2.526 2.526 0 0 0-.329-.422Zm.324 3.715.292-.658a2.261 2.261 0 0 1-.287-.55c-.072.2-.17.389-.293.56l.288.648Zm-2.13-1.472a.497.497 0 1 0 0-.994.497.497 0 0 0 0 .994Zm4.27 0a.497.497 0 1 0 0-.994.497.497 0 0 0 0 .994Zm-2.512-.497a1.758 1.758 0 1 1-3.516 0 1.758 1.758 0 0 1 3.516 0Zm-.753 0a1.005 1.005 0 1 1-2.01 0 1.005 1.005 0 0 1 2.01 0Zm3.265 1.758a1.758 1.758 0 1 0 0-3.517 1.758 1.758 0 0 0 0 3.517Zm0-.753a1.005 1.005 0 1 0 0-2.01 1.005 1.005 0 0 0 0 2.01Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M10.96 20.016c-1.6 0-3.1-.341-4.4-.939l5.285-4.824a3.26 3.26 0 0 1-1.196-2.526 3.26 3.26 0 0 1 1.236-2.558V8.46h2.03c.816 0 1.563.3 2.135.795a3.253 3.253 0 0 1 2.135-.795h2.03v.708a3.266 3.266 0 0 1-.632 5.51c-.395 1.166-1.137 2.214-2.131 3.067v1.507h-2.499a10.614 10.614 0 0 1-3.993.763Zm2.955-5.777c.595 0 1.14-.207 1.57-.552l.56 1.26.562-1.266a2.512 2.512 0 1 0-.557-3.278 2.512 2.512 0 1 0-2.135 3.836Zm0-.754a1.758 1.758 0 1 0 0-3.516 1.758 1.758 0 0 0 0 3.516Zm0-.753a1.005 1.005 0 1 0 0-2.01 1.005 1.005 0 0 0 0 2.01Zm6.029-1.005a1.758 1.758 0 1 1-3.517 0 1.758 1.758 0 0 1 3.517 0Zm-.754 0a1.005 1.005 0 1 1-2.01 0 1.005 1.005 0 0 1 2.01 0Z"
        clipRule="evenodd"
      />
      <path
        fill="#1A1A1C"
        d="M16.413 11.706a2.515 2.515 0 1 1-5.031 0 2.515 2.515 0 0 1 5.03 0Z"
      />
      <path
        fill="#1A1A1C"
        d="M20.689 11.706a2.515 2.515 0 1 1-5.03 0 2.515 2.515 0 0 1 5.03 0Z"
      />
      <path
        fill="#fff"
        d="M15.658 11.706a1.76 1.76 0 1 1-3.521 0 1.76 1.76 0 0 1 3.521 0Zm4.276 0a1.76 1.76 0 1 1-3.521 0 1.76 1.76 0 0 1 3.521 0Z"
      />
      <path
        fill="#1A1A1C"
        d="M14.903 11.706a1.006 1.006 0 1 1-2.012 0 1.006 1.006 0 0 1 2.012 0Zm4.277 0a1.006 1.006 0 1 1-2.013 0 1.006 1.006 0 0 1 2.013 0Zm-3.15 3.224-.87-1.962h1.742l-.872 1.962Z"
      />
    </svg>
  );
};

export default CoreWallet;
